const warehousingConfigReducer = (preferences = { view_mode: 'items' }, action) => {
    switch (action.type) {
        case 'ADD_WAREHOUSING_CONFIG':
            return {
                ...action.payload,
            }
        default:
            return preferences
    }
}

export default warehousingConfigReducer
