import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-semantic-toasts'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
// components
import SuperField from '@components/forms/SuperField'
import ModalCancel from '@components/buttons/ModalCancel'
import ModalSubmit from '@components/buttons/ModalSubmit'
import { Form, Divider } from 'semantic-ui-react'

const RemoveAbsenceRecordForm = ({ onClose, day, contract, absences, setContracts, setAttendanceAbsenceRecords }) => {
    const { t } = useTranslation()

    const [processing, setProcessing] = useState(false)
    const [selectedAbsences, setSelectedAbsences] = useState(absences.length === 1 ? [absences[0].id] : [])

    const handleSubmit = async () => {
        setProcessing(true)

        let removedAbsences = []
        for (let i = 0; i < selectedAbsences.length; i++) {
            const absenceRecord = absences.find((item) => item.id === selectedAbsences[i])
            if (absenceRecord) {
                if (absenceRecord.is_corrected) {
                    // delete if record is correction
                    const request = await requests.del(API.ATTENDANCE_BASE + 'absences/' + absenceRecord.id + '/')
                    if (request.status === 204) {
                        removedAbsences.push(absenceRecord.id)
                    }
                } else {
                    // patch as "is_resolved: true" if record is not correction
                    const request = await requests.patch(API.ATTENDANCE_BASE + 'absences/' + absenceRecord.id + '/', {
                        is_resolved: true,
                    })
                    if (request.status === 200) {
                        if (absenceRecord.absence_type?.source === 'vacation') {
                            // handle return to available vacation fond
                            if (parseFloat(absenceRecord.vacation_type) > 0) {
                                await requests.post(API.CONTRACTS + 'bank_history/', {
                                    type: 7, // vacation fond
                                    operation: 1, // increase
                                    amount: parseFloat(absenceRecord.vacation_type || 0),
                                    contract: absenceRecord.contract,
                                })
                            }
                        }

                        removedAbsences.push(absenceRecord.id)
                    }
                }
            }
        }

        toast({
            type: 'success',
            icon: 'check circle',
            title: t('absence_records_removed'),
            animation: 'pulse',
            time: 2000,
        })
        setAttendanceAbsenceRecords((prev) => prev.filter((item) => !removedAbsences.includes(item.id)))
        onClose()
        setProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <p style={{ fontWeight: 'bold' }}>{t('select_absences_to_remove')}:</p>
            {absences.map((absence) => (
                <SuperField
                    as="checkbox"
                    key={absence.id}
                    label={
                        absence.absence_type?.title +
                        ': ' +
                        absence.hours +
                        ' ' +
                        t('hours_shortcut').toLocaleLowerCase() +
                        '.'
                    }
                    checked={selectedAbsences.includes(absence.id)}
                    onChange={() => {
                        if (!selectedAbsences.includes(absence.id)) {
                            setSelectedAbsences((prev) => [...prev, absence.id])
                        } else {
                            setSelectedAbsences((prev) => prev.filter((item) => item !== absence.id))
                        }
                    }}
                />
            ))}

            <Divider />
            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit loading={processing} disabled={processing || selectedAbsences.length === 0} />
            </Form.Field>
        </Form>
    )
}

export default RemoveAbsenceRecordForm
