import React, { useEffect, useState } from 'react'
import { toast } from 'react-semantic-toasts'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
// store
import { API } from '@store/config'
import { icons } from '@store/icons'
import { routes } from '@store/routes'
import { requests } from '@helpers/requests'
import { useHasPermission } from '@helpers/hooks'
// components
import Icon from '@components/Icon'
import Notes from '@components/Notes'
import Skills from '@components/Skills'
import ListView from '@components/ListView'
import AvatarList from '@components/AvatarList'
import DocPreview from '@components/DocPreview'
import Action from '@components/general/Action'
import CanView from '@components/perms/CanView'
import BreadcrumbNav from '@components/BreadcrumbNav'
import { Popup, Button, Divider } from 'semantic-ui-react'
import GenerateDocuments from '@components/GenerateDocuments'
import SuperDuperModal from '@components/modals/SuperDuperModal'
import ProfilePreview from '@components/common/profile-card/ProfilePreview'
import UploadFile from '@components/UploadFile'
// import SkillSeniorityFilterField from '@components/forms/SkillSeniorityFilterField';
// specific components
import CandidateHistory from '../views/CandidateHistory'
import CandidateForm from '../components/forms/CandidateForm'
import AssignToHiringForm from '../components/forms/AssignToHiringForm'
import LabelsList from '@components/lists/LabelsList'
import CandidateFilters from '../components/forms/CandidateFilters'

const CandidateList = () => {
    const { t } = useTranslation()
    const [stats, setStats] = useState(null)
    const [recruitments, setRecruitments] = useState([])
    const [forceModalOpen, setForceModalOpen] = useState(0)

    const canSeeStats = useHasPermission('reports.c_view_candidates_reports')

    const convertToEmployee = async (id, setData) => {
        const request = await requests.patch(API.CANDIDATES + id + '/convert/', {})

        if (request.status === 200) {
            setData((prev) => prev.filter((item) => item.id !== id))

            toast({
                type: 'success',
                icon: 'check circle',
                title: t('success'),
                description: t('convert_to_employee_success_message'),
                animation: 'bounce',
                time: 5000,
            })
        } else {
            toast({
                type: 'error',
                icon: 'warning',
                title: t('error'),
                description: t('convert_to_employee_error_message'),
                animation: 'bounce',
                time: 5000,
            })
        }
    }

    const displayFullNameWithTitles = (candidate) => {
        let titles_before = candidate?.titles_before || ''
        let titles_after = candidate?.titles_after || ''
        let fullname = candidate.fullname

        let fullnameWithTitles = `${titles_before || ''} ${fullname} ${titles_after || ''}`

        return fullnameWithTitles.trim()
    }

    const removeFile = async (candidate, setData) => {
        const request = await requests.del(API.CANDIDATES + candidate.id + '/cv/')

        if (request.status === 204) {
            toast({
                type: 'success',
                icon: 'check circle',
                title: t('cv_removed'),
                animation: 'pulse',
                time: 2000,
            })

            setData((prev) =>
                prev.map((item) => {
                    if (item.id === candidate.id) {
                        item.cv = null
                    }
                    return item
                })
            )
        }
    }

    const determinateGender = (candidate) => {
        if (candidate?.gender) {
            return candidate?.gender
        } else {
            return false
        }
    }

    const determinateGenderIcon = (candidate) => {
        const gender = determinateGender(candidate)
        let icon = 'male-female'
        if (gender === 1) {
            icon = 'man'
        } else if (gender === 2) {
            icon = 'woman'
        }

        return icon
    }

    async function fetchStats() {
        if (canSeeStats) {
            const request = await requests.get(API.REPORTS + 'candidates/basic_stats/')
            if (request.status === 200) {
                setStats(request.response)
            }
        }
    }

    async function fetchRecruitments() {
        const request = await requests.get(
            API.HIRING_ROOMS + '?query={id, name, archived_recruitment_stages{id, name}}&is_active=true'
        )
        if (request.status === 200) {
            setRecruitments(request.response)
        }
    }

    useEffect(() => {
        fetchStats()
        fetchRecruitments()
        // eslint-disable-next-line
    }, [])

    return (
        <CanView
            permissions={[
                'common.c_view_all_candidates',
                'common.c_view_user_profile',
                'company.c_manage_only_assigned_unit_employees',
            ]}
            redirect
        >
            <BreadcrumbNav items={[{ name: t('candidates'), icon: icons.CANDIDATES, href: '' }]} />
            <ListView
                as="table"
                // allowViews
                allowSearch
                allowSelection
                allowRowExport
                // module="candidates"
                isSoftDeleteAllowed
                endpoint={API.CANDIDATES}
                actionsCellWidth="2"
                query={
                    '&query={id, skills, is_active, fullname, profile_picture, cv, contact_labels, contacts, notes, hiring_rooms, in_active_recruitment, blacklist_note}'
                }
                exports={[
                    {
                        endpoint: API.EXPORTS + 'dynamic_candidates/',
                        permissions: ['common.c_view_all_candidates', 'company.c_manage_only_assigned_unit_employees'],
                        apply_filters: true,
                        type: 'xlsx',
                        filename: 'candidates',
                    },
                ]}
                // imports={() => routes.IMPORTS + "?type=candidates&scheme=import-candidates"}
                initialFilters={{
                    gender: '',
                    country: '',
                    education: '',
                    position: '',
                    unit: '',
                    city: '',
                    state: '',
                    experience: '',
                    show_duplicates: '',
                    in_active_recruitment: '',
                    has_multiple_active_recruitments: '',
                    display_blacklisted: '',
                    skill_and_seniority_conjoined: '',
                    skill_and_seniority: '',
                    contact_labels: [],
                    language: '',
                    language_level: '',
                    is_active: '',
                    recruitment: '',
                    exclude_rejected: '',
                    exclude_blacklisted: '',
                    current_stage: '',
                    assigned_to_recruiter: '',
                }}
                listAdditionActions={(data, setData, total, setTotal, response) => [
                    {
                        as: 'filter',
                        index: 0,
                        name: t('all'),
                        filters: {
                            display_blacklisted: '',
                            is_active: '',
                            in_active_recruitment: '',
                        },
                        count: canSeeStats ? stats?.total || 0 : undefined,
                    },
                    {
                        as: 'filter',
                        index: 1,
                        name: t('in_active_recruitment'),
                        filters: {
                            display_blacklisted: '',
                            is_active: '',
                            in_active_recruitment: true,
                        },
                        count: canSeeStats ? stats?.in_active_recruitment || 0 : undefined,
                    },
                    {
                        as: 'filter',
                        index: 2,
                        name: t('blacklisted'),
                        filters: {
                            display_blacklisted: true,
                            in_active_recruitment: '',
                            is_active: '',
                        },
                        count: canSeeStats ? stats?.blacklisted || 0 : undefined,
                    },
                    {
                        as: 'filter',
                        index: 3,
                        name: t('inactive'),
                        filters: {
                            display_blacklisted: '',
                            in_active_recruitment: '',
                            is_active: false,
                        },
                        count: canSeeStats ? stats?.inactive || 0 : undefined,
                    },
                ]}
                tableHeaders={[
                    { title: t('fullname'), orderBy: 'lastname' },
                    { title: t('hiring_rooms') },
                    { title: t('contact') },
                    { title: t('labels') },
                    { title: t('cv') },
                    { title: t('skills') },
                    { title: t('notes') },
                    { title: t('recruiters') },
                    { title: t('status'), orderBy: 'status' },
                ]}
                renderFilterFields={(filters, setFilters) => (
                    <CandidateFilters filters={filters} setFilters={setFilters} recruitments={recruitments} />
                )}
                actions={[
                    {
                        as: 'modal',
                        type: 'add',
                        modal: <CandidateForm />,
                        permissions: [
                            'common.c_manage_all_candidates',
                            'common.c_manage_user_profile',
                            'company.c_manage_only_assigned_unit_employees',
                        ],
                    },
                    {
                        as: 'modal',
                        type: 'custom',
                        icon: 'eye-outline',
                        name: t('view_preview'),
                        modalSize: 'tiny',
                        modal: <ProfilePreview isCandidate />,
                        permissions: [
                            'common.c_view_all_candidates',
                            'common.c_view_user_profile',
                            'company.c_manage_only_assigned_unit_employees',
                        ],
                    },
                    {
                        as: 'link',
                        type: 'detail',
                        icon: 'id-card-outline',
                        name: t('view_card'),
                        target: '_blank',
                        redirect: (item) => routes.CANDIDATES_CARD + item.id,
                        permissions: [
                            'common.c_view_all_candidates',
                            'common.c_view_user_profile',
                            'company.c_manage_only_assigned_unit_employees',
                        ],
                    },
                    {
                        name: t('convert_to_employee'),
                        type: 'custom',
                        as: 'confirm',
                        icon: 'person-add-outline',
                        customIconColor: 'var(--black)',
                        text: t('convert_to_emp_text'),
                        permissions: [
                            'common.c_manage_all_candidates',
                            'company.c_manage_only_assigned_unit_employees',
                        ],
                        onClick: async (item, setData) => convertToEmployee(item.id, setData),
                    },
                    {
                        name: t('history'),
                        type: 'custom',
                        as: 'modal',
                        icon: 'albums-outline',
                        customIconColor: 'var(--dark)',
                        permissions: [],
                        modal: (item) => <CandidateHistory candidate={item.id} />,
                    },
                    {
                        name: t('delete'),
                        type: 'delete',
                        as: 'delete',
                        text: t('delete_candidate_message'),
                        permissions: [
                            'common.c_delete_all_candidates',
                            'company.c_manage_only_assigned_unit_employees',
                        ],
                    },
                ]}
                bulkActions={(selected) => [
                    {
                        as: 'modal',
                        name: t('generate_documents'),
                        icon: 'document-text-outline',
                        modal: <GenerateDocuments source="common.Profile" selected={selected} />,
                    },
                ]}
                renderCells={(candidate, setData) => [
                    {
                        content: (
                            <>
                                <strong style={{ fontSize: '1rem' }}>
                                    {displayFullNameWithTitles(candidate)}
                                    {determinateGender(candidate) && (
                                        <Popup
                                            hoverable
                                            position="right center"
                                            trigger={
                                                <Icon
                                                    name={determinateGenderIcon(candidate)}
                                                    style={{
                                                        marginLeft: '0.5rem',
                                                        color: 'var(--black)',
                                                        cursor: 'pointer',
                                                        fontSize: '1.2rem',
                                                    }}
                                                />
                                            }
                                            content={candidate?.gender_display}
                                        />
                                    )}

                                    {candidate.blacklist_note && (
                                        <Popup
                                            hoverable
                                            position="right center"
                                            trigger={
                                                <Icon
                                                    name="alert-circle"
                                                    style={{
                                                        marginLeft: '0.2rem',
                                                        color: 'var(--black)',
                                                        cursor: 'pointer',
                                                        fontSize: '1.2rem',
                                                    }}
                                                />
                                            }
                                            content={
                                                <div>
                                                    <strong>{t('blacklisted')}</strong>
                                                    <p>{candidate.blacklist_note}</p>
                                                </div>
                                            }
                                        />
                                    )}
                                </strong>
                                <CanView permissions={['candidates.c_manage_recruitment']}>
                                    <br />
                                    <span style={{ cursor: 'pointer', color: 'var(--primary)' }}>
                                        <SuperDuperModal
                                            header={t('assign_to_hiring_room')}
                                            trigger={<span>{t('assign_to_hiring_room')} </span>}
                                            content={<AssignToHiringForm candidate={candidate.id} setData={setData} />}
                                        />
                                    </span>
                                </CanView>
                            </>
                        ),
                    },
                    {
                        content: candidate?.in_active_recruitment && (
                            <div>
                                {candidate?.hiring_rooms?.map((room) => (
                                    <>
                                        <Link to={`${routes.HIRING_ROOMS}${room?.recruitment_id}`}>
                                            {' '}
                                            {room?.recruitment}{' '}
                                        </Link>{' '}
                                        <br />
                                    </>
                                ))}
                            </div>
                        ),
                    },
                    {
                        width: 1,
                        content: (
                            <div>
                                {candidate.contacts.length === 0 ? (
                                    <strong style={{ color: 'var(--danger)' }}> {t('no_active_contact')} </strong>
                                ) : (
                                    <>
                                        {candidate.contacts[0]?.contact_person_name ? (
                                            <strong>
                                                {candidate.contacts[0]?.contact_person_name} <br />
                                            </strong>
                                        ) : (
                                            ''
                                        )}
                                        {candidate.contacts[0]?.phone ? (
                                            <>
                                                <a href={'callto:' + candidate.contacts[0]?.phone}>
                                                    {' '}
                                                    <Icon name="call-outline" style={{ marginRight: '0.2rem' }} />{' '}
                                                    <span style={{ position: 'relative', top: '-0.2rem' }}>
                                                        {candidate.contacts[0]?.phone}
                                                    </span>{' '}
                                                </a>{' '}
                                                <br />
                                            </>
                                        ) : (
                                            ''
                                        )}
                                        {candidate.contacts[0]?.email ? (
                                            <>
                                                <a href={'mailto:' + candidate.contacts[0]?.email}>
                                                    {' '}
                                                    <Icon name="mail-outline" style={{ marginRight: '0.2rem' }} />{' '}
                                                    <span style={{ position: 'relative', top: '-0.2rem' }}>
                                                        {candidate.contacts[0]?.email}
                                                    </span>{' '}
                                                </a>{' '}
                                            </>
                                        ) : (
                                            ''
                                        )}
                                        {candidate.contacts.length > 1 && (
                                            <div>
                                                <SuperDuperModal
                                                    size="tiny"
                                                    content={
                                                        <div>
                                                            {candidate.contacts.map((contact, idx) => (
                                                                <div key={idx}>
                                                                    <div
                                                                        style={{
                                                                            display: 'flex',
                                                                            flexDirection: 'row',
                                                                            itemsAlign: 'start',
                                                                            justifyContent: 'space-between',
                                                                        }}
                                                                    >
                                                                        <div>
                                                                            {contact.contact_person_name !== '' && (
                                                                                <strong>
                                                                                    {contact.contact_person_name} <br />
                                                                                </strong>
                                                                            )}
                                                                            {contact.phone !== '' ? (
                                                                                <>
                                                                                    <a href={'callto:' + contact.phone}>
                                                                                        {' '}
                                                                                        <Icon
                                                                                            name="call-outline"
                                                                                            style={{
                                                                                                marginRight: '0.2rem',
                                                                                            }}
                                                                                        />{' '}
                                                                                        <span
                                                                                            style={{
                                                                                                position: 'relative',
                                                                                                top: '-0.2rem',
                                                                                            }}
                                                                                        >
                                                                                            {contact.phone}
                                                                                        </span>{' '}
                                                                                    </a>{' '}
                                                                                    <br />
                                                                                </>
                                                                            ) : (
                                                                                ''
                                                                            )}
                                                                            {contact?.email !== '' ? (
                                                                                <>
                                                                                    <a href={'mailto:' + contact.email}>
                                                                                        {' '}
                                                                                        <Icon
                                                                                            name="mail-outline"
                                                                                            style={{
                                                                                                marginRight: '0.2rem',
                                                                                            }}
                                                                                        />{' '}
                                                                                        <span
                                                                                            style={{
                                                                                                position: 'relative',
                                                                                                top: '-0.2rem',
                                                                                            }}
                                                                                        >
                                                                                            {contact.email}
                                                                                        </span>{' '}
                                                                                    </a>{' '}
                                                                                </>
                                                                            ) : (
                                                                                ''
                                                                            )}
                                                                            {contact?.is_invoicing && (
                                                                                <div
                                                                                    style={{
                                                                                        opacity: 0.8,
                                                                                        fontSize: '0.9rem',
                                                                                        fontWeight: 'bold',
                                                                                        marginTop: '0.2rem',
                                                                                    }}
                                                                                >
                                                                                    {t('used_for_invoicing')}
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                        <div style={{ textAlign: 'right' }}>
                                                                            <span
                                                                                style={{
                                                                                    fontWeight: 'bold',
                                                                                    opacity: 0.5,
                                                                                    marginRight: '1rem',
                                                                                    position: 'relative',
                                                                                    top: '-0.1rem',
                                                                                }}
                                                                            >
                                                                                #{idx + 1}.
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <Divider />
                                                                </div>
                                                            ))}
                                                        </div>
                                                    }
                                                    trigger={
                                                        <span className="ref-link" style={{ color: 'var(--primary)' }}>
                                                            {t('show_more_contacts')}
                                                        </span>
                                                    }
                                                />
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        ),
                    },
                    {
                        width: 3,
                        content: (
                            <>
                                <LabelsList
                                    rowID={candidate.id}
                                    labels={candidate?.contact_labels}
                                    endpoint={API.CANDIDATES}
                                    label_key="contact_labels"
                                />
                            </>
                        ),
                    },
                    {
                        content: (
                            <>
                                {candidate?.cv ? (
                                    <>
                                        <SuperDuperModal
                                            size="large"
                                            trigger={
                                                <Icon
                                                    title={t('scan')}
                                                    name="document-text-outline"
                                                    style={{
                                                        cursor: 'pointer',
                                                        color: 'var(--dark)',
                                                        fontSize: '1.5rem',
                                                    }}
                                                />
                                            }
                                            content={<DocPreview uri={candidate.cv} />}
                                        />
                                        <CanView permissions={['common.c_manage_all_candidates']}>
                                            <Icon
                                                onClick={() => removeFile(candidate, setData)}
                                                name="close"
                                                style={{
                                                    marginLeft: '0.5rem',
                                                    color: 'var(--danger)',
                                                    cursor: 'pointer',
                                                    position: 'relative',
                                                    top: '-0.2rem',
                                                }}
                                            />
                                        </CanView>
                                    </>
                                ) : (
                                    <CanView permissions={['common.c_manage_all_candidates']}>
                                        <SuperDuperModal
                                            trigger={<Button size="tiny" basic content={t('add_cv')} />}
                                            content={
                                                <UploadFile
                                                    field="cv"
                                                    isInvoiceImport
                                                    endpoint={API.CANDIDATES + candidate.id + '/cv/'}
                                                    callback={(response) =>
                                                        setData((prev) =>
                                                            prev.filter((item) => {
                                                                if (item.id === candidate.id) {
                                                                    item.cv = response.cv
                                                                }
                                                                return item
                                                            })
                                                        )
                                                    }
                                                />
                                            }
                                        />
                                    </CanView>
                                )}
                            </>
                        ),
                    },
                    {
                        content: (
                            <Action
                                as="modal"
                                modalSize="tiny"
                                tooltip={t('skills')}
                                header={t('skills')}
                                trigger={
                                    <span style={{ fontSize: '1.2rem', cursor: 'pointer' }}>
                                        <Icon name="library-outline" />{' '}
                                        <sup style={{ padding: '0.2rem', fontWeight: 'bold', opacity: '0.6' }}>
                                            {candidate.skills.length}
                                        </sup>
                                    </span>
                                }
                                modal={<Skills skills={candidate.skills} />}
                            />
                        ),
                    },

                    {
                        content: (
                            <Action
                                as="modal"
                                tooltip={t('candidate_notes')}
                                header={t('candidate_notes')}
                                open={candidate.id === forceModalOpen}
                                onClose={() => setForceModalOpen(0)}
                                trigger={
                                    <span
                                        style={{ fontSize: '1.2rem', cursor: 'pointer' }}
                                        onClick={() => setForceModalOpen(candidate.id)}
                                    >
                                        <Icon name="document-text-outline" />{' '}
                                        <sup style={{ padding: '0.2rem', fontWeight: 'bold', opacity: '0.6' }}>
                                            {candidate.notes.length}
                                        </sup>
                                    </span>
                                }
                                modal={
                                    <Notes
                                        notes={candidate.notes}
                                        endpoint={API.CANDIDATES}
                                        id={candidate.id}
                                        setData={setData}
                                    />
                                }
                            />
                        ),
                    },
                    {
                        customWidth: '150px',
                        content: (
                            <AvatarList
                                size="25"
                                alignDirection="start"
                                persons={candidate?.candidate_recruiters?.map((item) => ({
                                    ID: item?.id,
                                    id: item?.recruiter?.id,
                                    name: item?.recruiter?.fullname,
                                    avatar: item?.recruiter?.profile_picture,
                                    displayName: item?.recruiter?.fullname_with_titles || item?.recruiter?.fullname,
                                }))}
                            />
                        ),
                    },
                    {
                        content: (
                            <div style={{ display: 'flex' }}>
                                <Icon
                                    name="ellipse"
                                    style={{
                                        color: candidate.is_active ? 'var(--success)' : 'var(--danger)',
                                        marginRight: '0.5rem',
                                    }}
                                />
                                <span style={{ position: 'relative', top: '-0.3rem' }}>
                                    {' '}
                                    {candidate.is_active ? t('active_him') : t('inactive_him')}{' '}
                                </span>
                            </div>
                        ),
                    },
                ]}
            />
        </CanView>
    )
}

export default CandidateList
