import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useHistory } from 'react-router-dom'
//store
import { icons } from '@icons'
import { API } from '@store/config'
import { requests } from '@helpers/requests'
import { useFetchData, useQueryPage, useIsMount } from '@helpers/hooks'
//components
import Icon from '@components/Icon'
import Loading from '@components/general/Loading'
import { Button, Container } from 'semantic-ui-react'
import BreadcrumbNav from '@components/BreadcrumbNav'
import SuperDuperModal from '@components/modals/SuperDuperModal'
import { FlexTable, FlexRow, FlexItem } from '@components/tables/FlexTable'
import CanView from '@components/perms/CanView'
import EmptyRow from '@components/tables/EmptyRow'
import Paginator from '@components/Paginator'
// module specific
import Templates from './Templates'
import EmployeeBoarding from '../components/forms/EmployeeBoarding'
import Row from '../components/Row'

const Onboarding = () => {
    const { t } = useTranslation()
    let page = useQueryPage()
    const location = useLocation()
    const isMount = useIsMount()
    const history = useHistory()

    const [view, setView] = useState(1)
    const [limit, setLimit] = useState(10)
    const [result, setResult] = useFetchData(
        API.ONBOARDING_CHECKLIST + '?paginate=true&limit=' + limit + '&page=' + page + '&type=' + view
    )

    const terminate = async (id) => {
        const request = await requests.patch(API.ONBOARDING_CHECKLIST + id + '/', { status: 2 })
        if (request.status === 200) {
            setResult((prev) => ({
                ...prev,
                data: {
                    ...prev.data,
                    results: prev.data.results.filter((item) => {
                        if (item.id === id) {
                            item.status = request?.response?.status
                            item.status_display = request?.response?.status_display
                        }

                        return item
                    }),
                },
            }))
        }
    }

    const fetchBoarding = async (params, isFiltered) => {
        params = params || ''
        if (!result.isLoading) {
            if (isFiltered) {
                page = 1
            } else {
                let searchParams = new URLSearchParams(location.search)
                const param = searchParams.get('page')
                page = param !== null ? param : 1
            }

            if (!params.includes('page')) {
                params += '&page=' + page
            }

            if (!params.includes('type')) {
                params += '&type=' + view
            }

            if (!params.includes('limit')) {
                params += '&limit=' + limit
            }

            setResult((prevState) => ({
                ...prevState,
                isLoading: true,
            }))

            const request = await requests.get(API.ONBOARDING_CHECKLIST + '?paginate=true' + params)

            if (request.status === 200) {
                setResult((prevState) => ({
                    ...prevState,
                    isLoading: false,
                    data: request.response,
                }))
            }
        }
    }

    useEffect(() => {
        if (!isMount && !result.isLoading) {
            history.replace({ pathname: location.pathname, search: `?page=${1}` })
            fetchBoarding('&type=' + view, true)
        }

        // eslint-disable-next-line
    }, [view])

    return (
        <CanView permissions={['onboarding.c_view_all_checklist_instances']} redirect>
            <BreadcrumbNav items={[{ name: 'On/Off-Boarding', icon: icons.ONBOARDING, href: '' }]} />
            <Container fluid>
                <FlexRow padding="0">
                    <FlexItem>
                        <Button.Group
                            basic
                            size="small"
                            style={{ borderRadius: 0 }}
                            className="custom-filter-button-group"
                        >
                            <Button active={view === 1} onClick={() => setView(1)}>
                                On-boarding
                            </Button>
                            <Button active={view === 2} onClick={() => setView(2)}>
                                Off-boarding{' '}
                            </Button>
                        </Button.Group>
                    </FlexItem>
                    <CanView
                        permissions={[
                            'onboarding.c_view_all_checklist_instances',
                            'onboarding.c_manage_all_checklist_configs',
                        ]}
                    >
                        <FlexItem textAlign="right">
                            <CanView permissions={['onboarding.c_manage_all_checklist_configs']}>
                                <SuperDuperModal
                                    trigger={
                                        <Button
                                            color="black"
                                            size="small"
                                            icon={<Icon name="settings-outline" style={{ marginRight: '0.5rem' }} />}
                                            content={
                                                <span
                                                    style={{
                                                        position: 'relative',
                                                        top: '-0.2rem',
                                                    }}
                                                >
                                                    {t('manage_templates')}
                                                </span>
                                            }
                                        />
                                    }
                                    size="large"
                                    header={t('manage_templates')}
                                    content={<Templates />}
                                />
                            </CanView>
                            <CanView permissions={['onboarding.c_view_all_checklist_instances']}>
                                <SuperDuperModal
                                    trigger={<Button primary size="small" content={t('start_boarding')} />}
                                    size="small"
                                    header={t('start_boarding')}
                                    content={
                                        <EmployeeBoarding setResult={setResult} result={result} setListView={setView} />
                                    }
                                />
                            </CanView>
                        </FlexItem>
                    </CanView>
                </FlexRow>
                <div style={{ marginTop: '0.5rem' }} />

                {result.isLoading ? (
                    <Loading />
                ) : (
                    <>
                        <EmptyRow length={result?.data?.results?.length || 0} />
                        <FlexTable stripped={false}>
                            <FlexRow padding="0" />
                            {result?.data?.results?.map((item) => (
                                <Row
                                    data={item}
                                    key={item.id}
                                    originalResult={result}
                                    setOriginResult={setResult}
                                    terminate={() => terminate(item.id)}
                                />
                            ))}
                        </FlexTable>
                    </>
                )}
            </Container>
            <Paginator
                forcePage={page || 1}
                limit={limit}
                setLimit={setLimit}
                length={result?.data?.count || 0}
                onChange={(params) => fetchBoarding(params)}
            />
        </CanView>
    )
}

export default Onboarding
