import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
import { setDotSeparator, isValidNumber } from '@helpers/functions'
// components
import SuperField from '@components/forms/SuperField'
import NonFieldErrors from '@components/NonFieldErrors'
import ModalCancel from '@components/buttons/ModalCancel'
import { Divider, Form, Button, Grid, Header } from 'semantic-ui-react'

const StockItemCorrectionForm = ({ record, setData, onClose, setHeader }) => {
    const { t } = useTranslation()
    const profile = useSelector((state) => state.user?.profile?.id || null)

    const [errors, setErrors] = useState(null)

    // eslint-disable-next-line
    const stored_item = record.stored_item
    const item = record.stored_item.catalogue_item
    const warehouse = record.stored_item.warehouse

    const [isWeightUpdate, setIsWeightUpdate] = useState(item.weight_is_primary_measurement ? true : false)
    const [processing, setProcessing] = useState(false)
    const [form, setForm] = useState({
        quantity: '',
        note: '',
        stored_item: record.stored_item.id,
        warehouse_position: record.warehouse_position.id,
    })

    useEffect(() => {
        setHeader(t('make_a_correction') + ': ' + ((item?.code ? item?.code + ' ' : '') + item.title))
        // eslint-disable-next-line
    }, [])

    const handleSubmit = async () => {
        setProcessing(true)
        setErrors(null)

        let operation_quantity = 0
        let quantity = parseFloat(form.quantity)
        const currentQuantity = parseFloat(record.quantity)

        if (!isWeightUpdate) {
            quantity = parseFloat(quantity * parseFloat(record.average_weight || 0))
        }

        if (quantity === 0) {
            operation_quantity = currentQuantity * -1
        } else {
            if (currentQuantity > quantity) {
                // 10 / 5 => 5 means that we want to deduct 5 from current stock
                operation_quantity = (currentQuantity - quantity) * -1
            } else {
                // 10 / 15 => means that we want to add +5
                operation_quantity = (currentQuantity - quantity) * -1
            }
        }

        operation_quantity = parseFloat(operation_quantity)

        const request = await requests.post(API.ASSETS + 'warehouse_process_operations/', {
            operation: 1, // correction
            performed_by: profile,
            note: form.note,
            quantity: operation_quantity,
            stored_item: form.stored_item,
            warehouse_position: form.warehouse_position || null,
            stock_number: record.stock_number,
        })

        const requestStoredItem = await requests.get(
            API.ASSETS +
                `stored_item_positions/?stock_number=${record.stock_number}&stored_item=${record.stored_item.id}`
        )
        if (request.status === 400) setErrors(request.response)
        if (request.status === 201) {
            if (requestStoredItem.response.length > 0) {
                if (requestStoredItem.response[0]?.id) {
                    setData((prev) =>
                        prev.map((item) => {
                            if (item.id === requestStoredItem.response[0].id) {
                                item = requestStoredItem.response[0]
                            }
                            return item
                        })
                    )
                }
            }
            onClose()
        }

        setProcessing(false)
    }

    const isFormValid = () => {
        if (!isValidNumber(form.quantity, false)) return false
        return true
    }

    const calculatePackages = (stockItem) => {
        let quantity = parseFloat(stockItem.quantity || 0)
        let avgWeight = parseFloat(stockItem.average_weight || 0)
        let packages = quantity

        if (avgWeight > 0 && stockItem.stored_item.catalogue_item.weight_is_primary_measurement) {
            packages = quantity / avgWeight
        }

        return parseInt(packages || 0)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Header as="h4">{t('record_details')}</Header>
            <Grid>
                <Grid.Row columns="2" style={{ padding: 0 }}>
                    <Grid.Column style={{ padding: 0 }}>
                        <strong></strong>
                        {t('warehouse')}: <strong>{warehouse.title}</strong>
                        <br />
                        {t('warehouse_position')}: <strong>{record.warehouse_position.title}</strong>
                        <br />
                        {t('stock_number')}: <strong>{record.stock_number}</strong>
                        <br />
                        {t('measurement_type')}:{' '}
                        <strong>{item.weight_is_primary_measurement ? t('weight') : t('package')}</strong>
                        <br />
                    </Grid.Column>
                    <Grid.Column style={{ padding: 0 }}>
                        {t('current_stock')}: <strong>{record.quantity}</strong>
                        <br />
                        {t('average_weight')}: <strong>{record.average_weight}</strong>
                        <br />
                        {t('packages')}: <strong>{calculatePackages(record)}</strong>
                        <br />
                        {t('measure_unit')}: <strong>{item.measure_unit?.title}</strong>
                        <br />
                    </Grid.Column>
                </Grid.Row>
            </Grid>

            <NonFieldErrors errors={errors} />
            <Divider />

            {item.weight_is_primary_measurement && (
                <Form.Group>
                    <SuperField
                        as="checkbox"
                        label={t('update_weight')}
                        checked={isWeightUpdate}
                        onChange={() => setIsWeightUpdate(!isWeightUpdate)}
                    />
                    <SuperField
                        as="checkbox"
                        label={t('update_packages')}
                        checked={!isWeightUpdate}
                        onChange={() => setIsWeightUpdate(!isWeightUpdate)}
                    />
                </Form.Group>
            )}

            <SuperField
                as="input"
                required
                label={
                    t('new_stock') +
                    ' - ' +
                    (isWeightUpdate ? t('weight').toLocaleLowerCase() : t('packages').toLocaleLowerCase())
                }
                placeholder={t('enter_new_stock_quantity')}
                value={form.quantity}
                error={
                    errors !== null
                        ? errors?.[0]?.quantity
                        : !isValidNumber(form.quantity, true)
                        ? t('invalid_quantity')
                        : false
                }
                onChange={(e, { value }) =>
                    setForm((prev) => ({
                        ...prev,
                        quantity: setDotSeparator(value),
                    }))
                }
            />

            <Divider />
            <SuperField
                as="textarea"
                label={t('note')}
                value={form.note}
                onChange={(e, { value }) =>
                    setForm((prev) => ({
                        ...prev,
                        note: value,
                    }))
                }
            />

            <Divider />
            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose} />
                <Button
                    primary
                    loading={processing}
                    content={t('confirm')}
                    disabled={!isFormValid() || form.quantity === ''}
                />
            </Form.Field>
        </Form>
    )
}

export default StockItemCorrectionForm
