import React from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
// components
import Icon from '@components/Icon'
import Action from '@components/general/Action'
import CanView from '@components/perms/CanView'
import { Grid, Header, Progress, Card, Label } from 'semantic-ui-react'
import { Doughnut } from 'react-chartjs-2'

const HiringRoomCard = ({ item, actions, setData, setTotal }) => {
    const { t } = useTranslation()
    const room = item
    const tableActions = actions?.filter((action) => action.type !== 'add') || []

    const datagraph = (item) => {
        return {
            labels: [t('hired_only'), t('left')],
            datasets: [
                {
                    label: '...',
                    data: [
                        item.hired_candidate_count,
                        item.target && item.hired_candidate_count <= item.target
                            ? item.target - item.hired_candidate_count
                            : 0,
                    ],
                    backgroundColor: ['#1EE3CF', '#c20134'],
                    hoverBackgroundColor: ['#552fe4', '#c9c313'],
                },
            ],
        }
    }

    const getDays = (date) => {
        var m = moment(date)
        var today = moment().startOf('day')

        var days = Math.round(moment.duration(m - today).asDays())

        return days
    }

    const getTotalDays = (dateStart, dateEnd) => {
        var start = moment(dateStart)
        var end = moment(dateEnd)

        var days = Math.round(moment.duration(end - start).asDays())

        return days
    }

    const getDaysTillToday = (date) => {
        var m = moment(date)
        var today = moment().startOf('day')

        var days = Math.round(moment.duration(today - m).asDays())

        return days
    }

    const closeRoom = async (id) => {
        const request = await requests.patch(API.HIRING_ROOM + id + '/', { is_active: false })
        if (request.status === 200) {
            setTotal((prev) => prev - 1)
            setData((prev) => prev.filter((data) => data.id !== id))
        }
    }

    const reOpenRoom = async (id) => {
        const request = await requests.patch(API.HIRING_ROOM + id + '/', { is_active: true })
        if (request.status === 200) {
            setTotal((prev) => prev - 1)
            setData((prev) => prev.filter((data) => data.id !== id))
        }
    }

    return (
        <>
            {room && (
                <Card style={{ marginBottom: 0, padding: 0 }}>
                    <Header
                        as="h3"
                        style={{ textAlign: 'center', marginTop: '0.8rem', fontWeight: 'bold', paddingTop: '1rem' }}
                    >
                        {room.name}
                        <Header.Subheader style={{ textAlign: 'center' }}>
                            {room.hrorder?.id && room.hrorder?.order_number ? (
                                <span style={{ alignContent: 'center' }}>{'#' + room.hrorder.order_number}</span>
                            ) : (
                                '--'
                            )}
                        </Header.Subheader>
                    </Header>
                    <Grid style={{ padding: '1rem' }}>
                        <Grid.Row style={{ padding: 0, alignItems: 'center', justifyContent: 'center' }}>
                            <div>
                                <Doughnut
                                    data={datagraph(room)}
                                    width={400}
                                    options={{
                                        cutoutPercentage: 65,
                                        maintainAspectRatio: false,
                                        legend: { display: false },
                                        responsive: true,
                                        tooltips: {
                                            bodyFontColor: '#fff',
                                            backgroundColor: '#000',
                                        },
                                    }}
                                />
                            </div>
                            <div
                                style={{
                                    paddingLeft: '0.5rem',
                                    textAlign: 'center',
                                    width: '100%',
                                    fontWeight: 'bold',
                                    fontSize: '1.5rem',
                                    paddingTop: '1rem',
                                }}
                            >
                                {(room.target ? (room.hired_candidate_count / room.target) * 100 : 0).toFixed(0)} %
                            </div>
                        </Grid.Row>
                        <Grid.Row
                            style={{ padding: 0, paddingTop: '2rem', alignItems: 'center', justifyContent: 'center' }}
                            columns={3}
                        >
                            <Grid.Column style={{ padding: 0, textAlign: 'center', justifyContent: 'center' }}>
                                <div style={{ color: 'var(--variant2)' }}> {t('target')} </div>
                                {room.target ? (
                                    <Header as="h4" content={room.target} style={{ marginTop: 0 }} />
                                ) : (
                                    <div>--</div>
                                )}
                            </Grid.Column>
                            <Grid.Column style={{ padding: 0, textAlign: 'center', justifyContent: 'center' }}>
                                <div style={{ color: 'var(--variant2)' }}> {t('hired_only')} </div>
                                {room.hired_candidate_count ? (
                                    <Header as="h4" content={room.hired_candidate_count} style={{ marginTop: 0 }} />
                                ) : (
                                    <div>--</div>
                                )}
                            </Grid.Column>
                            <Grid.Column style={{ padding: 0, textAlign: 'center', justifyContent: 'center' }}>
                                <div style={{ color: 'var(--variant2)' }}> {t('left')} </div>
                                {room.hired_candidate_count && room.target ? (
                                    <Header
                                        as="h4"
                                        content={
                                            room.target - room.hired_candidate_count < 0
                                                ? 0
                                                : room.target - room.hired_candidate_count
                                        }
                                        style={{ marginTop: 0 }}
                                    />
                                ) : (
                                    <div>--</div>
                                )}
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={{ padding: 0, alignItems: 'center', justifyContent: 'center' }}>
                            {
                                <span style={{ marginTop: '2rem', alignContent: 'center', color: 'black' }}>
                                    {t('date_end')}
                                </span>
                            }
                        </Grid.Row>
                        <Grid.Row style={{ padding: 0, alignItems: 'center', justifyContent: 'center' }}>
                            {
                                <strong style={{ marginTop: 0, alignContent: 'center', color: 'black' }}>
                                    {room.date_to
                                        ? moment(room.date_to).format('DD.MM.YYYY') +
                                          (getDays(room.date_to) >= 0
                                              ? ', ' + t('in') + ' ' + getDays(room.date_to) + ' ' + t('days')
                                              : ', ' +
                                                t('before') +
                                                ' ' +
                                                Math.abs(getDays(room.date_to)) +
                                                ' ' +
                                                t('dayss'))
                                        : '--'}
                                </strong>
                            }
                        </Grid.Row>
                        <Grid.Row style={{ display: 'table-row' }}>
                            <div>
                                <Progress
                                    value={getDaysTillToday(room.date_from)}
                                    total={getTotalDays(room.date_from, room.date_to)}
                                    progress="percent"
                                    size="small"
                                />
                            </div>
                        </Grid.Row>
                        <Grid.Row style={{ display: 'table-row', paddingTop: 0, paddingBottom: 0 }}>
                            <div style={{ height: '1px' }}>
                                {room?.labels?.map((label, idx) => (
                                    <Label
                                        basic
                                        key={label.id}
                                        style={{
                                            background: label.color === '' ? 'var(--dark)' : label.color + 'B3',
                                            padding: '0.2rem',
                                        }}
                                    >
                                        <span
                                            style={{
                                                position: 'relative',
                                                top: '-0.05rem',
                                                fontSize: '0.8rem',
                                                color: 'var(--dark)',
                                            }}
                                        >
                                            {label.name}
                                        </span>
                                    </Label>
                                ))}
                            </div>
                        </Grid.Row>
                    </Grid>
                    <Grid style={{ backgroundColor: 'var(--light)', padding: '0.5rem', paddingTop: '1rem' }}>
                        <Grid.Row style={{ padding: 0, alignContent: 'center' }} columns={2} verticalAlign="middle">
                            <Grid.Column style={{ padding: 0, textAlign: 'left' }}>
                                <>
                                    {room.is_active ? (
                                        <>
                                            {' '}
                                            <Icon
                                                style={{ marginRight: '0.8rem', color: 'var(--success)' }}
                                                name="ellipse"
                                            />{' '}
                                            <strong
                                                style={{ position: 'relative', top: '-0.2rem', color: 'var(--dark)' }}
                                            >
                                                {t('active')}
                                            </strong>
                                        </>
                                    ) : (
                                        <>
                                            {' '}
                                            <Icon
                                                style={{ marginRight: '0.8rem', color: 'var(--danger)' }}
                                                name="ellipse"
                                            />{' '}
                                            <strong
                                                style={{ position: 'relative', top: '-0.2rem', color: 'var(--dark)' }}
                                            >
                                                {t('inactive')}
                                            </strong>
                                        </>
                                    )}
                                </>
                            </Grid.Column>
                            <Grid.Column style={{ padding: 0, textAlign: 'right' }}>
                                <>
                                    {tableActions.map((action, index) => (
                                        <CanView permissions={action.permissions || []} key={index}>
                                            {action?.isHidden?.(item) !== true && (
                                                <Action
                                                    type="icon"
                                                    size="huge"
                                                    icon={action.icon}
                                                    as={action.as}
                                                    paddingTop="0"
                                                    paddingBottom="0"
                                                    paddingLeft={'1rem'}
                                                    paddingRight="0"
                                                    to={action.redirect !== undefined ? action.redirect(item) : null}
                                                    tooltip={action.name}
                                                    iconColor={'var(--dark)'}
                                                />
                                            )}
                                        </CanView>
                                    ))}
                                    {room.is_active ? (
                                        <Action
                                            as="delete"
                                            size="huge"
                                            tooltip={t('close_room')}
                                            text={t('are_you_sure_that_you_want_to_close_this_room')}
                                            paddingRight="0"
                                            onClick={() => closeRoom(room.id)}
                                        />
                                    ) : (
                                        <Action
                                            as="confirm"
                                            type="icon"
                                            size="huge"
                                            iconColor="var(--dark)"
                                            tooltip={t('reopen_room')}
                                            icon="arrow-undo-circle-outline"
                                            text={t('are_you_sure_that_you_want_to_reopen_this_room')}
                                            paddingRight="0"
                                            onClick={() => reOpenRoom(room.id)}
                                        />
                                    )}
                                </>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Card>
            )}
        </>
    )
}

export default HiringRoomCard
