import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { useTranslation } from "react-i18next";

// store
import { API } from '@store/config'
import { createRequest } from '@services/ServiceCommon'
// components
import { Form, Header, Button, Divider } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';

const BugReporterForm = () => {
    const { t } = useTranslation();
    const user = useSelector(state => state.user)
    const loc = useLocation()

    const [form, setForm] = useState({
        title: '',
        description: '',
        screenshot: '',
        user: { name: user.name, email: user.email, role: user.role_name },
        type: 0, // 0-BUG    1-IMPROVEMENT,
        path: loc.pathname,
    });

    const [files, setFiles] = useState([])
    const [loading, setLoading] = useState(false)

    function getSubDomain (){
        let domain = window.location.href.toString()
        domain = domain.replace("www.", "")
        domain = domain.replace("https://", "")
        domain = domain.replace("http://", "")
    
        return domain.split('.')[0] 
    }

    async function handleSubmit() {
        setLoading(true)

        let subdomain = getSubDomain()
        if (subdomain !== "") subdomain = `[${subdomain}]: `

        let formData = {
            description: `### Description\n\n${form.description + "\n\n"} - User:  **${form.user.name}**\n - Email: **${form.user.email}**\n - Role: **${form.user.role}**\n\nPath: _${form.path}_`,
            title: `${subdomain}${form.title}`,
            labels: form.type === 0 ? ['Bug', 'Backlog', 'Reported'] : ['Improvement', 'Backlog', 'Reported'],
        }
        if (files.length > 0) {
            const endpoint = API.GIT_ATTACHMENTS
            formData = { ...formData, attachments: [] }

            for (let i = 0; i < files.length; i++) {
                const attachmentFormData = new FormData()
                attachmentFormData.append('file', files[i])
                const attachments = await createRequest(endpoint, attachmentFormData)
                if (attachments.status === 201) {
                    formData = { ...formData, attachments: [...formData.attachments, attachments.response.id] }
                }
            }

        }
        const request = await createRequest(API.GIT_ISSUE, formData)
        if (request.status === 201) {
            setForm({
                title: '',
                description: '',
                screenshot: '',
                user: { name: user.name, email: user.email, role: user.role_name },
                type: 0, // 0-BUG    1-IMPROVEMENT,
                path: loc.pathname
            })
            setFiles([])
            document.getElementById('screenshot').value = null
        }
        setLoading(false)
    }

    return (
        <span>
            <Form>
                <Header
                    as='h1'
                    className={`bug-reporter-form active`}
                    style={{ textAlign: 'center' }}
                    content={t('bug-reporter')}
                />
                <Divider />
                <Form.Group widths="equal">
                    <SuperField
                        as="input"
                        required
                        label={t('title')}
                        value={form.title}
                        className={`bug-reporter-form active`}
                        onChange={(e, { value }) => setForm({ ...form, title: value })}
                    />
                    <Form.Field style={{ display: 'flex', marginTop: "2.4rem" }}>
                        <SuperField
                            className={`bug-reporter-form active`}
                            label={t('bug')}
                            checked={form.type === 0}
                            onChange={() => setForm({ ...form, type: 0 })}
                            as="radio"
                        />
                        <SuperField
                            className={`bug-reporter-form active`}
                            label={t('improvement')}
                            style={{ margin: '0 1rem' }}
                            checked={form.type === 1}
                            onChange={() => setForm({ ...form, type: 1 })}
                            as="radio"
                        />
                    </Form.Field>
                </Form.Group>
                <SuperField
                    as="textarea"
                    rows="6"
                    className={`bug-reporter-form active`}
                    label={t('description')}
                    value={form.description}
                    onChange={(e, { value }) => setForm({ ...form, description: value })}
                />
                <Form.Input
                    type="file"
                    multiple
                    accept=".png, .jpg, .jpeg"
                    id="screenshot"
                    name="screenshot"
                    label={t('files')}
                    onChange={(event) => {
                        if (event.target?.files?.length > 0) {
                            setFiles(event.target.files)
                        }
                    }}
                />
                <Divider />
            </Form>
            
            <div style={{ display: 'flex', marginTop: '0.5rem' }} className={`bug-reporter-form active`} >
                <Button
                    loading={loading}
                    disabled={form.title === '' || loading}
                    primary
                    onClick={() => handleSubmit()}
                    content={t('send-report')} />
            </div>
        </span>
    )
}
export default BugReporterForm