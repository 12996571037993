import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useHistory } from 'react-router-dom'
// store
import { API } from '@store/config'
import { useFetchData, useQueryPage, useIsMount } from '@helpers/hooks'
import { requests } from '@helpers/requests'
// components
import Icon from '@components/Icon'
import Paginator from '@components/Paginator'
import CanView from '@components/perms/CanView'
import ConfirmModal from '@components/modals/ConfrimModal'
import SuperDuperModal from '@components/modals/SuperDuperModal'
import { Grid, Segment, Header, Divider, Ref, Sidebar, Menu, Button } from 'semantic-ui-react'
import { FlexTable, FlexRow, FlexHeader, FlexItem } from '@components/tables/FlexTable'
// module specific
import ItemForm from './forms/ItemForm'
import CategoryForm from './forms/CategoryForm'
import ItemFilterForm from './forms/ItemFilterForm'

const ItemsAndCategories = ({ response, setResponse }) => {
    const { t } = useTranslation()
    let page = useQueryPage()
    const location = useLocation()
    const history = useHistory()
    const isMount = useIsMount()
    const segmentRef = useRef()

    page = isMount ? 1 : page

    const [visible, setVisible] = useState(false)
    const [limit, setLimit] = useState(10)
    const [items, setItems] = useFetchData(API.ASSET_ITEMS + '?paginate=true&page=' + page + '&limit=' + limit)
    const [categories, setCategories] = useFetchData(API.ASSET_CATEGORIES)
    const [filters, setFilters] = useState({
        category: '',
        is_vehicle: false,
        is_safety_equipment: false,
        search: '',
    })

    useEffect(() => {
        if (response !== null) {
            if (response.type === 'category_create') {
                setCategories({
                    ...categories,
                    data: [response.result, ...categories.data],
                })
            }

            if (response.type === 'item_create') {
                setItems((prev) => ({
                    ...prev,
                    data: {
                        ...prev.data,
                        count: prev.data.count + 1,
                        results: [response.result, ...prev.data.results],
                    },
                }))
            }

            setResponse(null)
        }
        // eslint-disable-next-line
    }, [response])

    const onDelete = async (type, id) => {
        let request = undefined
        if (type === 'category_delete') {
            request = await requests.post(API.ASSET_CATEGORIES + id + '/delete/')
        } else if (type === 'item_delete') {
            request = await requests.post(API.ASSET_ITEMS + id + '/delete/')
        }

        if (request !== undefined && request.status === 200) {
            if (type === 'category_delete') {
                setCategories((prev) => ({
                    ...prev,
                    data: prev.data.filter((item) => item.id !== id),
                }))
            }

            if (type === 'item_delete') {
                setItems((prev) => ({
                    ...prev,
                    data: {
                        ...prev.data,
                        count: prev.data.count - 1,
                        results: prev.data.results.filter((item) => item.id !== id),
                    },
                }))
            }
        }
    }

    const onFilter = async (params, isFiltered) => {
        setItems((prev) => ({ ...prev, isLoading: true }))

        if (isFiltered) {
            page = 1
        } else {
            let searchParams = new URLSearchParams(location.search)
            const param = searchParams.get('page')
            page = param !== null ? param : 1
        }

        if (!params.includes('page')) {
            params += '&page=' + page
        }

        if (!params.includes('limit')) {
            params += '&limit=' + limit
        }

        params += filters.search !== '' ? '&search=' + filters.search : ''
        params += filters.is_vehicle !== false ? '&is_vehicle=' + filters.is_vehicle : ''
        params += filters.is_safety_equipment !== false ? '&is_safety_equipment=' + filters.is_safety_equipment : ''
        params += filters.category !== '' ? '&category=' + filters.category : ''
        params += filters.type !== '' ? '&type=' + filters.type : ''

        const request = await requests.get(API.ASSET_ITEMS + '?paginate=true' + params)
        if (request.status === 200) {
            setItems((prev) => ({ ...prev, data: request.response }))
        }

        setItems((prev) => ({ ...prev, isLoading: false }))
    }

    return (
        <Ref innerRef={segmentRef}>
            <Sidebar.Pushable
                as={Segment}
                style={{ border: 'none', background: 'transparent', boxShadow: 'none', padding: 0, margin: 0 }}
                className="fixed-sidebar"
            >
                <Sidebar
                    style={{ width: '40rem' }}
                    as={Menu}
                    animation="overlay"
                    direction="right"
                    icon="labeled"
                    vertical
                    visible={visible}
                    onHide={() => setVisible(false)}
                    width="wide"
                    target={segmentRef}
                >
                    <ItemFilterForm
                        filters={filters}
                        setFilters={setFilters}
                        setVisible={setVisible}
                        onFilter={async () => {
                            history.replace({ pathname: location.pathname, search: `?page=${1}` })
                            await onFilter('', true)
                        }}
                    />
                </Sidebar>
                <Sidebar.Pusher>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column computer={12} tablet={12} mobile={16}>
                                <Segment
                                    loading={items.isLoading}
                                    style={{
                                        padding: 0,
                                        background: 'transparent',
                                        boxShadow: 'none',
                                        border: 'none',
                                    }}
                                >
                                    <FlexRow background="transparent" padding="0">
                                        <FlexItem>
                                            <Header as="h3" content={t('items')} />
                                        </FlexItem>
                                        <FlexItem textAlign="right">
                                            <Button
                                                size="small"
                                                style={{
                                                    background: 'transparent',
                                                    color: 'var(--dark)',
                                                    paddingLeft: '1rem',
                                                    paddingRight: '1rem',
                                                }}
                                                onClick={() => setVisible(!visible)}
                                            >
                                                <Icon name="funnel-outline" />
                                            </Button>
                                        </FlexItem>
                                    </FlexRow>

                                    <Divider />
                                    <FlexTable>
                                        <FlexRow>
                                            <FlexHeader basis="150%" content={t('title')} />
                                            <FlexHeader content={t('safety_equipment')} />
                                            <FlexHeader content={t('category')} />
                                            <FlexHeader content={t('type')} />
                                            <FlexHeader content={t('lifetime')} />
                                            <FlexHeader content={t('price')} />
                                            <CanView permissions={['assets.c_manage_items']}>
                                                <FlexHeader content={t('actions')} />
                                            </CanView>
                                        </FlexRow>
                                        {(items?.data?.count === 0 || items === undefined) && (
                                            <FlexRow background="var(--white)" fontSize="1rem" borders>
                                                <FlexItem bold textAlign="center" content={t('no_data')} />
                                            </FlexRow>
                                        )}

                                        {items?.data?.results?.map((item) => (
                                            <FlexRow background="var(--white)" fontSize="1rem" borders key={item.id}>
                                                <FlexItem
                                                    bold
                                                    basis="150%"
                                                    content={
                                                        <>
                                                            <div>{item.title}</div>
                                                            {item.is_vehicle && (
                                                                <strong>
                                                                    <Icon
                                                                        name="car-outline"
                                                                        style={{ marginRight: '0.5rem' }}
                                                                    />{' '}
                                                                    <span
                                                                        style={{ position: 'relative', top: '-0.2rem' }}
                                                                    >
                                                                        {t('vehicle')}
                                                                    </span>
                                                                </strong>
                                                            )}
                                                        </>
                                                    }
                                                />
                                                <FlexItem content={item?.is_safety_equipment ? t('yes') : t('no')} />
                                                <FlexItem
                                                    content={item?.category ? item.category?.title : t('no_category')}
                                                />
                                                <FlexItem content={item?.type_display || ''} />
                                                <FlexItem content={item.lifetime_in_months} />
                                                <FlexItem content={item?.total_price || '--'} />
                                                <CanView permissions={['assets.c_manage_items']}>
                                                    <FlexItem>
                                                        <FlexRow padding="0" background="transparent">
                                                            <FlexItem>
                                                                <SuperDuperModal
                                                                    trigger={
                                                                        <Icon
                                                                            name="pencil-outline"
                                                                            style={{
                                                                                color: 'var(--dark)',
                                                                                cursor: 'pointer',
                                                                            }}
                                                                        />
                                                                    }
                                                                    size="small"
                                                                    content={
                                                                        <ItemForm item={item} setItems={setItems} />
                                                                    }
                                                                />
                                                            </FlexItem>
                                                            <FlexItem>
                                                                <ConfirmModal
                                                                    description={t('are_you_sure')}
                                                                    onConfirm={() => onDelete('item_delete', item.id)}
                                                                    button={
                                                                        <Icon
                                                                            name="close-outline"
                                                                            style={{
                                                                                color: 'var(--danger)',
                                                                                cursor: 'pointer',
                                                                            }}
                                                                        />
                                                                    }
                                                                />
                                                            </FlexItem>
                                                        </FlexRow>
                                                    </FlexItem>
                                                </CanView>
                                            </FlexRow>
                                        ))}
                                    </FlexTable>
                                </Segment>
                                <Paginator
                                    forcePage={page || 1}
                                    limit={limit}
                                    setLimit={setLimit}
                                    length={items?.data?.count || 0}
                                    onChange={(params) => onFilter(params)}
                                />
                            </Grid.Column>
                            <Grid.Column computer={4} tablet={4} mobile={16}>
                                <Segment
                                    loading={categories.isLoading}
                                    style={{
                                        padding: 0,
                                        background: 'transparent',
                                        boxShadow: 'none',
                                        border: 'none',
                                    }}
                                >
                                    <FlexRow background="transparent" padding="0">
                                        <FlexItem>
                                            <Header
                                                as="h3"
                                                content={t('categories')}
                                                style={{ paddingTop: '0.5rem', paddingBottom: '0.5rem' }}
                                            />
                                        </FlexItem>
                                    </FlexRow>
                                    <Divider />
                                    <FlexTable responsive={false}>
                                        <FlexRow>
                                            <FlexHeader basis="200%" content={t('title')} />
                                            <CanView permissions={['assets.c_manage_item_categories']}>
                                                <FlexHeader content={t('actions')} />
                                            </CanView>
                                        </FlexRow>
                                        {(categories?.data?.length === 0 || categories === undefined) && (
                                            <FlexRow background="var(--white)" fontSize="1rem" borders>
                                                <FlexItem bold textAlign="center" content={t('no_data')} />
                                            </FlexRow>
                                        )}

                                        {categories?.data?.map((category) => (
                                            <FlexRow
                                                background="var(--white)"
                                                fontSize="1rem"
                                                borders
                                                key={category.id}
                                            >
                                                <FlexItem basis="200%" bold>
                                                    <span style={{ marginRight: '4rem' }}>{category.title}</span>
                                                </FlexItem>
                                                <CanView permissions={['assets.c_manage_item_categories']}>
                                                    <FlexItem>
                                                        <SuperDuperModal
                                                            trigger={
                                                                <Icon
                                                                    name="pencil-outline"
                                                                    style={{ color: 'var(--dark)', cursor: 'pointer' }}
                                                                />
                                                            }
                                                            size="small"
                                                            content={
                                                                <CategoryForm
                                                                    category={category}
                                                                    setCategories={setCategories}
                                                                />
                                                            }
                                                        />
                                                        <ConfirmModal
                                                            description={t('are_you_sure')}
                                                            onConfirm={() => onDelete('category_delete', category.id)}
                                                            button={
                                                                <Icon
                                                                    name="close-outline"
                                                                    style={{
                                                                        color: 'var(--danger)',
                                                                        cursor: 'pointer',
                                                                    }}
                                                                />
                                                            }
                                                        />
                                                    </FlexItem>
                                                </CanView>
                                            </FlexRow>
                                        ))}
                                    </FlexTable>
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Sidebar.Pusher>
            </Sidebar.Pushable>
        </Ref>
    )
}

export default ItemsAndCategories
