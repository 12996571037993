import React from 'react';
import { Link } from 'react-router-dom';
// components
import Icon from '@components/Icon';
import { List, Popup } from 'semantic-ui-react';
import CanView from '@components/perms/CanView';

const SidebarItem = ({ permissions, modules, to, icon, text, paddingLeft, hide, toggle }) => {

    // check if contain specific classname
    return (
        <CanView permissions={permissions} modules={modules}>
            <Popup className="sidebar-item-popup"
                disabled={!hide}
                flowing position="right center" hoverable
                trigger={
                    <List.Item
                        as={Link} to={to}
                        className={window.location.href.includes(to) ? "sidebar-item is-active" : "sidebar-item"}
                        onClick={() => toggle()}
                    >
                        <Icon
                            name={icon}
                            style={{ fontSize: "1.3rem" }}
                            className={window.location.href.includes(to) ? "is-active" : ""} 
                        />
                        <span style={{ position: 'relative', top: "-0.3rem", paddingLeft: "0.8rem" }}>{ text }</span>
                    </List.Item>
                } >
                {text}
            </Popup>
        </CanView>
    );

};

export default SidebarItem;