import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
// store
import { API } from '@store/config'
import { useFetchData } from '@helpers/hooks'
import { getParams } from '@helpers/functions'
import { routes } from '@routes/index'
import { requests } from '@helpers/requests'
// components
import { Button, Header, Divider, Grid, Container } from 'semantic-ui-react'
import { FlexTable, FlexRow, FlexHeader, FlexItem } from '@components/tables/FlexTable'
import EmptyRow from '@components/tables/EmptyRow'
import Loading from '@components/general/Loading'
import Action from '@components/general/Action'
import GdprForm from './GdprForm'
import CanView from '@components/perms/CanView'

const Gdpr = () => {
    const { t } = useTranslation()

    const [result, setResult] = useFetchData(API.GDPRS + '?paginate=true')

    const types = useSelector((state) => state.choices?.gdpr_types)

    const previous = getParams(result.data?.previous)
    const next = getParams(result.data?.next)

    const fetchGdpr = async (params) => {
        setResult((prevState) => ({
            ...prevState,
            isLoading: true,
        }))
        const request = await requests.get(API.GDPRS + '?paginate=true' + params)

        if (request.status === 200) {
            setResult((prevState) => ({
                ...prevState,
                isLoading: false,
                data: request.response,
            }))
        } else {
            setResult((prevState) => ({
                ...prevState,
                isLoading: false,
            }))
        }
    }

    const onDelete = async (id) => {
        const request = await requests.del(API.GDPRS + id + '/')
        if (request.status === 204) {
            setResult((prevState) => ({
                ...prevState,
                data: {
                    ...prevState.data,
                    results: prevState.data.results.filter((item) => item.id !== id),
                },
            }))
        }
    }

    return (
        <Container fluid style={{ marginTop: '1rem' }}>
            <CanView permissions={['common.c_view_gdpr']} redirect>
                <Grid columns="2">
                    <Grid.Column style={{ paddingBottom: 0 }}>
                        <Header as="h3">{t('gdpr')}</Header>
                    </Grid.Column>
                    <Grid.Column textAlign="right" style={{ paddingBottom: 0, paddingTop: 0 }}>
                        <CanView permissions={['common.c_manage_gdpr']}>
                            <Action
                                as="modal"
                                closeOnDimmerClick={false}
                                type="addButton"
                                modalSize="large"
                                buttonText={t('add_gdpr')}
                                modal={<GdprForm setResult={setResult} />}
                            />
                        </CanView>
                    </Grid.Column>
                </Grid>
                <Divider />
                <FlexTable>
                    <FlexRow>
                        <FlexHeader content={t('title')} />
                        <FlexHeader content={t('type')} />
                        <FlexHeader content={t('length_in_months')} />
                        <FlexHeader content={t('actions')} basis="15%" />
                    </FlexRow>
                    <EmptyRow length={result?.data?.length} />
                    {result.isLoading ? (
                        <Loading />
                    ) : (
                        result?.data?.results?.map((gdpr) => (
                            <FlexRow background="var(--white)" key={gdpr.id} fontSize="1rem" borders>
                                <FlexItem content={gdpr.title} />
                                <FlexItem content={types[gdpr.type]} />
                                <FlexItem content={gdpr.lenght_in_months} />
                                <FlexItem basis="15%">
                                    <FlexRow background="transparent" padding="0">
                                        <FlexItem>
                                            <Action
                                                type="icon"
                                                as="link"
                                                to={routes.GDPRS + gdpr.id + '/'}
                                                tooltip={t('view_detail')}
                                            />
                                        </FlexItem>
                                        <CanView permissions={['common.c_manage_gdpr']}>
                                            <FlexItem>
                                                <Action
                                                    as="modal"
                                                    type="icon"
                                                    icon="pencil"
                                                    modalSize="large"
                                                    tooltip={t('edit')}
                                                    modal={
                                                        <GdprForm gdpr={gdpr} result={result} setResult={setResult} />
                                                    }
                                                />
                                            </FlexItem>
                                        </CanView>
                                        <CanView permissions={['common.c_delete_gdpr']}>
                                            <FlexItem>
                                                <Action
                                                    as="delete"
                                                    tooltip={t('delete')}
                                                    text={t('delete_this')}
                                                    onClick={() => onDelete(gdpr.id)}
                                                />
                                            </FlexItem>
                                        </CanView>
                                    </FlexRow>
                                </FlexItem>
                            </FlexRow>
                        ))
                    )}
                </FlexTable>
                <FlexRow>
                    <FlexItem basis="50%">
                        <Button.Group>
                            <Action
                                as="custom"
                                type="icon"
                                icon="angle double left"
                                disabled={previous === null}
                                primary
                                onClick={previous !== null ? () => fetchGdpr(previous) : null}
                                iconColor="var(--white)"
                                paddingLeft="1rem"
                                paddingRight="1rem"
                            />
                            <Action
                                as="custom"
                                type="icon"
                                icon="angle double right"
                                disabled={previous === null}
                                primary
                                onClick={next !== null ? () => fetchGdpr(next) : null}
                                iconColor="var(--white)"
                                paddingLeft="1rem"
                                paddingRight="1rem"
                            />
                        </Button.Group>
                    </FlexItem>
                </FlexRow>
            </CanView>
        </Container>
    )
}

export default Gdpr
