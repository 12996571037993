import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
// store
import { icons } from '@store/icons';
// components
import Icon from '@components/Icon';
import CanView from '@components/perms/CanView';
import BreadcrumbNav from '@components/BreadcrumbNav';
import { Button, Container, Divider } from 'semantic-ui-react';
// custom components
import OrderProcesses from '../order_processes';
import SaleOrder from './sales';
import ServiceOrder from './service';
import HrOrder from './recruitment';
import TransportOrder from './transport';
import EmploymentOrder from './employment';

// eslint-disable-next-line
const determinateActiveInitialOrder = (allowedOrderTypes) => {
    if (allowedOrderTypes['hr_order'] === true) return 1
    if (allowedOrderTypes['employment_order'] === true) return 2
    if (allowedOrderTypes['sale_order'] === true) return 4
    if (allowedOrderTypes['service_order'] === true) return 3
    if (allowedOrderTypes['transport_order'] === true) return 5

    return 0
}

const OrdersGUI = () => {
    const { t } = useTranslation()
    const allowedOrderTypes = useSelector(state => state.allowed_order_types)
    const defaultOrderType = useSelector(store => store.default_order_types)

    // 0 - overview, 6 - processes  ||  1 - HR Order, 2 - Employment Order, 3 - Service Order, 4 - Transport Order, 5 - Sale Order
    // const [view, setView] = useState(6)
    const [view, setView] = useState(() => determinateActiveInitialOrder(allowedOrderTypes))

    const isAllowed = (orderType) => {
        return allowedOrderTypes[orderType]
    }

    return (
        <CanView permissions={['orders.c_view_order']} redirect>
            <BreadcrumbNav items={[
                { 'name': t('orders'), 'icon': icons.ORDERS, 'href': "" }
            ]}/>
            <Container fluid>
                <Button.Group basic size="small" style={{ borderRadius: '0' }} className='custom-filter-button-group'>
                    {/* <Button disabled active={view === 0} onClick={() => setView(0)}>
                        <Icon name="bar-chart-outline" style={{ marginRight: "0.5rem" }}/>
                        <span style={{ position: "relative", top: "-0.1rem" }}>
                            { t('overview') } 
                        </span>
                    </Button>   */}
                    { (isAllowed("hr_order")) && 
                        <Button active={view === 1} onClick={() => setView(1)}>
                            <Icon name={icons.ORDERS} style={{ marginRight: "0.5rem" }}/> 
                            <span style={{ position: "relative", top: "-0.1rem" }}>
                                { t('recruitment') } 
                            </span>
                        </Button>
                    }
                    { (isAllowed("employment_order")) && 
                        <Button active={view === 2} onClick={() => setView(2)}>
                            <Icon name={icons.ORDERS} style={{ marginRight: "0.5rem" }}/> 
                            <span style={{ position: "relative", top: "-0.1rem" }}>
                                { t('employment') } 
                            </span>
                        </Button>
                    }
                    { isAllowed("sale_order") && 
                        <Button active={view === 4} onClick={() => setView(4)}>
                            <Icon name={icons.ORDERS} style={{ marginRight: "0.5rem" }}/> 
                            <span style={{ position: "relative", top: "-0.1rem" }}>
                                { t("business") } 
                            </span>
                        </Button> 
                    }
                    { (isAllowed("service_order")) && 
                        <Button active={view === 3} onClick={() => setView(3)}>
                            <Icon name={icons.ORDERS} style={{ marginRight: "0.5rem" }}/> 
                            <span style={{ position: "relative", top: "-0.1rem" }}>
                                { t('service') } 
                            </span>
                        </Button>
                    }
                    { isAllowed("transport_order") && 
                        <Button active={view === 5} onClick={() => setView(5)}>
                            <Icon name={icons.ORDERS} style={{ marginRight: "0.5rem" }}/> 
                            <span style={{ position: "relative", top: "-0.1rem" }}>
                                { t('transport') } 
                            </span>
                        </Button> 
                    } 
                            
                    <Button active={view === 6} onClick={() => setView(6)}>
                        <Icon name={icons.ORDER_JOURNAL} style={{ marginRight: "0.5rem" }}/>
                        <span style={{ position: "relative", top: "-0.1rem" }}>
                            { t('order_journal') } 
                        </span>
                    </Button>           
                </Button.Group>
                <Divider style={{ marginBottom:  0 }}/>
            </Container>

            { view === 0 && <Container fluid>In preparation</Container> }
            { view === 1 && <HrOrder isPurchase={false}/> }
            { view === 2 && <EmploymentOrder isPurchase={false}/> }
            { view === 3 && <ServiceOrder isPurchase={false}/> }
            { view === 4 && <SaleOrder isPurchase={defaultOrderType.sale_order === "issued"}/> }
            { view === 5 && <TransportOrder isPurchase={defaultOrderType.transport_order === "issued"}/> }
            { view === 6 && <OrderProcesses isPurchase={false}/> }
        </CanView>
    );
};

export default OrdersGUI;