import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { addChoices, addLanguage } from '@actions'
// store
import { routes } from '@routes'
import { languageOptions } from '@services/helpers/settings'
import { authService } from '@services/ServiceAuth'
import { userService } from '@services/ServiceUser'
import { commonService } from '@services/ServiceCommon'
import { isEmpty } from '@helpers/validation'
import { API } from '@store/config'
import { createRequest } from '@services/ServiceCommon'

import { Button, Form, Grid, Header, Divider, Container } from 'semantic-ui-react'
import SuperField from '@components/forms/SuperField'

const FirstLoginPage = () => {
    const { t, i18n } = useTranslation()
    let history = useHistory()
    let dispatch = useDispatch()

    const user = useSelector((state) => state.user)

    const [currentUsername] = useState(user.username)

    // form data
    const [language, setLanguage] = useState('en')
    const [username, setUsername] = useState('')
    const [oldpassword, setOldPassword] = useState('')
    const [newpassword, setNewPassword] = useState('')

    // form state
    const [allowUsernameChange, setAllowUsernameChange] = useState(false)
    const [isProcessing, setIsProcessing] = useState(false)
    const [usernameError, setUsernameError] = useState(null)
    const [oldPasswordError, setOldPasswordError] = useState(null)
    const [newPasswordError, setNewPasswordError] = useState(null)

    useEffect(() => {
        dispatch(addLanguage(language))
        i18n.changeLanguage(language)

        // eslint-disable-next-line
    }, [language])

    const logoutUser = () => {
        authService.logout(dispatch)
        history.push(routes.LOGIN)
    }

    const changePrefLanguage = async () => {
        // set preferable language
        let newLanguage = language
        if (language === 'en') {
            newLanguage = 'en-us' // API requirement for English - will be changed to "en" in the future
        } else if (language === 'cz') {
            newLanguage = 'cs'
        }

        const languageChangeRequest = await userService.setPreference(
            'language__user_language_preferences',
            newLanguage
        )

        if (languageChangeRequest.status === 200) {
            dispatch(addLanguage(language))
            i18n.changeLanguage(language)

            // fetch new choices based on selected language
            const choices = await commonService.getChoices()
            if (choices.status === 200) {
                dispatch(addChoices(choices.response))
            }
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setUsernameError(null)
        setOldPasswordError(null)
        setNewPasswordError(null)
        setIsProcessing(true)

        // 0. Change pref. language
        // eslint-disable-next-line
        await changePrefLanguage()

        // 1. Check if username was filled, then ask for username change
        let requestUsernameChange = null
        if (!isEmpty(username) && username !== currentUsername) {
            requestUsernameChange = await createRequest(API.SET_USERNAME, {
                current_password: oldpassword,
                new_username: username,
            })

            if (requestUsernameChange.status === 400) {
                if (requestUsernameChange.response?.new_username) {
                    setUsernameError(requestUsernameChange.response?.new_username)
                }

                if (requestUsernameChange.response?.current_password) {
                    setOldPasswordError(requestUsernameChange.response?.current_password)
                }
                setIsProcessing(false)
                return
            }
        }

        // 2. If username wasnt filled then ask for password change without checking response from username change (because there isnt any response)
        const request = await createRequest(API.SET_PASSWORD, {
            current_password: oldpassword,
            new_password: newpassword,
        })

        if (request.status === 400) {
            if (request.response?.current_password) {
                setOldPasswordError(request.response?.current_password)
            }
            if (request.response?.new_password) {
                setNewPasswordError(request.response?.new_password)
            }
            setIsProcessing(false)
            return
        }

        if (request.status === 204) {
            const loginUsername =
                requestUsernameChange !== null && requestUsernameChange.status === 204 ? username : currentUsername
            // eslint-disable-next-line
            const login = await authService.login(loginUsername, newpassword, dispatch, i18n)
        }
        setIsProcessing(false)
    }

    return (
        <Container>
            <Grid centered>
                <Grid.Row style={{ marginTop: '3rem' }}>
                    <Grid.Column
                        computer={8}
                        tablet={16}
                        mobile={16}
                        style={{ background: 'var(--white)', padding: '1.5rem' }}
                    >
                        <Header
                            as="h1"
                            style={{ padding: '0', fontSize: '2rem', textAlign: 'left' }}
                            content={t('setup_profile')}
                        />
                        <p> {t('profile_setup_to_protect_change_password')} </p>

                        <Form onSubmit={handleSubmit}>
                            <SuperField
                                as="input"
                                fluid
                                required
                                icon="lock"
                                type="password"
                                iconPosition="left"
                                label={t('old_password')}
                                placeholder={t('old_password')}
                                help={t('verify_message')}
                                value={oldpassword}
                                error={oldPasswordError !== null ? oldPasswordError : false}
                                onChange={(e) => setOldPassword(e.target.value)}
                            />

                            <SuperField
                                as="input"
                                icon="lock"
                                iconPosition="left"
                                required
                                fluid
                                type="password"
                                label={t('new_password')}
                                placeholder={t('new_password')}
                                value={newpassword}
                                error={newPasswordError !== null ? newPasswordError : false}
                                onChange={(e) => setNewPassword(e.target.value)}
                            />

                            <Header as="h3" content={t('profile_setup_change_username')} />
                            <div style={{ marginBottom: '0.5rem' }}>
                                {t('your_current_username_is_set_to')} <strong>{currentUsername}</strong>
                            </div>
                            <SuperField
                                as="checkbox"
                                label={t('allow_me_to_change_username')}
                                checked={allowUsernameChange}
                                onChange={() => setAllowUsernameChange(!allowUsernameChange)}
                            />

                            {allowUsernameChange && (
                                <SuperField
                                    autoFocus
                                    as="input"
                                    fluid
                                    icon="user"
                                    iconPosition="left"
                                    help={
                                        <>
                                            {t('your_current_username_is_set_to')}: <strong> {currentUsername} </strong>
                                            . {t('if_you_want_to_change_it_message')}
                                        </>
                                    }
                                    label={t('new_username')}
                                    placeholder={t('set_new_username')}
                                    value={username}
                                    error={usernameError !== null ? usernameError : false}
                                    onChange={(e) => setUsername(e.target.value)}
                                />
                            )}

                            <Header as="h3" content={t('profile_setup_set_preferable_language')} />
                            <p>{t('profile_setup_hriis_interface_translation_hint')}</p>
                            <SuperField
                                as="choice"
                                inline
                                fluid
                                floating
                                direction="left"
                                customOptions={languageOptions}
                                value={language}
                                onChange={(e, { value }) => setLanguage(value)}
                            />

                            <Divider />
                            <Form.Field style={{ textAlign: 'right' }}>
                                <Button disabled={isProcessing} type="button" onClick={logoutUser}>
                                    {t('cancel')}
                                </Button>
                                <Button
                                    primary
                                    loading={isProcessing}
                                    disabled={isProcessing || oldpassword === '' || newpassword === ''}
                                >
                                    {t('save_changes')}
                                </Button>
                            </Form.Field>
                        </Form>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    )
}

export default FirstLoginPage
