import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
// store
import { routes } from '@routes'
import { API } from '@store/config'
import { requests } from '@helpers/requests'
import { useHasPermissions } from '@helpers/hooks'
// components
import Icon from '@components/Icon'
import ListView from '@components/ListView'
import Action from '@components/general/Action'
import CanView from '@components/perms/CanView'
import { Divider, Form } from 'semantic-ui-react'
import SuperField from '@components/forms/SuperField'
import SuperDuperModal from '@components/modals/SuperDuperModal'
import SuggestionField from '@components/forms/common/SuggestionField'
// module specific Components
import JobOfferPreview from './JobOfferPreview'
import PublicationList from '../views/PublicationList'

const JobsTable = () => {
    const { t } = useTranslation()
    const history = useHistory()
    const canViewHiringRoom = useHasPermissions(['candidates.c_view_recruitment'])

    return (
        <>
            <CanView permissions={['jobs.c_view_job_offers']} redirect>
                <ListView
                    as="table"
                    allowSearch
                    searchKey="title"
                    // module="jobs"
                    endpoint={API.JOB_OFFERS}
                    initialFilters={{
                        country: '',
                        city: '',
                        state: '',
                        title: '',
                        valid_for_disabled_person: '',
                        valid_for_graduate: '',
                        valid_for_student: '',
                        recruitment: '',
                    }}
                    renderFilterFields={(filters, setFilters) => (
                        <>
                            <SuperField
                                as="input"
                                label={t('title')}
                                value={filters.title}
                                onChange={(e, { value }) => setFilters((prev) => ({ ...prev, title: value }))}
                            />
                            <Form.Group widths="equal">
                                <SuggestionField
                                    label={t('city')}
                                    endpoint={API.JOBS + 'filter_city/'}
                                    text="city"
                                    search="city"
                                    value={filters.city}
                                    onSelect={(value) =>
                                        setFilters({
                                            ...filters,
                                            city: value,
                                        })
                                    }
                                />

                                <SuggestionField
                                    label={t('region')}
                                    endpoint={API.JOBS + 'filter_state/'}
                                    text="state"
                                    search="state"
                                    value={filters.state}
                                    onSelect={(value) =>
                                        setFilters({
                                            ...filters,
                                            state: value,
                                        })
                                    }
                                />
                            </Form.Group>
                            <SuperField
                                as="choice"
                                search
                                value={filters.country}
                                type="countries"
                                label={t('country')}
                                onChange={(e, { value }) =>
                                    setFilters({
                                        ...filters,
                                        country: value,
                                    })
                                }
                            />
                            <SuperField
                                as="choice"
                                search
                                value={filters.recruitment}
                                endpoint={API.HIRING_ROOM + '?query={id, name}'}
                                text="name"
                                label={t('hiring_rooms')}
                                onChange={(e, { value }) =>
                                    setFilters({
                                        ...filters,
                                        recruitment: value,
                                    })
                                }
                            />
                            <Divider />

                            <Form.Group widths="equal">
                                <SuperField
                                    as="checkbox"
                                    label={t('valid_for_disabled_person')}
                                    // width={10}
                                    checked={filters.valid_for_disabled_person}
                                    onChange={(e, data) =>
                                        setFilters({
                                            ...filters,
                                            valid_for_disabled_person: data.checked,
                                        })
                                    }
                                />
                                <SuperField
                                    as="checkbox"
                                    label={t('valid_for_graduate')}
                                    // width={10}
                                    checked={filters.valid_for_graduate}
                                    onChange={(e, data) =>
                                        setFilters({
                                            ...filters,
                                            valid_for_graduate: data.checked,
                                        })
                                    }
                                />
                                <SuperField
                                    as="checkbox"
                                    label={t('valid_for_student')}
                                    // width={10}
                                    checked={filters.valid_for_student}
                                    onChange={(e, data) =>
                                        setFilters({
                                            ...filters,
                                            valid_for_student: data.checked,
                                        })
                                    }
                                />
                            </Form.Group>
                        </>
                    )}
                    listAdditionActions={(data, setData, total, setTotal, response) => [
                        {
                            as: 'filter',
                            index: 0,
                            name: t('all'),
                            filters: {
                                is_draft: '',
                                is_published: '',
                            },
                            count: response ? response?.draft_count + response?.published_count : 0,
                        },
                        {
                            as: 'filter',
                            index: 1,
                            name: t('drafts'),
                            filters: {
                                is_draft: true,
                                is_published: '',
                            },
                            count: response?.draft_count || 0,
                        },
                        {
                            as: 'filter',
                            index: 2,
                            name: t('published'),
                            filters: {
                                is_draft: '',
                                is_published: true,
                            },
                            count: response?.published_count || 0,
                        },
                    ]}
                    actions={[
                        {
                            as: 'link',
                            type: 'add',
                            name: t('add_job'),
                            permissions: ['jobs.c_manage_job_offers'],
                            redirect: () => routes.CREATE_JOB_OFFER,
                        },
                        {
                            as: 'modal',
                            type: 'custom',
                            name: t('show_detail'),
                            icon: 'eye-outline',
                            customIconColor: 'var(--primary)',
                            permissions: ['jobs.c_view_job_offers'],
                            modalSize: 'large',
                            modal: (item) => <JobOfferPreview offer={item} />,
                        },
                        {
                            name: t('duplicate'),
                            type: 'custom',
                            as: 'confirm',
                            icon: 'copy-outline',
                            text: t('duplicate_offer_confirm'),
                            permissions: ['jobs.c_manage_job_offers'],
                            onClick: async (item) => {
                                const request = await requests.post(API.GET_JOB_OFFERS + item.id + '/duplicate/')

                                if (request.status === 201) {
                                    history.push(routes.UPDATE_JOB_OFFER + request.response.id + '/')
                                }
                            },
                        },
                        {
                            as: 'link',
                            type: 'edit',
                            name: t('edit'),
                            redirect: (item) => routes.UPDATE_JOB_OFFER + item.id,
                            permissions: ['jobs.c_manage_job_offers'],
                        },
                        {
                            name: t('delete'),
                            type: 'delete',
                            as: 'delete',
                            text: t('are_you_sure'),
                            permissions: ['jobs.c_delete_offers'],
                        },
                    ]}
                    tableHeaders={[
                        { title: '' },
                        { title: t('title'), orderBy: 'title' },
                        { title: t('workplace') },
                        { title: t('language') },
                        { title: t('open_positions') },
                        { title: t('hiring_room') },
                        { title: t('publications') },
                    ]}
                    renderCells={(offer, setData) => [
                        {
                            width: 1,
                            content: offer.is_draft && (
                                <span style={{ color: 'var(--danger)', float: 'right', marginRight: '1rem' }}>
                                    ({t('draft')})
                                </span>
                            ),
                        },
                        {
                            width: 3,
                            content: (
                                <SuperDuperModal
                                    size="large"
                                    trigger={<span className="ref-link">{offer.title}</span>}
                                    content={<JobOfferPreview offer={offer} />}
                                />
                            ),
                        },
                        {
                            content: (
                                <span>
                                    {offer?.workplace?.address?.city && offer?.workplace?.address?.country_display ? (
                                        <>
                                            <Icon name="location-outline" style={{ marginRight: '0.5rem' }} />
                                            <span style={{ position: 'relative', top: '-0.2rem' }}>
                                                {offer?.workplace?.address?.city},{' '}
                                                {offer?.workplace?.address?.country_display}
                                            </span>
                                        </>
                                    ) : (
                                        '--'
                                    )}
                                </span>
                            ),
                        },
                        { content: offer.in_language_display },
                        {
                            content: (
                                <>
                                    <Icon name="people-outline" style={{ marginRight: '0.5rem' }} />
                                    <span style={{ position: 'relative', top: '-0.2rem' }}>
                                        {offer.number_of_openings}
                                    </span>
                                </>
                            ),
                        },
                        {
                            content: (
                                <>
                                    {offer.recruitment && canViewHiringRoom ? (
                                        <Link to={routes.HIRING_ROOMS + offer?.recruitment?.id}>
                                            {offer?.recruitment?.name}
                                        </Link>
                                    ) : (
                                        <>{offer?.recruitment?.id && <>{offer?.recruitment?.name} </>}</>
                                    )}
                                </>
                            ),
                        },
                        {
                            content: (
                                <>
                                    <Icon name="pricetags-outline" style={{ marginRight: '0.5rem' }} />
                                    <span style={{ position: 'relative', top: '-0.1rem' }}>
                                        {offer?.publication_count > 0 ? offer?.publication_count : 0}
                                    </span>

                                    <Action
                                        as="modal"
                                        type="icon"
                                        iconColor="var(--primary)"
                                        paddingLeft="1rem"
                                        icon="eye-outline"
                                        size="large"
                                        modalSize="large"
                                        tooltip={t('show_publications')}
                                        modal={<PublicationList offer={offer} />}
                                    />
                                </>
                            ),
                        },
                    ]}
                />
            </CanView>
        </>
    )
}

export default JobsTable
