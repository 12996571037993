import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router'
import { Switch, Route, Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
// store
import { icons } from '@icons'
import { routes } from '@routes'
import { API } from '@store/config'
import { requests } from '@helpers/requests'
// components
import { Container, Grid, Card, Divider } from 'semantic-ui-react'
import CanView from '@components/perms/CanView'
import BreadcrumbNav from '@components/BreadcrumbNav'
// module specific
import AssignedItemsView from './views/AssignedItemsView'
import AssetsManagementView from './views/AssetsManagementView'
import IssuedReturnedCardsView from './views/IssuedReturnedCardsView'
import AssetTransfers from './views/AssetTransfers'
import AssetsHistoryView from './views/AssetsHistoryView'

const Assets = ({ location }) => {
    const { t } = useTranslation()

    const [employees, setEmployees] = useState([])

    const employeeOptions = employees.map((item) => ({ key: item.id, value: item.id, text: item.fullname_with_titles }))

    useEffect(() => {
        async function fetchEmployees() {
            const request = await requests.get(API.EMPLOYEES + '?only_basic_info=true')

            if (request.status === 200) {
                setEmployees(request.response)
            }
        }

        fetchEmployees()
    }, [])

    const NavigateTo = ({ route, text, isActive, ...rest }) => {
        return (
            <Grid.Column
                as={Link}
                to={route}
                {...rest}
                className="toggle-button"
                style={{
                    textAlign: 'center',
                }}
            >
                <Card
                    className="active"
                    fluid
                    style={{ borderRadius: 0, background: isActive ? 'var(--white)' : 'none' }}
                >
                    <Card.Content as="h2">{text}</Card.Content>
                </Card>
            </Grid.Column>
        )
    }

    return (
        <CanView
            permissions={['assets.c_view_items', 'assets.c_view_warehouses', 'assets.c_view_item_categories']}
            redirect
        >
            <BreadcrumbNav style={{ marginBottom: 0 }} items={[{ name: t('assets'), icon: icons.ASSETS, href: '' }]} />
            <Container fluid>
                <Grid>
                    <Grid.Row
                        columns={5}
                        className="custom-filter-button-group"
                        style={{ paddingTop: 0, paddingBottom: 0 }}
                    >
                        <NavigateTo
                            isActive={routes.ASSETS === location.pathname}
                            route={routes.ASSETS}
                            text={t('manage_assets')}
                        />
                        <NavigateTo
                            isActive={routes.ASSETS + 'cards/' === location.pathname}
                            route={routes.ASSETS + 'cards/'}
                            text={t('return_issue_cards')}
                        />
                        <NavigateTo
                            isActive={routes.ASSETS + 'assigned/' === location.pathname}
                            route={routes.ASSETS + 'assigned/'}
                            text={t('assigned_assets')}
                        />
                        <NavigateTo
                            isActive={routes.ASSETS + 'transfers/' === location.pathname}
                            route={routes.ASSETS + 'transfers/'}
                            text={t('transfers')}
                        />
                        <NavigateTo
                            isActive={routes.ASSETS + 'history/' === location.pathname}
                            route={routes.ASSETS + 'history/'}
                            text={t('history')}
                        />
                    </Grid.Row>
                </Grid>
                <Divider />

                <Switch>
                    <Route exact path={routes.ASSETS}>
                        <AssetsManagementView employees={employeeOptions} />
                    </Route>
                    <Route path={routes.ASSETS + 'cards/'}>
                        <IssuedReturnedCardsView employees={employeeOptions} />
                    </Route>
                    <Route path={routes.ASSETS + 'assigned/'}>
                        <AssignedItemsView employees={employeeOptions} />
                    </Route>
                    <Route path={routes.ASSETS + 'transfers/'}>
                        <AssetTransfers employees={employeeOptions} />
                    </Route>
                    <Route path={routes.ASSETS + 'history/'}>
                        <AssetsHistoryView employees={employeeOptions} />
                    </Route>
                </Switch>
            </Container>
        </CanView>
    )
}

export default withRouter(Assets)
