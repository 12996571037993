import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-semantic-toasts'
import { useTranslation } from 'react-i18next'
import { useParams, useHistory, useLocation } from 'react-router-dom'
// store
import { icons } from '@icons'
import { routes } from '@routes'
import { API } from '@store/config'
import { tzDateTime } from '@helpers/dates'
import { requests } from '@helpers/requests'
import { useHasPermissions } from '@helpers/hooks'
// components
import Icon from '@components/Icon'
import AvatarList from '@components/AvatarList'
import CanView from '@components/perms/CanView'
import AvatarIcon from '@components/AvatarIcon'
import FilesTab from '@components/documents/FilesTab'
import BreadcrumbNav from '@components/BreadcrumbNav'
import LabelsList from '@components/lists/LabelsList'
import SpinnerSegment from '@components/SpinnerSegment'
import ConfirmModal from '@components/modals/ConfrimModal'
import SuperDuperModal from '@components/modals/SuperDuperModal'
import BusinessDetailView from '@components/common/business-details/BusinessDetailView'
import { Container, Tab, Divider, Segment, Grid, Header, Label, List, Popup } from 'semantic-ui-react'
// specific components
import QuickNotes from './components/QuickNotes'
import AccountForm from './components/AccountForm'
import Branches from '../suppliers/components/Branches'

const AccountDetail = ({ isSupplier }) => {
    const { t } = useTranslation()
    const params = useParams()
    const history = useHistory()
    const location = useLocation()
    const dateFormat = useSelector((state) => state.date_format)

    const canManage = useHasPermissions([
        'accounts.c_manage_all_accounts',
        'accounts.c_manage_user_accounts',
        'accounts.c_manage_assigned_accounts',
    ])

    const [focusedPopup, setFocusedPopup] = useState(0)
    const [openPopup, setOpenPopup] = useState(0)

    const [account, setAccount] = useState(null)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        async function fetchAccountDetails() {
            setIsLoading(true)
            const request = await requests.get(API.ACCOUNTS + 'business_details/' + params.id + '/')
            if ([404, 403].includes(request.status)) history.push(isSupplier ? routes.SUPPLIERS : routes.ACCOUNTS)
            if (request.status === 200) {
                if (!request.response.is_supplier && location.pathname.includes('supplier')) {
                    history.push(routes.SUPPLIERS)
                } else if (!request.response.is_account && location.pathname.includes('accounts')) {
                    history.push(routes.ACCOUNTS)
                } else {
                    setAccount(request.response)
                }
            }
            setIsLoading(false)
        }

        fetchAccountDetails()
        // eslint-disable-next-line
    }, [params.id])

    const onStateChange = async (state, account, setData) => {
        if (account.is_active !== state) {
            const request = await requests.patch(API.ACCOUNTS + 'business_details/' + account.id + '/', {
                is_active: state,
            })
            if (request.status === 200) {
                setData(request.response)
            }
        }
    }

    const uploadLogo = async (image) => {
        const formData = new FormData()
        formData.append('logo', image)
        return await requests.post(API.ACCOUNTS + `business_details/${account?.id}/logo/`, formData)
    }

    const deleteLogo = async () => {
        return await requests.del(API.ACCOUNTS + `business_details/${account?.id}/logo/`)
    }

    const getAccountType = (account) => {
        if (!account.is_non_business_client && !account.is_freelancer && !account.is_account_employer)
            return t('business_person')
        if (account.is_non_business_client && !account.is_freelancer && !account.is_account_employer)
            return t('individual_person')
        if (!account.is_non_business_client && account.is_freelancer && !account.is_account_employer)
            return t('self_employed_singular')
        if (!account.is_non_business_client && !account.is_freelancer && account.is_account_employer)
            return t('account_employer')

        return '--'
    }

    const breadcrumb_items = [
        {
            name: isSupplier ? t('suppliers') : t('accounts'),
            icon: icons.ACCOUNTS,
            href: isSupplier ? routes.SUPPLIERS : routes.ACCOUNTS,
        },
        { name: account?.name || '--', icon: icons.ACCOUNTS },
    ]

    return (
        <CanView permissions={['accounts.c_view_all_accounts', 'accounts.c_view_assigned_accounts']} redirect>
            <SpinnerSegment
                loading={isLoading}
                loadingMessage={isSupplier ? t('loading_supplier_details') : t('loading_account_details')}
            >
                {!isLoading && (
                    <>
                        <BreadcrumbNav items={breadcrumb_items} />
                        <Container fluid>
                            <Segment className="rounded-edges">
                                <Grid stackable>
                                    <Grid.Row columns={2} verticalAlign="top">
                                        <Grid.Column width="3" centered>
                                            <AvatarIcon
                                                size={150}
                                                active={account.is_active}
                                                name={account.name || '--'}
                                                src={account.logo || null}
                                                handleUpload={uploadLogo}
                                                handleDelete={deleteLogo}
                                                uploadable={canManage}
                                            />
                                        </Grid.Column>
                                        <Grid.Column
                                            width="13"
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            <div>
                                                <Header as="h2" content={account.name} style={{ margin: 0 }} />
                                                <div style={{ opacity: 0.7 }}>
                                                    {!account.is_non_business_client && account.tax_mode && (
                                                        <>
                                                            <strong>{account.tax_mode_display}</strong> {' · '}
                                                        </>
                                                    )}
                                                    {getAccountType(account)}
                                                </div>
                                                <div style={{ marginTop: '1rem' }}>
                                                    {account?.identification_number ? (
                                                        <>
                                                            {t('id_number')}:{' '}
                                                            <strong>
                                                                {account?.identification_number}
                                                                <br />
                                                            </strong>
                                                        </>
                                                    ) : (
                                                        ''
                                                    )}
                                                    {account?.dit_number ? (
                                                        <>
                                                            {t('dit_number')}:{' '}
                                                            <strong>
                                                                {account?.dit_number}
                                                                <br />
                                                            </strong>
                                                        </>
                                                    ) : (
                                                        ''
                                                    )}
                                                    {account?.vat_number ? (
                                                        <>
                                                            {t('vat_number')}:{' '}
                                                            <strong>
                                                                {account?.vat_number}
                                                                <br />
                                                            </strong>
                                                        </>
                                                    ) : (
                                                        ''
                                                    )}
                                                </div>

                                                <div style={{ marginTop: '1rem' }}>
                                                    <LabelsList
                                                        rowID={account.id}
                                                        label_key={'tags'}
                                                        labels={account.tags}
                                                        endpointLabels={API.ACCOUNTS + 'labels/'}
                                                        endpoint={API.ACCOUNTS + 'business_details/'}
                                                    />
                                                </div>
                                                <div
                                                    style={{
                                                        textAlign: 'left',
                                                        opacity: 0.6,
                                                        fontSize: '0.9rem',
                                                        marginTop: '1rem',
                                                    }}
                                                >
                                                    {t('created')}{' '}
                                                    <strong>
                                                        {tzDateTime(account.created_on).format(dateFormat + ' HH:MM')}
                                                    </strong>
                                                    {' · '}
                                                    {t('created_by')}{' '}
                                                    <strong>{account.created_by?.name || '--'}</strong>
                                                </div>
                                            </div>
                                            <div>
                                                <div
                                                    style={{
                                                        marginBottom: '0.5rem',
                                                        textAlign: 'right',
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                        justifyContent: 'flex-end',
                                                    }}
                                                >
                                                    {canManage ? (
                                                        <>
                                                            <Popup
                                                                hoverable
                                                                position="bottom left"
                                                                open={
                                                                    openPopup === account.id ||
                                                                    focusedPopup === account.id
                                                                }
                                                                onOpen={() => setOpenPopup(account.id)}
                                                                onClose={() => setOpenPopup(0)}
                                                                trigger={
                                                                    <Label
                                                                        size="small"
                                                                        color={account.is_active ? 'green' : 'red'}
                                                                        style={{
                                                                            marginRight: '1rem',
                                                                            position: 'relative',
                                                                            top: '-0.2rem',
                                                                        }}
                                                                    >
                                                                        {account.is_active
                                                                            ? t('active_him')
                                                                            : t('inactive_him')}
                                                                        <Icon
                                                                            name="chevron-down-outline"
                                                                            style={{
                                                                                marginLeft: '0.5rem',
                                                                                position: 'relative',
                                                                                top: '0.1rem',
                                                                            }}
                                                                        />
                                                                    </Label>
                                                                }
                                                                content={
                                                                    <div>
                                                                        <strong>{t('change_state')}:</strong>
                                                                        <List
                                                                            divided
                                                                            selection
                                                                            style={{ width: '250px' }}
                                                                        >
                                                                            <List.Item
                                                                                active={account.is_active === true}
                                                                                onClick={() =>
                                                                                    onStateChange(
                                                                                        true,
                                                                                        account,
                                                                                        setAccount
                                                                                    )
                                                                                }
                                                                            >
                                                                                <Icon
                                                                                    name="ellipse"
                                                                                    style={{
                                                                                        marginRight: '0.5rem',
                                                                                        color: 'green',
                                                                                    }}
                                                                                />
                                                                                <span
                                                                                    style={{
                                                                                        position: 'relative',
                                                                                        top: '-0.10rem',
                                                                                    }}
                                                                                >
                                                                                    {t('active_him')}
                                                                                </span>
                                                                            </List.Item>
                                                                            <ConfirmModal
                                                                                description={t('set_to_inactive')}
                                                                                button={
                                                                                    <List.Item
                                                                                        disabled={
                                                                                            account.is_active === false
                                                                                        }
                                                                                        active={
                                                                                            account.is_active === false
                                                                                        }
                                                                                        onClick={() =>
                                                                                            setFocusedPopup(account.id)
                                                                                        }
                                                                                    >
                                                                                        <Icon
                                                                                            name="ellipse"
                                                                                            style={{
                                                                                                marginRight: '0.5rem',
                                                                                                color: 'red',
                                                                                            }}
                                                                                        />
                                                                                        <span
                                                                                            style={{
                                                                                                position: 'relative',
                                                                                                top: '-0.10rem',
                                                                                            }}
                                                                                        >
                                                                                            {t('inactive_him')}
                                                                                        </span>
                                                                                    </List.Item>
                                                                                }
                                                                                onConfirm={async () => {
                                                                                    const request =
                                                                                        await requests.patch(
                                                                                            API.ACCOUNTS +
                                                                                                'business_details/' +
                                                                                                account.id +
                                                                                                '/',
                                                                                            {
                                                                                                is_active: false,
                                                                                            }
                                                                                        )

                                                                                    if (request.status === 200) {
                                                                                        toast({
                                                                                            type: 'success',
                                                                                            icon: 'check circle',
                                                                                            title: t('set_as_inactive'),
                                                                                            animation: 'pulse',
                                                                                            time: 2000,
                                                                                        })

                                                                                        setAccount(request.response)
                                                                                        setFocusedPopup(0)
                                                                                    }
                                                                                }}
                                                                                onRefuse={() => setFocusedPopup(0)}
                                                                            />
                                                                        </List>
                                                                    </div>
                                                                }
                                                            />
                                                        </>
                                                    ) : (
                                                        <Label
                                                            size="small"
                                                            color={account.is_active ? 'green' : 'red'}
                                                            style={{
                                                                marginRight: '1rem',
                                                                position: 'relative',
                                                                top: '-0.2rem',
                                                            }}
                                                        >
                                                            {account.is_active ? t('active_him') : t('inactive_him')}
                                                        </Label>
                                                    )}

                                                    <div style={{ marginRight: '0.5rem' }}>
                                                        <QuickNotes isDirect record={account} setData={setAccount} />
                                                    </div>
                                                    <div>
                                                        <CanView
                                                            permissions={[
                                                                'accounts.c_manage_all_accounts',
                                                                'accounts.c_manage_user_accounts',
                                                                'accounts.c_manage_assigned_accounts',
                                                            ]}
                                                        >
                                                            <SuperDuperModal
                                                                size="small"
                                                                header={t('update')}
                                                                trigger={
                                                                    <Icon
                                                                        name="pencil"
                                                                        style={{
                                                                            marginLeft: '1rem',
                                                                            cursor: 'pointer',
                                                                        }}
                                                                    />
                                                                }
                                                                content={
                                                                    <AccountForm
                                                                        isDirect={true}
                                                                        record={account}
                                                                        setData={setAccount}
                                                                        isSupplier={account.is_supplier}
                                                                    />
                                                                }
                                                            />
                                                        </CanView>
                                                    </div>
                                                </div>

                                                <div style={{ marginTop: '1rem' }}>
                                                    <strong>{t('unit')}:</strong>
                                                    <div style={{ marginTop: '0.5rem' }}>
                                                        {account.unit?.name || (
                                                            <span style={{ opacity: 0.8 }}>{t('unclassified')}</span>
                                                        )}
                                                    </div>
                                                </div>

                                                <div style={{ marginTop: '1rem' }}>
                                                    <strong>{t('responsible_persons')}:</strong>
                                                    <div style={{ marginTop: '0.5rem' }}>
                                                        {account.assigned_to.length > 0 ? (
                                                            <AvatarList
                                                                size="25"
                                                                alignDirection="start"
                                                                persons={account.assigned_to.map((item) => ({
                                                                    id: item.id,
                                                                    name: item.fullname,
                                                                    avatar: item.profile_picture,
                                                                    displayName:
                                                                        item?.fullname_with_titles || item.fullname,
                                                                }))}
                                                            />
                                                        ) : (
                                                            <span style={{ opacity: 0.8 }}>
                                                                {' '}
                                                                {t('no_responsible_person')}{' '}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                                <div style={{ marginTop: '1rem' }}>
                                                    <strong>{t('website')}:</strong>
                                                    <div style={{ marginTop: '0.5rem' }}>
                                                        {account.website ? (
                                                            <a href={account.website}>{account.website}</a>
                                                        ) : (
                                                            '--'
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Segment>

                            <Divider />
                            <Tab
                                panes={[
                                    {
                                        menuItem: t('home'),
                                        render: () => (
                                            <Tab.Pane>
                                                <Grid>
                                                    <Grid.Row>
                                                        <Grid.Column>
                                                            <Header as="h3" content={t('description')} />
                                                            <p>{account?.description || '--'}</p>
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                    <Grid.Row columns="2" divided>
                                                        <Grid.Column>
                                                            <Header as="h3" content={t('business_detail')} />
                                                            <BusinessDetailView
                                                                isDirect={true}
                                                                updateState={setAccount}
                                                                permissions={{
                                                                    view: 'accounts.c_view_all_account_bank_accounts',
                                                                    manage: 'accounts.c_manage_all_account_bank_accounts',
                                                                    delete: 'accounts.c_manage_all_account_bank_accounts',
                                                                }}
                                                                data={account}
                                                                endpoint={
                                                                    API.ACCOUNTS +
                                                                    'business_details/' +
                                                                    account.id +
                                                                    '/'
                                                                }
                                                            />
                                                        </Grid.Column>
                                                        <Grid.Column>
                                                            <Header as="h3" content={t('contacts')} />
                                                            <div>
                                                                {account.contacts.length === 0 && (
                                                                    <span style={{ opacity: 0.8 }}>{t('no_data')}</span>
                                                                )}
                                                                {account.contacts.map((contact, idx) => (
                                                                    <div key={idx}>
                                                                        <div
                                                                            style={{
                                                                                display: 'flex',
                                                                                flexDirection: 'row',
                                                                                itemsAlign: 'start',
                                                                                justifyContent: 'space-between',
                                                                            }}
                                                                        >
                                                                            <div>
                                                                                {contact.contact_person_name !== '' && (
                                                                                    <strong>
                                                                                        {contact.contact_person_name}{' '}
                                                                                        <br />
                                                                                    </strong>
                                                                                )}
                                                                                {contact.phone !== '' ? (
                                                                                    <>
                                                                                        {contact.phone} <br />
                                                                                    </>
                                                                                ) : (
                                                                                    ''
                                                                                )}
                                                                                {contact?.email !== '' ? (
                                                                                    <>{contact?.email} </>
                                                                                ) : (
                                                                                    ''
                                                                                )}
                                                                                {contact?.is_invoicing && (
                                                                                    <div
                                                                                        style={{
                                                                                            opacity: 0.8,
                                                                                            fontSize: '0.9rem',
                                                                                            fontWeight: 'bold',
                                                                                            marginTop: '0.2rem',
                                                                                        }}
                                                                                    >
                                                                                        {t('used_for_invoicing')}
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                            <div style={{ textAlign: 'right' }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontWeight: 'bold',
                                                                                        opacity: 0.5,
                                                                                        marginRight: '1rem',
                                                                                        position: 'relative',
                                                                                        top: '-0.1rem',
                                                                                    }}
                                                                                >
                                                                                    #{idx + 1}.
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <Divider />
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                </Grid>
                                            </Tab.Pane>
                                        ),
                                    },
                                    {
                                        menuItem: t('documents'),
                                        render: () => (
                                            <Tab.Pane>
                                                <FilesTab
                                                    id={account.id}
                                                    module={isSupplier ? 'supplier' : 'account'}
                                                    getEndpoint={API.ACCOUNTS + 'business_details/'}
                                                    updateEndpoint={API.ACCOUNTS + 'business_details/'}
                                                />
                                            </Tab.Pane>
                                        ),
                                    },
                                    {
                                        menuItem: t('branches'),
                                        render: () => (
                                            <Tab.Pane>
                                                <Branches account={account.id} />
                                            </Tab.Pane>
                                        ),
                                    },
                                ]}
                            />
                        </Container>
                    </>
                )}
            </SpinnerSegment>
        </CanView>
    )
}

export default AccountDetail
