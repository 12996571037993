import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { toast } from 'react-semantic-toasts'
// store
import { API } from '@store/config'
import { tzDateTime } from '@helpers/dates'
import { requests } from '@helpers/requests'
// components
import ListView from '@components/ListView'
import CanView from '@components/perms/CanView'
import ConfrimModal from '@components/modals/ConfrimModal'
import { Divider, Button, Header, Label } from 'semantic-ui-react'

const ProcessOverview = ({ process, setData, onClose }) => {
    const { t } = useTranslation()

    const onCloseProcess = async () => {
        const request = await requests.patch(API.ASSETS + 'warehouse_processes/' + process.id + '/', {
            is_active: false,
        })
        if (request.status === 200) {
            toast({
                type: 'success',
                icon: 'check circle',
                title: t('process_has_been_closed'),
                animation: 'pulse',
                time: 2000,
            })
            setData((prev) =>
                prev.map((item) => {
                    if (item.id === process.id) {
                        item = request.response
                    }
                    return item
                })
            )
            onClose()
        }
    }

    const onReOpenProcess = async () => {
        const request = await requests.patch(API.ASSETS + 'warehouse_processes/' + process.id + '/', {
            is_active: true,
        })
        if (request.status === 200) {
            toast({
                type: 'success',
                icon: 'check circle',
                title: t('process_has_been_reopened'),
                animation: 'pulse',
                time: 2000,
            })
            setData((prev) =>
                prev.map((item) => {
                    if (item.id === process.id) {
                        item = request.response
                    }
                    return item
                })
            )
            onClose()
        }
    }

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Header as="h3" style={{ marginBottom: '0.2rem' }}>
                    {t('process_name')}: <span style={{ fontWeight: 'normal' }}> {process.title} </span>
                </Header>

                <CanView permissions={['assets.c_manage_warehouse_processes']}>
                    {process.is_active ? (
                        <ConfrimModal
                            description={t('close_process_delete')}
                            onConfirm={() => onCloseProcess()}
                            button={
                                <Button size="tiny" style={{ background: 'var(--danger)', color: 'var(--white)' }}>
                                    {t('close_process')}
                                </Button>
                            }
                        />
                    ) : (
                        <ConfrimModal
                            description={t('reopen_process_message')}
                            onConfirm={() => onReOpenProcess()}
                            button={
                                <Button size="tiny" style={{ background: 'var(--primary)', color: 'var(--white)' }}>
                                    {t('reopen_process')}
                                </Button>
                            }
                        />
                    )}
                </CanView>
            </div>
            <p> {process.note} </p>

            <Divider />
            <ProcessHistory process={process} />
        </>
    )
}

const ProcessHistory = ({ process }) => {
    const { t } = useTranslation()
    const dateFormat = useSelector((state) => state.date_format)

    return (
        <ListView
            as="table"
            isModal
            forceReloadOnPagination={false}
            endpoint={API.ASSETS + 'history/'}
            tableHeaders={[
                { title: t('operation') },
                { title: t('warehouse_position') },
                { title: t('item') },
                { title: t('quantity') },
                { title: t('performed_by') },
                { title: t('datetime') },
            ]}
            initialFilters={{
                warehouse_process: process.id,
                query: '{id, operation, operation_display, quantity, warehouse_position, stored_item{id, code, catalogue_item}, performed_by, created_on}',
            }}
            renderCells={(item) => [
                {
                    content: (
                        <Label size="small" color={item.operation === 6 ? 'orange' : 'info'}>
                            {item.operation_display}
                        </Label>
                    ),
                },
                {
                    content: item.warehouse_position
                        ? item.warehouse_position.title +
                          (item.warehouse_position.section && ' / ' + item.warehouse_position.section) +
                          (item.warehouse_position.row && ' / ' + item.warehouse_position.row)
                        : '--',
                },
                {
                    content: (
                        <>
                            {' '}
                            {item.stored_item?.code
                                ? item.stored_item?.code + ' - '
                                : item.stored_item?.catalogue_item?.code
                                ? item.stored_item?.catalogue_item?.code + ' - '
                                : ''}
                            {item.stored_item.catalogue_item.title}
                        </>
                    ),
                },
                { content: item.quantity },
                { content: item.performed_by?.fullname },
                { content: tzDateTime(item.created_on).format(dateFormat + ' HH:mm') },
            ]}
        />
    )
}

export default ProcessOverview
