import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
// store
import { API } from '@store/config'
import { tzDateTime } from '@helpers/dates'
// import { requests } from '@helpers/requests';
// components
import { Header, Container, Divider } from 'semantic-ui-react'
import ListView from '@components/ListView'

const StoredItemPositionHistory = ({ record }) => {
    const { t } = useTranslation()
    const dateFormat = useSelector((state) => state.date_format)

    return (
        <>
            <Container fluid>
                <Header
                    as="h3"
                    content={
                        <>
                            {t('stored_item_history') + ': '}
                            <span style={{ fontWeight: 'normal' }}>
                                {record.stock_number} •{' '}
                                {(record?.stored_item?.catalogue_item?.code
                                    ? record?.stored_item?.catalogue_item.code + ' - '
                                    : '') +
                                    record?.stored_item?.catalogue_item.title +
                                    ' (' +
                                    record.stored_item?.warehouse?.title +
                                    ')'}
                            </span>
                        </>
                    }
                />
                <Divider />
            </Container>
            <ListView
                as="table"
                allowSearch
                endpoint={API.ASSETS + 'history/'}
                initialFilters={{
                    stored_item: record.stored_item.id,
                    stock_number: record.stock_number,
                    warehouse_position: record.warehouse_position.id,
                    operation: [1, 5, 6],
                    ordering: '-created_on',
                }}
                listAdditionActions={() => [
                    {
                        as: 'filter',
                        index: 0,
                        name: t('all'),
                        filters: {
                            operation: [1, 5, 6],
                        },
                    },
                    {
                        as: 'filter',
                        index: 1,
                        name: t('stock_loading'),
                        filters: {
                            operation: [5],
                        },
                    },
                    {
                        as: 'filter',
                        index: 2,
                        name: t('stock_unloading'),
                        filters: {
                            operation: [6],
                        },
                    },
                    {
                        as: 'filter',
                        index: 3,
                        name: t('correction'),
                        filters: {
                            operation: [1],
                        },
                    },
                ]}
                tableHeaders={[
                    { title: t('operation'), orderBy: 'operation' },
                    { title: t('stock'), orderBy: 'quantity' },
                    { title: t('stock_number'), orderBy: 'stock_number' },
                    { title: t('warehouse_position'), orderBy: 'warehouse_position__title' },
                    { title: t('performed_by'), orderBy: 'performed_by__fullname' },
                    { title: t('created_on'), orderBy: 'created_on' },
                    { title: t('note') },
                ]}
                renderCells={(history) => [
                    {
                        content: (
                            <>
                                {history.operation === 5 && t('stock_loading')}
                                {history.operation === 6 && t('stock_unloading')}
                                {history.operation === 1 && t('correction')}
                            </>
                        ),
                    },
                    {
                        content: (
                            <>
                                {parseFloat(history.quantity)
                                    ? parseFloat(history.quantity).toFixed(3).replace('.000', '')
                                    : 0}
                            </>
                        ),
                    },
                    { content: history.stock_number || '--' },
                    {
                        content: history.warehouse_position
                            ? history.warehouse_position.title +
                              (history.warehouse_position.section && ' / ' + history.warehouse_position.section) +
                              (history.warehouse_position.row && ' / ' + history.warehouse_position.row)
                            : '--',
                    },
                    { content: history.performed_by?.fullname || '--' },
                    { content: tzDateTime(history.created_on).format(dateFormat + ' HH:mm') },
                    { content: history.reason || history.note },
                ]}
            />
        </>
    )
}

export default StoredItemPositionHistory
