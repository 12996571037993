import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment from 'moment';
import '@store/languages';
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
import { thousandsSeparators } from '@helpers/functions';
// components
import {
    PieChart,
    Pie,
    Sector, 
    ComposedChart,
    Bar,
    Cell,
    XAxis,
    YAxis,
    Line,
    // CartesianGrid,
    ReferenceLine,
    Tooltip,
    Legend,
    ResponsiveContainer
} from "recharts";
import { Segment, Grid } from 'semantic-ui-react';

const MonthlyCCStats = ({ isDetail, filters, listData }) => {
    const { t } = useTranslation()

    const [loading, setLoading] = useState(false)
    const [response, setResponse] = useState(null)
    const [data, setData] = useState([])
    const [pieData, setPieData] = useState([])
    const [activeIndex, setActiveIndex] = useState(0)
    const [activeBar, setActiveBar] = useState(null)
    const colors = useSelector(state => state.global_pref.colors)
    const language = useSelector(state => state.language)

    const year = filters?.year || parseInt(moment().format('YYYY'))
    const monthStart = filters?.monthStart || 1
    const monthEnd = filters?.monthEnd || 12
    const currency = filters?.currency || "EUR"

    const renderActiveShape = (props) => {
        const RADIAN = Math.PI / 180
        const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props
        const sin = Math.sin(-RADIAN * midAngle)
        const cos = Math.cos(-RADIAN * midAngle)
        const sx = cx + (outerRadius + 10) * cos
        const sy = cy + (outerRadius + 10) * sin
        const mx = cx + (outerRadius + 30) * cos
        const my = cy + (outerRadius + 30) * sin
        const ex = mx + (cos >= 0 ? 1 : -1) * 22
        const ey = my
        const textAnchor = cos >= 0 ? 'start' : 'end'
    
        return (
            <g>
            <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
                {payload.name}
            </text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
            />
            <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${thousandsSeparators(value || 0)} ${filters.currency}`}</text>
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
                {`(${t("rate_value")} ${(percent * 100).toFixed(2)}%)`}
            </text>
            </g>
        );
    }

    useEffect(() => {
        async function fetchData(){
            setActiveBar(null)
            setLoading(true)
            let graphData = []
            let pieDataArray = [
                { id: "income", name: t('incomes'), value: 0, fill: "var(--success)" },
                { id: "expense", name: t('expenses'), value: 0, fill: "var(--danger)" },
            ]
            // fetch data from endpoint for year overview
            let extraParams = ""
            if (filters.cost_center !== "") extraParams += `&cost_center=${filters.cost_center}`
            if (filters.cost_centers !== "") extraParams += `&cost_centers=${filters.cost_centers}`
            if (filters?.[isDetail ? "tags" : "cost_center_tags"]?.length > 0 ) {
                for (let i = 0; i < filters?.[isDetail ? "tags" : "cost_center_tags"].length; i++) {
                    extraParams += `&${isDetail ? "tags" : "cost_center_tags"}=${filters?.[isDetail ? "tags" : "cost_center_tags"][i]}`
                }
            }
            if (filters.unit !== "") extraParams += `&unit=${filters.unit}`
            if (filters.date_from !== "" && filters.date_to !== "") {
                extraParams += `&date_after=${filters.date_from}`
                extraParams += `&date_before=${filters.date_to}`
            }
            const request = await requests.get(API.REPORTS + `cost_centers/basic_monthly_ratios/?year=${year}&month_start=${monthStart}&month_end=${monthEnd}&currency=${currency}${extraParams}`)
            if (request.status === 200) {
                setResponse(request.response)
                const selectedYear = request.response.year_data
                // eslint-disable-next-line
                const comparableYear = request.response.comparable_year_data

                for (let i = 0; i < selectedYear.length; i++) {
                    let expenses = selectedYear[i].total_converted_expenses > 0 ? (selectedYear[i].total_converted_expenses * -1) : selectedYear[i].total_converted_expenses
                    graphData.push({
                        name: selectedYear[i].month,
                        incomes: selectedYear[i].total_converted_incomes,
                        expenses: selectedYear[i].total_converted_expenses,
                        total: parseFloat(parseFloat(selectedYear[i].total_converted_incomes + expenses).toFixed(2)),
                        previousYear: 0,
                    })

                    pieDataArray = pieDataArray.filter(item => {
                        if (item.id === "income"){
                            item.value = parseFloat(parseFloat(item.value + selectedYear[i].total_converted_incomes).toFixed(2))
                        }
                        if (item.id === "expense"){
                            item.value = parseFloat(parseFloat(item.value + selectedYear[i].total_converted_expenses).toFixed(2))
                        }

                        return item
                    })
                }

                // for (let i = 0; i < comparableYear.length; i++) {
                //     let month = graphData.find(item => item.name === comparableYear[i].month)
                //     let previousYear = 0
                    
                //     if (month) {
                //         let comparableIncomes = comparableYear[i].total_converted_incomes
                //         let comparableExpenses = comparableYear[i].total_converted_expenses
                //         let expenses = comparableExpenses > 0 ? (comparableExpenses * -1) : comparableExpenses
                //         let comparableTotal = comparableIncomes + expenses

                //         if (comparableTotal > 0) {
                //             let incomes = month.total_converted_incomes
                //             let expenses = month.total_converted_expenses
                //             let total = incomes + expenses

                //             let difference = comparableTotal - total
                //             if (difference > 0) previousYear = difference
                //         }

                //         graphData.filter(item => {
                //             if (item.name === comparableYear[i].month){
                //                 item.previousYear = previousYear
                //             }

                //             return item
                //         })
                //     }
                // }

                setData(graphData)
                setPieData(pieDataArray)
            }
            setLoading(false)
        }
        
        // if (!isMount){
            fetchData()
        //}
        // eslint-disable-next-line
    }, [listData])

    const onPieEnter = (_, index) => {
        setActiveIndex(index)
    }

    const onBarClick = (_, index) => {
        if (_?.activeLabel === undefined) return;
        setActiveBar(_?.activeLabel)
        // calculate income/expenses for selected month
        const selectedMonth = data.find(item => item.name === _?.activeLabel)
        if (selectedMonth) {
            setPieData(pieData.filter(item => {
                if (item.id === "income"){
                    item.value = selectedMonth.incomes
                }
                if (item.id === "expense"){
                    item.value = selectedMonth.expenses
                }

                return item
            }))
        }
    }

    const clearSelection = () => {
        if ( response === null ) return;

        let pieDataArray = [
            { id: "income", name: t('incomes'), value: 0, fill: "var(--success)" },
            { id: "expense", name: t('expenses'), value: 0, fill: "var(--danger)" },
        ]
        let selectedYear = response.year_data
        for (let i = 0; i < selectedYear.length; i++) {
            pieDataArray = pieDataArray.filter(item => {
                if (item.id === "income"){
                    item.value = item.value + selectedYear[i].total_converted_incomes
                }
                if (item.id === "expense"){
                    item.value = item.value + selectedYear[i].total_converted_expenses
                }

                return item
            })
        }

        setActiveBar(null)
        setPieData(pieDataArray)
    }

    const CustomTooltip = ({ active, payload, label }) => {
        const { t } = useTranslation()
        if (active && payload && payload.length) {
            return (
                <div style={{ padding: "0.5rem", background: "var(--white)", border: "1px solid var(--light)" }}>
                    <p>{`${t('difference')}: ${thousandsSeparators(payload[0].value)} ${filters.currency || "EUR"}`}</p>
                    
                </div>
            );
        }
      
        return null
    }

    return (
        <Segment 
            loading={loading}
            style={{ 
                padding: 0, 
                marginTop: 0,
                background: "transparent", 
                boxShadow: "none", 
                border: "none",
            }}
        >
            { loading && 
                <p style={{ textAlign: "center", color: "var(--dark)", paddingTop: "6rem" }}> 
                { t('loading_yearly_overview') } 
                </p>
            }
            { !loading && 
                <Grid stackable>
                    <Grid.Row columns={2} style={{ padding: "0rem" }}>
                        <Grid.Column>
                            <ResponsiveContainer width="90%" height={250}>
                                <ComposedChart
                                    activeIndex={activeBar}
                                    onClick={onBarClick}
                                    data={data}
                                    barSize={20}
                                    margin={{
                                        top: 20,
                                        right: 30,
                                        left: 20,
                                        bottom: 5
                                    }}
                                    >
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Legend/>
                                    <Tooltip content={<CustomTooltip/>}/>
                                    <ReferenceLine y={0} stroke="#000"/>
                                    <Bar name={t('difference')} dataKey="total" legendType="none">
                                        {
                                            data.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={entry.total >= 0 ? colors.success : colors.danger}/>
                                            ))
                                        }
                                    </Bar>
                                    <Line name={t('difference')} type="monotone" dataKey="total" stroke={colors.blue} legendType="none"/>
                                </ComposedChart>
                            </ResponsiveContainer>
                        </Grid.Column>
                        <Grid.Column>
                            <ResponsiveContainer width="100%" height={250}>
                                <PieChart>
                                    <Pie
                                        activeIndex={activeIndex}
                                        activeShape={renderActiveShape}
                                        data={pieData}
                                        cx="50%"
                                        cy="50%"
                                        innerRadius={60}
                                        outerRadius={80}
                                        fill="#8884d8"
                                        dataKey="value"
                                        onMouseEnter={onPieEnter}
                                    />
                                    <Legend content={
                                        <div style={{ textAlign: "center" }}>
                                            <p>
                                                { activeBar === null 
                                                    ? 
                                                        <strong>
                                                            <span style={{ textTransform: "capitalize" }}>{ moment().locale(language).month(monthStart - 1).format("MMMM") }</span>
                                                            { " - " }
                                                            <span style={{ textTransform: "capitalize" }}>{ moment().locale(language).month(monthEnd - 1).format("MMMM") }</span>
                                                            { " " }
                                                            { year }
                                                        </strong>
                                                    : <strong> 
                                                        <span style={{ textTransform: "capitalize" }}>
                                                            { moment().locale(language).month(activeBar - 1).format("MMMM") }
                                                        </span> { year } </strong>
                                                }
                                                { activeBar !== null &&
                                                    <>
                                                        <br/>
                                                        <span className="ref-link" style={{ color: "var(--primary)" }} onClick={() => clearSelection()}>
                                                            { t('clear_selection') }
                                                        </span>
                                                    </>
                                                }
                                            </p>
                                        </div>
                                    }/>
                                </PieChart>
                            </ResponsiveContainer>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            }
        </Segment>
    );
};

export default MonthlyCCStats;