import React, { useState } from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-semantic-toasts'
// store
import { API } from '@store/config'
import { routes } from '@store/routes'
import { requests } from '@helpers/requests'
import { fileDownload } from '@helpers/requests'
import { renderAddress } from '@helpers/functions'
// components
import Icon from '@components/Icon'
// eslint-disable-next-line
import Action from '@components/general/Action'
import ListView from '@components/ListView'
import { Label, Popup } from 'semantic-ui-react'
import GenerateDocuments from '@components/GenerateDocuments'
import Attachments from '@components/Attachments'
import AbroadForm from '../components/AbroadForm'
import AbroadsFilters from '../components/AbroadFilters'
import AbroadStats from '../components/AbroadStats'

const SlovakAbroads = () => {
    const { t } = useTranslation()
    const dateFormat = useSelector((state) => state.date_format)
    const countries = useSelector((state) => state.choices.countries)

    const [attachmentsOpen, setAttachmentsOpen] = useState(0)

    // eslint-disable-next-line
    const generatePDF = async (abroad) => {
        toast({
            type: 'success',
            icon: 'check circle',
            title: t('generating_export') + '...',
            description: t('wait_a_moment_until_its_finished') + '.',
            animation: 'bounce',
            time: 3000,
        })

        await fileDownload(
            'GET',
            API.API_URL + `/exports/abroads/a1_sk/${abroad.id}/?is_blob=true`,
            'application/pdf',
            `abroad__${abroad.id}.pdf`
        )
    }

    const getState = (dateFrom, dateTo) => {
        const fromDate = dateFrom ? moment(dateFrom).format(dateFormat) : ''
        const toDate = dateTo ? moment(dateTo).format(dateFormat) : ''

        if (fromDate === '' && toDate === '') return 0 // no dates are specified = inactive

        if (fromDate !== '' && toDate === '') {
            if (moment(dateFrom).isSameOrBefore(moment())) {
                return 1
            } else {
                return 0
            }
        }

        if (toDate !== '' && fromDate !== '') {
            if (moment(dateTo).isSameOrAfter(moment())) {
                return 1
            } else {
                return 0
            }
        }
    }

    // eslint-disable-next-line
    const setAsActive = async (itemID, setAbroad) => {
        const request = await requests.patch(API.ABROADS + itemID + '/', { is_draft: false })
        if (request.status === 200) {
            setAbroad((prevState) =>
                prevState.filter((item) => {
                    if (itemID === item.id) {
                        item.is_draft = false
                    }
                    return item
                })
            )
        }
    }

    return (
        <ListView
            as="table"
            allowSearch
            allowSelection
            showStatistic
            endpoint={API.ABROADS}
            actionsCellWidth="2"
            bulkActions={(selected) => [
                {
                    as: 'modal',
                    name: t('generate_documents'),
                    icon: 'document-text-outline',
                    modal: <GenerateDocuments source="abroads.SlovakAbroad" selected={selected} />,
                },
            ]}
            listAdditionActions={(data) => [
                {
                    as: 'filter',
                    index: 0,
                    name: t('active'),
                    filters: {
                        is_draft: false,
                        is_active: true,
                    },
                },
                {
                    as: 'filter',
                    index: 1,
                    name: t('inactive'),
                    filters: {
                        is_active: false,
                    },
                },
                // {
                //     as: 'filter',
                //     index: 2,
                //     name: t('draft'),
                //     filters: {
                //         is_active: '',
                //         is_draft: true,
                //     },
                // },
                {
                    as: 'filter',
                    index: 3,
                    name: t('all'),
                    filters: {
                        is_active: '',
                        is_draft: '',
                    },
                },
            ]}
            actions={[
                {
                    as: 'modal',
                    type: 'add',
                    name: t('posting_staff'),
                    modal: <AbroadForm />,
                },
                // {
                //     as: 'custom',
                //     type: 'custom',
                //     name: t('generate_pdf'),
                //     icon: 'document-text-outline',
                //     customIconColor: 'var(--danger)',
                //     onClick: (item) => generatePDF(item),
                // },
                {
                    name: t('edit'),
                    type: 'edit',
                    as: 'modal',
                    icon: 'pencil-outline',
                    customIconColor: 'var(--dark)',
                    modal: (item, setData, setTotal) => (
                        <AbroadForm item={item} setData={setData} setTotal={setTotal} />
                    ),
                },
                {
                    name: t('delete'),
                    type: 'delete',
                    as: 'delete',
                    text: t('delete'),
                },
            ]}
            renderStatisticView={(filters, data, total, params, setFilters, setTotal, setData, fetchData) => (
                <AbroadStats listData={data} params={params} />
            )}
            tableHeaders={[
                { title: t('employee'), orderBy: 'personal_information__person__lastname' },
                { title: t('added_employer') },
                { title: t('abroad_country') },
                { title: t('workplace') },
                { title: t('date_from'), orderBy: 'date_from' },
                { title: t('date_to'), orderBy: 'date_to' },
                { title: t('attachments') },
                { title: t('status') },
            ]}
            initialFilters={{
                // query: '{id, employee{id, fullname_with_titles}, inland_employer{id, name}, abroad_employer{id, name, identification_number}, abroad_country, work_address, work_abroad_from, work_abroad_to, is_draft, attachments}',
                date_from: '',
                date_to: '',
                abroad_country: '',
                unit: '',
                abroad_employer: '',
                inland_employer: '',
                is_draft: '',
                is_active: true,
                employee: '',
                employment_type: '',
            }}
            renderFilterFields={(filters, setFilters) => <AbroadsFilters filters={filters} setFilters={setFilters} />}
            renderCells={(abroad, setAbroad) => [
                {
                    content: (
                        <>
                            <Link to={routes.EMPLYOEES_DETAIL + abroad?.employee?.id} target="_blank">
                                <strong>{abroad?.employee?.fullname_with_titles}</strong>
                            </Link>
                            <br />
                            <strong>{abroad?.inland_employer?.name}</strong>
                        </>
                    ),
                },
                {
                    content: (
                        <>
                            <strong>{abroad?.abroad_employer?.name}</strong>
                            <br />
                            {abroad?.abroad_employer?.identification_number}
                        </>
                    ),
                },
                { content: countries?.[abroad?.abroad_country] },
                { content: renderAddress(abroad?.work_address) },
                { content: abroad?.work_abroad_from ? moment(abroad?.work_abroad_from).format(dateFormat) : null },
                { content: abroad?.work_abroad_to ? moment(abroad?.work_abroad_to).format(dateFormat) : null },
                {
                    content: (
                        <Label basic>
                            <Popup
                                style={{ zIndex: '1000', minWidth: '450px' }}
                                open={attachmentsOpen === abroad.id}
                                onOpen={() => setAttachmentsOpen(abroad.id)}
                                hoverable
                                position="left center"
                                trigger={
                                    <div style={{ cursor: 'pointer' }}>
                                        <Icon name="reader-outline" style={{ marginRight: '0.5rem' }} />
                                        <span style={{ position: 'relative', top: '-0.1rem' }}>
                                            {abroad.attachments.length}
                                        </span>
                                    </div>
                                }
                                content={
                                    <Attachments
                                        // viewOnly={!canManage}
                                        record={abroad}
                                        setData={setAbroad}
                                        sourceEndpoint={API.ABROADS}
                                        closeView={() => setAttachmentsOpen(0)}
                                    />
                                }
                            />
                        </Label>
                    ),
                },
                {
                    content: abroad?.is_draft ? (
                        <>
                            <Label basic style={{ backgroundColor: 'var(--info)', color: 'white' }}>
                                {t('draft')}
                            </Label>
                            {/* <br />
                            <Action
                                as="custom"
                                type="button"
                                buttonText={t('set_as_active')}
                                iconColor="var(--info)"
                                paddingRight="1rem"
                                onClick={() => setAsActive(abroad.id, setAbroad)}
                            /> */}
                        </>
                    ) : getState(abroad.work_abroad_from, abroad.work_abroad_to) === 0 ? (
                        <Label basic style={{ backgroundColor: 'var(--danger)', color: 'white' }}>
                            {t('inactive')}
                        </Label>
                    ) : (
                        <Label basic style={{ backgroundColor: 'var(--success)', color: 'white' }}>
                            {t('active')}
                        </Label>
                    ),
                },
            ]}
        />
    )
}

export default SlovakAbroads
