import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
// store
import { icons } from '@store/icons'
import { useHasPermissions } from '@helpers/hooks'
// components
import Icon from '@components/Icon'
import CanView from '@components/perms/CanView'
import BreadcrumbNav from '@components/BreadcrumbNav'
import { Container, Button, Divider } from 'semantic-ui-react'
// views
import PayrollsList from './views/PayrollsList'
import Bonuses from './views/Bonuses'
import Advances from './views/Advances'
import Deductions from './views/Deductions'

const Payrolls = () => {
    const { t } = useTranslation()
    const canViewPayrolls = useHasPermissions(['payrolls.c_view_all_payrolls', 'payrolls.c_manage_user_payrolls'])
    const canViewBonuses = useHasPermissions([
        'payrolls.c_view_all_bonus_payments',
        'payrolls.c_manage_user_bonus_payments',
    ])
    const canViewAdvances = useHasPermissions([
        'payrolls.c_view_all_advance_payments',
        'payrolls.c_manage_user_advance_payments',
    ])
    const canViewDeductions = useHasPermissions([
        'payrolls.c_view_all_deduction_payments',
        'payrolls.c_manage_user_deduction_payments',
    ])

    const [view, setView] = useState(() => {
        if (canViewPayrolls) return 1
        if (canViewBonuses) return 2
        if (canViewAdvances) return 3
        if (canViewDeductions) return 4

        return 0
    })

    return (
        <>
            <BreadcrumbNav items={[{ name: t('payroll_evidence'), icon: icons.PAYROLLS, href: '' }]} />
            <Container fluid>
                <Button.Group basic size="small" style={{ borderRadius: '0' }} className='custom-filter-button-group'>
                    <CanView permissions={['payrolls.c_view_all_payrolls', 'payrolls.c_manage_user_payrolls']}>
                        <Button active={view === 1} onClick={() => setView(1)}>
                            <Icon name={'cash-outline'} style={{ marginRight: '0.5rem' }} />
                            <span style={{ position: 'relative', top: '-0.1rem' }}>{t('payroll_records')}</span>
                        </Button>
                    </CanView>
                    <CanView
                        permissions={['payrolls.c_view_all_bonus_payments', 'payrolls.c_manage_user_bonus_payments']}
                    >
                        <Button active={view === 2} onClick={() => setView(2)}>
                            <span style={{ position: 'relative', top: '-0.1rem' }}>{t('bonuses_and_rewards')}</span>
                        </Button>
                    </CanView>
                    <CanView
                        permissions={[
                            'payrolls.c_view_all_advance_payments',
                            'payrolls.c_manage_user_advance_payments',
                        ]}
                    >
                        <Button active={view === 3} onClick={() => setView(3)}>
                            <span style={{ position: 'relative', top: '-0.1rem' }}>{t('advances')}</span>
                        </Button>
                    </CanView>
                    <CanView
                        permissions={[
                            'payrolls.c_view_all_deduction_payments',
                            'payrolls.c_manage_user_deduction_payments',
                        ]}
                    >
                        <Button active={view === 4} onClick={() => setView(4)}>
                            <span style={{ position: 'relative', top: '-0.1rem' }}>{t('deductions')}</span>
                        </Button>
                    </CanView>
                </Button.Group>
                <Divider style={{ marginBottom: 0 }} />
            </Container>

            {view === 1 && <PayrollsList />}
            {view === 2 && <Bonuses />}
            {view === 3 && <Advances />}
            {view === 4 && <Deductions />}
        </>
    )
}

export default Payrolls
