import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
// components
import CanView from '@components/perms/CanView';
import UserDailyStats from '@components/widgets/statistics/UserDailyStats';
import UserDailyTasks from '@components/widgets/statistics/UserDailyTasks';
import DashboardTotalStatsView from '@components/widgets/statistics/DashboardTotalStatsView';
import MultipleDashboardWidgets from '@components/widgets/statistics/MultipleDashboardWidgets';
// specific
import Sidebar from './sidebar/Sidebar';
import MainNavigation from "./MainNavigation";
import { Grid } from 'semantic-ui-react';

function SidebarWidgetsView({ changeViewType }) {
    const user = useSelector(state => state.user)
    const dateFormat = useSelector(state => state.date_format)
    const today = moment().utc().format("YYYY-MM-DD HH:mm:ss")

    return (
        <>
            <Sidebar />
            <div className="main">
                <MainNavigation changeViewType={changeViewType}/>

                {/* Main area for statistics */}
                <Grid stackable>
                    <Grid.Row>
                        <Grid.Column mobile={16} tablet={16} computer={7}>
                            <UserDailyStats
                                user={user}
                                dateFormat={dateFormat}
                                today={today}
                            />

                            <CanView permissions={['tasks.c_view_tasks']}>
                                <UserDailyTasks
                                    user={user} 
                                    dateFormat={dateFormat}
                                />
                            </CanView>

                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={9}>
                            <MultipleDashboardWidgets
                                user={user}
                                dateFormat={dateFormat}
                                today={today}
                            />

                            <CanView permissions={['reports.c_view_dashboard_reports']}>
                                <DashboardTotalStatsView/>
                            </CanView>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        </>
    );
}

export default SidebarWidgetsView;