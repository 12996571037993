import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-semantic-toasts'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
// components
import SuperField from '@components/forms/SuperField'
import ModalCancel from '@components/buttons/ModalCancel'
import ModalSubmit from '@components/buttons/ModalSubmit'
import { Form, Divider, Label } from 'semantic-ui-react'

const findAbsenceType = (type, absenceTypes) => {
    return absenceTypes.find((item) => item.id === type)
}

const AddAbsenceRecordForm = ({ onClose, day, contract, absences, setContracts, setAttendanceAbsenceRecords }) => {
    const { t } = useTranslation()
    const [absenceTypes, setAbsenceTypes] = useState([])
    const [absenceData, setAbsenceData] = useState({
        type: '',
        value: '',
        vacation_hours: '',
    })

    const [processing, setProcessing] = useState(false)
    // eslint-disable-next-line
    const [form, setForm] = useState({
        date: day.date,
        employee: contract.profile_id,
        contract: contract.id,
    })

    const handleAbsenceDataset = () => {
        // handle absence if exists
        const additionalData = {}
        let source = absenceData.type !== '' ? findAbsenceType(absenceData.type, absenceTypes)?.source : null
        if (source !== null) {
            additionalData['absence_type'] = absenceData.type
            if (source === 'vacation') {
                additionalData['hours'] = absenceData.vacation_hours
                additionalData['vacation_type'] = absenceData.value
            } else {
                additionalData['hours'] = absenceData.value
            }

            additionalData['contract'] = form.contract
            additionalData['date'] = form.date
            additionalData['is_corrected'] = true
        }

        return additionalData
    }

    const handleAbsenceSubmit = async () => {
        setProcessing(true)
        const absenceData = handleAbsenceDataset()
        const requestAbsence = await requests.post(API.ATTENDANCE_BASE + 'absences/', absenceData)
        if (requestAbsence.status === 201) {
            toast({
                type: 'success',
                icon: 'check circle',
                title: t('correction_record_created'),
                animation: 'pulse',
                time: 2000,
            })
            setAttendanceAbsenceRecords((prev) => [...prev, requestAbsence.response])
            onClose()
        }

        setProcessing(false)
    }

    useEffect(() => {
        const fetchAbsenceTypes = async () => {
            const request = await requests.get(API.ATTENDANCE_BASE + 'absence_types/?is_active=true')
            if (request.status === 200) setAbsenceTypes(request.response)
        }

        fetchAbsenceTypes()
    }, [])

    return (
        <Form onSubmit={handleAbsenceSubmit}>
            <>
                <SuperField
                    as="choice"
                    search
                    required
                    value={absenceData.type}
                    label={t('absence_type')}
                    customOptions={absenceTypes.map((item) => ({
                        key: item.id,
                        value: item.id,
                        text: item.title,
                    }))}
                    onChange={(e, { value }) => {
                        setAbsenceData((prev) => ({ ...prev, type: value, value: '', vacation_hours: '' }))
                    }}
                />

                {findAbsenceType(absenceData.type, absenceTypes)?.source !== 'vacation' ? (
                    <Form.Field>
                        <SuperField
                            as="input"
                            required
                            label={t('number_of_hours')}
                            value={absenceData.value}
                            error={
                                isNaN(absenceData.value) ||
                                parseFloat(absenceData.value) > 24 ||
                                parseFloat(absenceData.value) <= 0
                                    ? t('invalid_number')
                                    : false
                            }
                            onChange={(e, { value }) =>
                                setAbsenceData((prev) => ({ ...prev, value: value.replace(',', '.') }))
                            }
                        />
                        <Label.Group simple>
                            <Label
                                style={{ cursor: 'pointer' }}
                                active={absenceData.value.toString() === '7.5'}
                                onClick={() => setAbsenceData((prev) => ({ ...prev, value: 7.5 }))}
                            >
                                7.5h
                            </Label>
                            <Label
                                style={{ cursor: 'pointer' }}
                                active={absenceData.value.toString() === '8'}
                                onClick={() => setAbsenceData((prev) => ({ ...prev, value: 8 }))}
                            >
                                8h
                            </Label>
                            <Label
                                style={{ cursor: 'pointer' }}
                                active={absenceData.value.toString() === '12'}
                                onClick={() => setAbsenceData((prev) => ({ ...prev, value: 12 }))}
                            >
                                12h
                            </Label>
                        </Label.Group>
                    </Form.Field>
                ) : (
                    <>
                        <SuperField
                            as="choice"
                            required
                            label={t('choose_option')}
                            value={absenceData.value !== '0.00' ? absenceData.value || '' : ''}
                            customOptions={[
                                { key: 1, value: '1.00', text: t('fullday_vacation') },
                                { key: 2, value: '0.50', text: t('halfday_vacation') },
                            ]}
                            onChange={(e, { value }) => {
                                if (parseFloat(value) === 0.5) {
                                    setAbsenceData((prev) => ({ ...prev, value: value, vacation_hours: 4 }))
                                } else {
                                    setAbsenceData((prev) => ({ ...prev, value: value, vacation_hours: 8 }))
                                }
                            }}
                        />

                        <Form.Field>
                            <SuperField
                                as="input"
                                required
                                label={t('number_of_hours')}
                                value={absenceData.vacation_hours}
                                error={
                                    isNaN(absenceData.vacation_hours) ||
                                    parseFloat(absenceData.vacation_hours) > 24 ||
                                    parseFloat(absenceData.vacation_hours) <= 0
                                        ? t('invalid_number')
                                        : false
                                }
                                onChange={(e, { value }) =>
                                    setAbsenceData((prev) => ({
                                        ...prev,
                                        vacation_hours: value.replace(',', '.'),
                                    }))
                                }
                            />
                            <Label.Group simple>
                                <Label
                                    style={{ cursor: 'pointer' }}
                                    active={absenceData.vacation_hours.toString() === '4'}
                                    onClick={() => setAbsenceData((prev) => ({ ...prev, vacation_hours: 4 }))}
                                >
                                    4h
                                </Label>
                                <Label
                                    style={{ cursor: 'pointer' }}
                                    active={absenceData.vacation_hours.toString() === '6'}
                                    onClick={() => setAbsenceData((prev) => ({ ...prev, vacation_hours: 6 }))}
                                >
                                    6h
                                </Label>
                                <Label
                                    style={{ cursor: 'pointer' }}
                                    active={absenceData.vacation_hours.toString() === '7.5'}
                                    onClick={() => setAbsenceData((prev) => ({ ...prev, vacation_hours: 7.5 }))}
                                >
                                    7.5h
                                </Label>
                                <Label
                                    style={{ cursor: 'pointer' }}
                                    active={absenceData.vacation_hours.toString() === '8'}
                                    onClick={() => setAbsenceData((prev) => ({ ...prev, vacation_hours: 8 }))}
                                >
                                    8h
                                </Label>
                                <Label
                                    style={{ cursor: 'pointer' }}
                                    active={absenceData.vacation_hours.toString() === '10'}
                                    onClick={() => setAbsenceData((prev) => ({ ...prev, vacation_hours: 10 }))}
                                >
                                    10h
                                </Label>
                                <Label
                                    style={{ cursor: 'pointer' }}
                                    active={absenceData.vacation_hours.toString() === '12'}
                                    onClick={() => setAbsenceData((prev) => ({ ...prev, vacation_hours: 12 }))}
                                >
                                    12h
                                </Label>
                            </Label.Group>
                        </Form.Field>
                    </>
                )}
                <Divider />
                <Form.Field style={{ textAlign: 'right' }}>
                    <ModalCancel onClose={onClose} />
                    <ModalSubmit
                        loading={processing}
                        disabled={
                            absenceData.type === '' ||
                            absenceData.value === '' ||
                            (findAbsenceType(absenceData.type, absenceTypes)?.source === 'vacation' &&
                                absenceData.vacation_hours === '') ||
                            processing
                        }
                    />
                </Form.Field>
            </>
        </Form>
    )
}

export default AddAbsenceRecordForm
