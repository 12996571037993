import React, { useState, useEffect } from 'react'
import { Switch } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
// store
import { routes } from '@routes/index'
import { setSettingsURL } from '@store/actions'
// design:
import '@styles/dashboard.css'
// General components
import Layout from './_views/Layout'
import DashboardPages from './DashboardPages'
import FirstLoginPage from './FirstLoginPage'
import Sidebar from './_views/sidebar/Sidebar'
import MainNavigation from './_views/MainNavigation'
import PrivateRoute from '@components/routes/PrivateRoute'
import BugReporter from '@components/widgets/bug-reporter/BugReporter'
import SettingsSidebar from './core/settings/components/settings-views/SettingsSidebar'

function Dashboard() {
    const dispatch = useDispatch()
    const isFirstLogin = useSelector((state) => state?.user?.is_first_login)
    // const toggled_sidebar = useSelector((state) => state.toggled_sidebar)

    const isSettingsView = window.location.href.indexOf('settings') > -1

    useEffect(() => {
        function setURL() {
            if (!isSettingsView) {
                dispatch(setSettingsURL(window.location.pathname))
            }
        }
        setURL()
        //eslint-disable-next-line
    }, [window.location.href])

    // component state
    const [viewType, setViewType] = useState(2) // 1 - as IconsView, 2 - as SidebarWidgetsView

    const changeViewType = () => {
        if (viewType === 1) {
            setViewType(2)
        } else {
            setViewType(1)
        }
    }

    return (
        <div>
            {isFirstLogin ? (
                <FirstLoginPage />
            ) : (
                <>
                    <BugReporter />
                    {/* <MainNavigation changeViewType={changeViewType}/> */}

                    {/* <div className={toggled_sidebar ? 'wrapper collapsed' : 'wrapper'}> */}
                    <div className={'wrapper'}>
                        <Switch>
                            {window.location.href === window.location.origin + routes.DASHBOARD ? (
                                <PrivateRoute
                                    exact
                                    path={routes.DASHBOARD}
                                    component={() => <Layout viewType={viewType} changeViewType={changeViewType} />}
                                />
                            ) : (
                                <>
                                    {!isSettingsView && <Sidebar />}
                                    {isSettingsView && <SettingsSidebar />}
                                    <div className="main">
                                        <MainNavigation changeViewType={changeViewType} />
                                        <DashboardPages />
                                    </div>
                                </>
                            )}
                        </Switch>
                    </div>
                </>
            )}
        </div>
    )
}

export default Dashboard
