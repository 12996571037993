import React from 'react'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
// store
import { API } from '@store/config'
import { tzDateTime } from '@helpers/dates'
import { requests } from '@helpers/requests'
import { handleURL } from '@helpers/functions'
import { useHasPermission } from '@helpers/hooks'
// import { toast } from 'react-semantic-toasts';
// components
import Icon from '@components/Icon'
import ListView from '@components/ListView'
import AvatarList from '@components/AvatarList'
import CanView from '@components/perms/CanView'
import LabelsList from '@components/lists/LabelsList'
import BreadcrumbNav from '@components/BreadcrumbNav'
import SuperField from '@components/forms/SuperField'
import { Popup, Label, Form, List } from 'semantic-ui-react'
import ContactDetails from '@components/ContactDetails'
import SuperDuperModal from '@components/modals/SuperDuperModal'
// module specific components
import QuickNotes from './components/QuickNotes'
import OpportunityForm from './components/OpportunityForm'
import LastContactedForm from './components/LastContactedForm'
import OpportunityListStats from './components/OpportunityListStats'

const Opportunities = () => {
    const { t } = useTranslation()
    const dateFormat = useSelector((state) => state.date_format)

    const canManage = useHasPermission('orders.c_manage_offer')

    const onCreate = (response, setData, setTotal) => {
        setData((prev) => [response, ...prev])
        setTotal((prev) => prev + 1)
    }

    const onUpdate = (response, setData) => {
        setData((prev) =>
            prev.map((item) => {
                if (item.id === response.id) {
                    item = response
                }
                return item
            })
        )
    }

    const determinateColor = (stage) => {
        let color = 'var(--dark)'

        if (stage === 1) color = 'var(--info)'
        if (stage === 2) color = 'var(--primary)'
        if (stage === 3) color = 'var(--warning)'
        if (stage === 4) color = 'var(--blue)'
        if (stage === 5) color = 'var(--iceblue)'
        if (stage === 6) color = 'brown'
        if (stage === 7) color = 'orange'
        if (stage === 8) color = 'green'
        if (stage === 9) color = 'var(--danger)'
        return color
    }

    const onStageChange = async (stage, lead, setData) => {
        if (lead.stage !== stage) {
            const request = await requests.patch(API.LEADS + lead.id + '/', { stage: stage })
            if (request.status === 200) {
                setData((prev) =>
                    prev.map((item) => {
                        if (item.id === lead.id) {
                            item = request.response
                        }
                        return item
                    })
                )
            }
        }
    }

    return (
        <CanView permissions={['orders.c_view_offer']} redirect>
            <BreadcrumbNav items={[{ name: t('opportunities'), icon: '', href: '' }]} />
            <ListView
                as="table"
                allowSearch
                endpoint={API.LEADS}
                showStatistic
                visibleStatsOnRender
                actionsCellWidth="2"
                initialFilters={{
                    is_opportunity: true,
                    assigned_to: '',
                    stage: '',
                    unit: '',
                    source: '',
                    tags: [],
                    ordering: '-created_on',
                }}
                renderFilterFields={(filters, setFilters, visible) => (
                    <>
                        <Form.Group widths="equal">
                            <SuperField
                                as="choice"
                                search
                                label={t('assigned_to')}
                                endpoint={API.EMPLOYEES + '?only_basic_info=true&is_active=true'}
                                text="fullname"
                                value={filters.assigned_to}
                                onChange={(e, { value }) =>
                                    setFilters({
                                        ...filters,
                                        assigned_to: value,
                                    })
                                }
                            />

                            <SuperField
                                as="choice"
                                label={t('stage')}
                                type="opportunity_stage_choices"
                                value={filters.stage?.toString() || ''}
                                onChange={(e, { value }) =>
                                    setFilters({
                                        ...filters,
                                        stage: value,
                                        state: '',
                                    })
                                }
                            />
                        </Form.Group>

                        <SuperField
                            as="choice"
                            search
                            value={filters.unit}
                            endpoint={API.UNITS + '?query={id, name}&only_from_active_company=true'}
                            text="name"
                            label={t('unit')}
                            onChange={(e, { value }) =>
                                setFilters({
                                    ...filters,
                                    unit: value,
                                })
                            }
                        />

                        <SuperField
                            search
                            multiple
                            as="choice"
                            text="name"
                            label={t('tags')}
                            value={filters.tags}
                            endpoint={API.ORDERS + 'labels/?lead_tag=true'}
                            onChange={(e, { value }) =>
                                setFilters({
                                    ...filters,
                                    tags: value,
                                })
                            }
                        />

                        <SuperField
                            as="choice"
                            search
                            label={t('source')}
                            text="title"
                            endpoint={API.ORDERS + 'sale_sources/'}
                            value={filters.source}
                            onChange={(e, { value }) =>
                                setFilters({
                                    ...filters,
                                    source: value,
                                })
                            }
                        />
                    </>
                )}
                exports={[
                    {
                        endpoint: API.EXPORTS + 'dynamic_leads/',
                        permissions: ['orders.c_view_offer', 'orders.c_view_user_offer'],
                        apply_filters: true,
                        type: 'xlsx',
                        filename: 'opportunities',
                    },
                ]}
                actions={[
                    {
                        as: 'modal',
                        type: 'add',
                        name: t('create'),
                        modalSize: 'small',
                        permissions: ['orders.c_manage_offer'],
                        modal: (
                            <OpportunityForm
                                isOpportunity={true}
                                handleRequest={(response, setData, setTotal) => onCreate(response, setData, setTotal)}
                            />
                        ),
                    },
                    // {
                    //     name: t('convert_to_opportunity'),
                    //     type: "custom",
                    //     as: "confirm",
                    //     icon: "sync-outline",
                    //     text: t('convert_to_opportunity'),
                    //     permissions: ['orders.c_manage_offer'],
                    //     isHidden: (item) => item.is_opportunity,
                    //     onClick: async (item, setData, setTotal) => {
                    //         let data = {
                    //             is_opportunity: true,
                    //             state: 5,
                    //             stage: 1
                    //         }
                    //         const request = await requests.patch(API.LEADS + item.id + "/", data)

                    //         if (request.status === 200) {
                    //             toast({
                    //                 type: 'success',
                    //                 icon: 'check circle',
                    //                 title: t('converted_to_opportunity'),
                    //                 animation: 'pulse',
                    //                 time: 2000,
                    //             })

                    //             setData(prev => prev.map(lead => {
                    //                 if (lead.id === item.id) {
                    //                     lead = request.response
                    //                 }

                    //                 return lead
                    //             }))
                    //         }
                    //     }
                    // },
                    {
                        as: 'modal',
                        type: 'edit',
                        name: t('edit'),
                        modalSize: 'small',
                        permissions: ['orders.c_manage_offer'],
                        modal: (item, setData) => (
                            <OpportunityForm
                                isOpportunity={true}
                                data={item}
                                setData={setData}
                                handleRequest={(response, setData, setTotal) => onUpdate(response, setData, setTotal)}
                            />
                        ),
                    },
                    {
                        name: t('delete'),
                        type: 'delete',
                        as: 'delete',
                        text: t('are_you_sure'),
                        permissions: ['orders.c_delete_offer'],
                    },
                ]}
                tableHeaders={[
                    { title: t('fullname'), orderBy: 'lastname' },
                    { title: t('position'), orderBy: 'title' },
                    { title: t('company_name'), orderBy: 'company_name' },
                    { title: t('contact') },
                    { title: t('last_contacted'), orderBy: 'last_contacted' },
                    { title: t('tags') },
                    { title: t('source'), orderBy: 'source__title' },
                    { title: t('assigned_to') },
                    { title: t('description') },
                    { title: t('notes') },
                    { title: t('stage'), orderBy: 'stage' },
                    { title: t('created') },
                ]}
                renderCells={(lead, setData) => [
                    {
                        width: 1,
                        content: `${lead.firstname} ${lead.lastname}`,
                    },
                    {
                        width: 2,
                        content: lead.title || '--',
                    },
                    {
                        content: (
                            <span>
                                <span>{lead.company_name}</span>
                                <br />
                                <small>{lead.company_id}</small>
                            </span>
                        ),
                    },
                    {
                        content: (
                            <>
                                <ContactDetails phone={lead?.phone} email={lead?.email} />
                                {lead?.url && (
                                    <div>
                                        <Icon
                                            name="globe"
                                            style={{
                                                marginRight: '0.5rem',
                                                color: 'var(--primary)',
                                                position: 'relative',
                                                top: '0.1rem',
                                            }}
                                        />
                                        <a href={handleURL(lead.url)} target="_blank" rel="noopener noreferrer">
                                            {handleURL(lead.url)}
                                        </a>
                                    </div>
                                )}
                            </>
                        ),
                    },
                    {
                        content: (
                            <>
                                {lead?.last_contacted ? (
                                    <strong>
                                        {' '}
                                        {moment(lead.last_contacted).format('HH:mm') === '00:00'
                                            ? moment(lead?.last_contacted).format(dateFormat)
                                            : tzDateTime(lead?.last_contacted).format(dateFormat + ' HH:mm')}{' '}
                                    </strong>
                                ) : (
                                    t('not_contacted')
                                )}{' '}
                                <br />
                                <SuperDuperModal
                                    header={t('add_last_contacted')}
                                    trigger={
                                        <small className="ref-link" style={{ color: 'var(--primary)' }}>
                                            {t('add_date')}
                                        </small>
                                    }
                                    content={<LastContactedForm lead={lead} setData={setData} />}
                                />
                            </>
                        ),
                    },
                    {
                        content: (
                            <LabelsList
                                key={lead.id}
                                rowID={lead.id}
                                labels={lead.tags}
                                endpoint={API.LEADS}
                                additionalFilters="&lead_tag=true"
                                mark="lead_tag"
                                endpointLabels={API.ORDERS + 'labels/?lead_tag=true'}
                                label_key="tags"
                            />
                        ),
                    },
                    {
                        content: lead?.source?.id ? (
                            <Label
                                style={{
                                    background: lead.source?.color === '' ? 'var(--dark)' : lead.source?.color + 'B3',
                                }}
                            >
                                {lead.source?.title}
                            </Label>
                        ) : (
                            '--'
                        ),
                    },
                    {
                        content: (
                            <AvatarList
                                size="25"
                                alignDirection="start"
                                persons={lead.assigned_to.map((item) => ({
                                    id: item.id,
                                    name: item.fullname,
                                    avatar: item.profile_picture,
                                    displayName: item?.fullname_with_titles || item.fullname,
                                }))}
                            />
                        ),
                    },
                    {
                        content: lead.description ? (
                            <Popup
                                hoverable
                                size="tiny"
                                position="left center"
                                trigger={<Icon name="document-text-outline" style={{ fontSize: '1.3rem' }} />}
                                content={lead.description}
                            />
                        ) : (
                            ''
                        ),
                    },
                    { content: <QuickNotes lead={lead} setData={setData} /> },
                    {
                        content: (
                            <>
                                {canManage ? (
                                    <Popup
                                        hoverable
                                        position="bottom left"
                                        trigger={
                                            <div style={{ position: 'relative' }}>
                                                <Icon
                                                    name="ellipse"
                                                    style={{
                                                        marginRight: '0.5rem',
                                                        color: determinateColor(lead.stage),
                                                    }}
                                                />
                                                <span style={{ position: 'relative', top: '-0.10rem' }}>
                                                    {lead.stage_display}
                                                </span>
                                            </div>
                                        }
                                        content={
                                            <div>
                                                <strong>{t('change_stage')}:</strong>
                                                <List divided selection style={{ width: '250px' }}>
                                                    <List.Item
                                                        active={lead.stage === 1}
                                                        onClick={() => onStageChange(1, lead, setData)}
                                                    >
                                                        <Icon
                                                            name="ellipse"
                                                            style={{
                                                                marginRight: '0.5rem',
                                                                color: determinateColor(1),
                                                            }}
                                                        />
                                                        <span style={{ position: 'relative', top: '-0.10rem' }}>
                                                            {t('qualification')}
                                                        </span>
                                                    </List.Item>
                                                    <List.Item
                                                        active={lead.stage === 2}
                                                        onClick={() => onStageChange(2, lead, setData)}
                                                    >
                                                        <Icon
                                                            name="ellipse"
                                                            style={{
                                                                marginRight: '0.5rem',
                                                                color: determinateColor(2),
                                                            }}
                                                        />
                                                        <span style={{ position: 'relative', top: '-0.10rem' }}>
                                                            {t('needs_analysis')}
                                                        </span>
                                                    </List.Item>
                                                    <List.Item
                                                        active={lead.stage === 3}
                                                        onClick={() => onStageChange(3, lead, setData)}
                                                    >
                                                        <Icon
                                                            name="ellipse"
                                                            style={{
                                                                marginRight: '0.5rem',
                                                                color: determinateColor(3),
                                                            }}
                                                        />
                                                        <span style={{ position: 'relative', top: '-0.10rem' }}>
                                                            {t('value_proposition')}
                                                        </span>
                                                    </List.Item>
                                                    <List.Item
                                                        active={lead.stage === 4}
                                                        onClick={() => onStageChange(4, lead, setData)}
                                                    >
                                                        <Icon
                                                            name="ellipse"
                                                            style={{
                                                                marginRight: '0.5rem',
                                                                color: determinateColor(4),
                                                            }}
                                                        />
                                                        <span style={{ position: 'relative', top: '-0.10rem' }}>
                                                            {t('id_decision_makers')}
                                                        </span>
                                                    </List.Item>
                                                    <List.Item
                                                        active={lead.stage === 5}
                                                        onClick={() => onStageChange(5, lead, setData)}
                                                    >
                                                        <Icon
                                                            name="ellipse"
                                                            style={{
                                                                marginRight: '0.5rem',
                                                                color: determinateColor(5),
                                                            }}
                                                        />
                                                        <span style={{ position: 'relative', top: '-0.10rem' }}>
                                                            {t('preception_analysis')}
                                                        </span>
                                                    </List.Item>
                                                    <List.Item
                                                        active={lead.stage === 6}
                                                        onClick={() => onStageChange(6, lead, setData)}
                                                    >
                                                        <Icon
                                                            name="ellipse"
                                                            style={{
                                                                marginRight: '0.5rem',
                                                                color: determinateColor(6),
                                                            }}
                                                        />
                                                        <span style={{ position: 'relative', top: '-0.10rem' }}>
                                                            {t('proposal_price_quote')}
                                                        </span>
                                                    </List.Item>
                                                    <List.Item
                                                        active={lead.stage === 7}
                                                        onClick={() => onStageChange(7, lead, setData)}
                                                    >
                                                        <Icon
                                                            name="ellipse"
                                                            style={{
                                                                marginRight: '0.5rem',
                                                                color: determinateColor(7),
                                                            }}
                                                        />
                                                        <span style={{ position: 'relative', top: '-0.10rem' }}>
                                                            {t('negotiation_review')}
                                                        </span>
                                                    </List.Item>
                                                    <List.Item
                                                        active={lead.stage === 8}
                                                        onClick={() => onStageChange(8, lead, setData)}
                                                    >
                                                        <Icon
                                                            name="ellipse"
                                                            style={{
                                                                marginRight: '0.5rem',
                                                                color: determinateColor(8),
                                                            }}
                                                        />
                                                        <span style={{ position: 'relative', top: '-0.10rem' }}>
                                                            {t('closed_won')}
                                                        </span>
                                                    </List.Item>
                                                    <List.Item
                                                        active={lead.stage === 9}
                                                        onClick={() => onStageChange(9, lead, setData)}
                                                    >
                                                        <Icon
                                                            name="ellipse"
                                                            style={{
                                                                marginRight: '0.5rem',
                                                                color: determinateColor(9),
                                                            }}
                                                        />
                                                        <span style={{ position: 'relative', top: '-0.10rem' }}>
                                                            {t('closed_lost')}
                                                        </span>
                                                    </List.Item>
                                                </List>
                                            </div>
                                        }
                                    />
                                ) : (
                                    <div style={{ position: 'relative' }}>
                                        <Icon
                                            name="ellipse"
                                            style={{ marginRight: '0.5rem', color: determinateColor(lead.stage) }}
                                        />
                                        <span style={{ position: 'relative', top: '-0.10rem' }}>
                                            {lead.stage_display}
                                        </span>
                                    </div>
                                )}
                            </>
                        ),
                    },
                    {
                        content: (
                            <div>
                                {tzDateTime(lead.created_on).format(dateFormat + ' HH:mm')} <br />
                                <span>
                                    {t('created_by')} <strong>{lead.created_by?.name || '--'}</strong>
                                </span>
                            </div>
                        ),
                    },
                ]}
                renderStatisticView={(filters, data, total, params, setFilters, setTotal, setData, fetchData) => (
                    <OpportunityListStats
                        data={data}
                        total={total}
                        params={params}
                        filters={filters}
                        setFilters={setFilters}
                        fetchData={fetchData}
                    />
                )}
            />
        </CanView>
    )
}

export default Opportunities
