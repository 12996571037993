import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-semantic-toasts'
import moment from 'moment'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
// components
import { Form, Divider, Label, Button, Grid, Header, Segment } from 'semantic-ui-react'
import SuperField from '@components/forms/SuperField'
import ModalSubmit from '@components/buttons/ModalSubmit'
import ModalCancel from '@components/buttons/ModalCancel'

const findAbsenceType = (type, absenceTypes) => {
    return absenceTypes.find((item) => item.id === type)
}

const ChooseWorkingContract = ({ contracts, displayWorkplace, setContract }) => {
    const { t } = useTranslation()

    return (
        <Grid>
            <Grid.Row columns="1">
                <Grid.Column centered>
                    <Header as="h3" content={t('select_workplace')} style={{ textAlign: 'center' }} />
                </Grid.Column>
            </Grid.Row>
            {contracts.map((contract) => (
                <Grid.Row columns="1">
                    <Grid.Column onClick={() => setContract(contract)}>
                        <Segment
                            className="choose-workplace"
                            style={{
                                cursor: 'pointer',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                        >
                            <div>
                                <strong>
                                    {contract.employer.name} - {displayWorkplace(contract)}{' '}
                                </strong>{' '}
                                <br />
                                <span>{contract.work_position.title}</span>
                                <br />
                                <span style={{ opacity: '0.5' }}>{t('click_to_select_this_option')}</span>
                            </div>
                            <div>
                                {contract.status === 1 && (
                                    <Label style={{ background: 'green', color: 'var(--white)' }}>{t('active')}</Label>
                                )}
                                {contract.status === 3 && (
                                    <Label
                                        style={{
                                            background: 'var(--blue)',
                                            color: 'var(--white)',
                                            textAlign: 'center',
                                        }}
                                    >
                                        {t('active') + ' - ' + t('in_notice_period')}
                                    </Label>
                                )}
                            </div>
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
            ))}
        </Grid>
    )
}

const AbsenceRequestForm = ({ onClose, contracts, displayWorkplace, setSelectedContract }) => {
    const { t } = useTranslation()

    const [processing, setProcessing] = useState(false)

    const [isSingleDay, setIsSingleDay] = useState(true)
    const [contract, setContract] = useState(contracts.length === 1 ? contracts[0] : null)
    const [absenceTypes, setAbsenceTypes] = useState([])
    const [form, setForm] = useState({
        date_from: moment().format('YYYY-MM-DD'),
        date_to: '',
        type: '',
        vacation_hours: 8,
        value: 8,
    })

    useEffect(() => {
        const fetchAbsenceTypes = async () => {
            const request = await requests.get(API.ATTENDANCE_BASE + 'absence_types/?is_active=true')
            if (request.status === 200) setAbsenceTypes(request.response)
        }

        fetchAbsenceTypes()
    }, [])

    const handleSubmit = async () => {
        setProcessing(true)

        // handle absence if exists
        const additionalData = {}
        let source = form.type !== '' ? findAbsenceType(form.type, absenceTypes)?.source : null
        if (source !== null) {
            additionalData[source] = parseFloat(form.value)
            additionalData['absence_type'] = form.type
            if (source === 'vacation') {
                additionalData['vacation_hours'] = form.vacation_hours
            }

            additionalData['absence_reported_by_employee'] = true
            additionalData['absence_reported_by_employee_date'] = moment.utc().format('YYYY-MM-DD HH:mm:ss')
        }

        // single day
        if (isSingleDay) {
            const request = await requests.post(API.ATTENDANCE_BASE + 'absences/', {
                date: form.date_from,
                contract: contract.id,
                absence_type: form.type,
                hours:
                    findAbsenceType(form.type, absenceTypes).source === 'vacation' ? form.vacation_hours : form.value,
                vacation_type: findAbsenceType(form.type, absenceTypes).source === 'vacation' ? form.value : null,
                requested: true,
            })

            if (request.status === 201) {
                setSelectedContract(contract)
                toast({
                    type: 'success',
                    icon: 'check circle',
                    title: t('work_absence_requested'),
                    description: '',
                    animation: 'bounce',
                    time: 3000,
                })
                onClose()
            }
        } else {
            // multiple days
        }

        setProcessing(false)
    }

    const isFormValid = () => {
        let source = findAbsenceType(form.type, absenceTypes)?.source
        if (source === 'absence') source = 'absention'

        if (form.date_from === '') return false
        if (isSingleDay === false) {
            if (form.date_to === '') {
                return false
            }

            if (moment(form.date_to).isBefore(moment(form.date_from))) return false
        }

        if (form.type === '') return false
        if (form.value === '' || isNaN(form.value) || parseFloat(form.value) > 24 || parseFloat(form.value) <= 0) {
            return false
        }

        if (source === 'vacation') {
            if (
                form.vacation_hours === '' ||
                isNaN(form.vacation_hours) ||
                parseFloat(form.vacation_hours) > 24 ||
                parseFloat(form.vacation_hours) <= 0
            )
                return false
        }

        return true
    }

    return (
        <>
            {contracts.length > 0 ? (
                contract !== null ? (
                    <Form onSubmit={handleSubmit}>
                        <Form.Field>
                            <Button.Group fluid size="small" basic>
                                <Button type="button" active={isSingleDay} onClick={() => setIsSingleDay(true)}>
                                    {t('single_day_absence')}
                                </Button>
                                <Button
                                    disabled
                                    type="button"
                                    active={!isSingleDay}
                                    onClick={() => setIsSingleDay(false)}
                                >
                                    {t('multiple_days_absence')}
                                </Button>
                            </Button.Group>
                        </Form.Field>
                        <Divider />

                        <Form.Group widths="equal">
                            <SuperField
                                required
                                as="datepicker"
                                value={form.date_from}
                                minDate={moment()}
                                label={isSingleDay ? t('date') : t('date_from')}
                                onChange={(e, { value }) => setForm((prev) => ({ ...prev, date_from: value }))}
                            />
                            {!isSingleDay && (
                                <SuperField
                                    required
                                    as="datepicker"
                                    label={t('date_to')}
                                    value={form.date_to}
                                    onChange={(e, { value }) => setForm((prev) => ({ ...prev, date_to: value }))}
                                />
                            )}
                        </Form.Group>

                        {form.date_from !== '' && form.date_to !== '' ? (
                            moment(form.date_to).isBefore(moment(form.date_from)) ? (
                                <p style={{ color: 'var(--danger)', fontWeight: 'bold' }}> {t('invalid_range')} </p>
                            ) : null
                        ) : null}

                        <SuperField
                            as="choice"
                            search
                            required
                            value={form.type}
                            label={t('absence_type')}
                            customOptions={absenceTypes.map((item) => ({
                                key: item.id,
                                value: item.id,
                                text: item.title,
                            }))}
                            onChange={(e, { value }) => {
                                setForm((prev) => ({
                                    ...prev,
                                    type: value,
                                    value: findAbsenceType(value, absenceTypes)?.source === 'vacation' ? '1.00' : 8,
                                }))
                            }}
                        />

                        {findAbsenceType(form.type, absenceTypes)?.source !== 'vacation' ? (
                            <Form.Field>
                                <SuperField
                                    as="input"
                                    required
                                    label={t('number_of_hours')}
                                    value={form.value}
                                    error={
                                        isNaN(form.value) || parseFloat(form.value) > 24 || parseFloat(form.value) <= 0
                                            ? t('invalid_number')
                                            : false
                                    }
                                    onChange={(e, { value }) =>
                                        setForm((prev) => ({ ...prev, value: value.replace(',', '.') }))
                                    }
                                />
                                <Label.Group simple>
                                    <Label
                                        style={{ cursor: 'pointer' }}
                                        active={form.value.toString() === '7.5'}
                                        onClick={() => setForm((prev) => ({ ...prev, value: 7.5 }))}
                                    >
                                        7.5h
                                    </Label>
                                    <Label
                                        style={{ cursor: 'pointer' }}
                                        active={form.value.toString() === '8'}
                                        onClick={() => setForm((prev) => ({ ...prev, value: 8 }))}
                                    >
                                        8h
                                    </Label>
                                    <Label
                                        style={{ cursor: 'pointer' }}
                                        active={form.value.toString() === '12'}
                                        onClick={() => setForm((prev) => ({ ...prev, value: 12 }))}
                                    >
                                        12h
                                    </Label>
                                </Label.Group>
                            </Form.Field>
                        ) : (
                            <>
                                <SuperField
                                    as="choice"
                                    required
                                    label={t('choose_option')}
                                    value={form.value !== '0.00' ? form.value || '' : ''}
                                    customOptions={[
                                        { key: 1, value: '1.00', text: t('fullday_vacation') },
                                        { key: 2, value: '0.50', text: t('halfday_vacation') },
                                    ]}
                                    onChange={(e, { value }) => {
                                        if (parseFloat(value) === 0.5) {
                                            setForm((prev) => ({ ...prev, value: value, vacation_hours: 4 }))
                                        } else {
                                            setForm((prev) => ({ ...prev, value: value, vacation_hours: 8 }))
                                        }
                                    }}
                                />

                                <Form.Field>
                                    <SuperField
                                        as="input"
                                        required
                                        label={t('number_of_hours')}
                                        value={form.vacation_hours}
                                        error={
                                            isNaN(form.vacation_hours) ||
                                            parseFloat(form.vacation_hours) > 24 ||
                                            parseFloat(form.vacation_hours) <= 0
                                                ? t('invalid_number')
                                                : false
                                        }
                                        onChange={(e, { value }) =>
                                            setForm((prev) => ({ ...prev, vacation_hours: value.replace(',', '.') }))
                                        }
                                    />
                                    <Label.Group simple>
                                        <Label
                                            style={{ cursor: 'pointer' }}
                                            active={form.vacation_hours.toString() === '4'}
                                            onClick={() => setForm((prev) => ({ ...prev, vacation_hours: 4 }))}
                                        >
                                            4h
                                        </Label>
                                        <Label
                                            style={{ cursor: 'pointer' }}
                                            active={form.vacation_hours.toString() === '6'}
                                            onClick={() => setForm((prev) => ({ ...prev, vacation_hours: 6 }))}
                                        >
                                            6h
                                        </Label>
                                        <Label
                                            style={{ cursor: 'pointer' }}
                                            active={form.vacation_hours.toString() === '7.5'}
                                            onClick={() => setForm((prev) => ({ ...prev, vacation_hours: 7.5 }))}
                                        >
                                            7.5h
                                        </Label>
                                        <Label
                                            style={{ cursor: 'pointer' }}
                                            active={form.vacation_hours.toString() === '8'}
                                            onClick={() => setForm((prev) => ({ ...prev, vacation_hours: 8 }))}
                                        >
                                            8h
                                        </Label>
                                        <Label
                                            style={{ cursor: 'pointer' }}
                                            active={form.vacation_hours.toString() === '10'}
                                            onClick={() => setForm((prev) => ({ ...prev, vacation_hours: 10 }))}
                                        >
                                            10h
                                        </Label>
                                        <Label
                                            style={{ cursor: 'pointer' }}
                                            active={form.vacation_hours.toString() === '12'}
                                            onClick={() => setForm((prev) => ({ ...prev, vacation_hours: 12 }))}
                                        >
                                            12h
                                        </Label>
                                    </Label.Group>
                                </Form.Field>
                            </>
                        )}

                        <Divider />
                        <Form.Field style={{ textAlign: 'right' }}>
                            <ModalCancel onClose={onClose} disabled={processing} />
                            <ModalSubmit loading={processing} disabled={processing || !isFormValid()} />
                        </Form.Field>
                    </Form>
                ) : (
                    <ChooseWorkingContract
                        contracts={contracts}
                        setContract={setContract}
                        displayWorkplace={displayWorkplace}
                    />
                )
            ) : (
                <p style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1.2rem' }}>
                    {t('no_active_contract_found')}
                    <div style={{ marginTop: '0.5rem' }}>
                        <Button size="big" onClick={() => onClose()} content={t('close')} />
                    </div>
                </p>
            )}
        </>
    )
}

export default AbsenceRequestForm
