import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-semantic-toasts'
import '@store/languages'
import moment from 'moment'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
// eslint-disable-next-line
import { setLocaleLanguage } from '@helpers/dates'
// components
import Icon from '@components/Icon'
import SpinnerSegment from '@components/SpinnerSegment'
import { Container, Grid, Button, Table, Card, Header, Segment, Divider } from 'semantic-ui-react'

const WarehouseManagement = ({ employee, setMode, setEmployee }) => {
    const { t } = useTranslation()
    const dateFormat = useSelector((state) => state.date_format)
    const today = moment().format(dateFormat)

    const [fetchingStoredItemPositions, setFetchingStoredItemPositions] = useState(true)
    const [processingReturn, setProcessingReturn] = useState(false)
    const [processing, setProcessing] = useState(false)
    const [loading, setLoading] = useState(true)
    const [process, setProcess] = useState(null)
    const [activeItem, setActiveItem] = useState(null)
    const [processes, setProcesses] = useState([])
    const [filterView, setFilterView] = useState(0)
    const [stockedItems, setStockedItems] = useState([])

    const [allowedItems, setAllowedItems] = useState([])
    // const [allowedPositions, setAllowedPositions] = useState([])

    async function fetchStockedPositions(process) {
        let query = `{id, stock_number, stored_item{id, catalogue_item{id, code, title, weight_is_primary_measurement, measure_unit{abbreviation}, category}}, warehouse_position{id, title, section, row, quantity_limit}, quantity, average_weight}`
        let allowed_items = ''
        let totalItems = process.allowed_items.length
        for (let i = 0; i < process.allowed_items.length; i++) {
            if (totalItems === i + 1) {
                allowed_items += process.allowed_items[i].id
            } else {
                allowed_items += process.allowed_items[i].id + ','
            }
        }

        let allowed_positions = ''
        let totalPositions = process.allowed_positions.length
        for (let i = 0; i < process.allowed_positions.length; i++) {
            if (totalPositions === i + 1) {
                allowed_positions += process.allowed_positions[i].id
            } else {
                allowed_positions += process.allowed_positions[i].id + ','
            }
        }

        let queryAllowedItems = allowed_items !== '' ? `&allowed_items=${allowed_items}` : ''
        let queryAllowedPositions = allowed_positions !== '' ? `&allowed_positions=${allowed_positions}` : ''

        if (process.allowed_positions.length !== 0 || process.allowed_items.length !== 0) {
            const request = await requests.get(
                API.ASSETS +
                    `stored_item_positions/?query=${query}&ordering=-created_on&in_stock=true${queryAllowedItems}${queryAllowedPositions}`
            )
            if (request.status === 200) {
                setStockedItems(request.response)
                if (activeItem !== null) {
                    const selectedItem = request.response.find((item) => item.id === activeItem.id)
                    if (selectedItem) {
                        setActiveItem(selectedItem)
                    }
                }
            }
        } else {
            setStockedItems([])
            setActiveItem(null)
        }
    }

    async function fetchProcesses(intervalFetch) {
        intervalFetch = [undefined, false].includes(intervalFetch) ? false : true
        if (!intervalFetch) setLoading(true)
        const request = await requests.get(
            API.ASSETS + 'warehouse_processes/?is_active=true&is_template=false&responsible_persons=' + employee.id
        )
        if (request.status === 200) {
            const results = request.response?.results || []
            if (results?.length >= 1 && intervalFetch && process !== null) {
                let selectedProcess = process
                if (selectedProcess === null) {
                    setProcess(results?.[0] || null)
                } else {
                    setProcess(results.find((item) => item.id === selectedProcess.id) || null)
                }

                if (process) {
                    await fetchStockedPositions(process)
                }
            }

            setProcesses(results || [])
        }
        setLoading(false)
    }

    useEffect(() => {
        const interval = setInterval(async () => {
            if (!loading) {
                await fetchProcesses(true)
            }
        }, 10000)

        return () => clearInterval(interval)
    })

    useEffect(() => {
        fetchProcesses()
        // eslint-disable-next-line
    }, [employee])

    const onReturn = () => {
        setMode(1)
        setEmployee(null)
    }

    const calculateQuantityToDeduct = (item) => {
        let toDeduct = item.stored_item.catalogue_item.weight_is_primary_measurement ? item.average_weight || 1 : 1

        let calculatedDiff = parseFloat(parseFloat(item.quantity) - parseFloat(item.average_weight))
        if (calculatedDiff < 0) toDeduct = item.quantity

        return toDeduct
    }

    const handlePositionSelection = async (item) => {
        setProcessing(true)
        const request = await requests.post(API.ASSETS + 'warehouse_process_operations/', {
            quantity: calculateQuantityToDeduct(item),
            operation: 6,
            stored_item: activeItem.stored_item.id,
            warehouse_process: process.id,
            warehouse_position: item.warehouse_position.id,
            performed_by: employee.id,
            stock_number: item.stock_number,
        })

        if (request.status === 201) {
            const requestStoredItem = await requests.get(
                API.ASSETS +
                    `stored_item_positions/?stock_number=${item.stock_number}&stored_item=${item.stored_item.id}`
            )
            if (requestStoredItem.status === 200) {
                if (requestStoredItem.response.length > 0) {
                    if (requestStoredItem.response[0]?.id) {
                        setStockedItems((prev) =>
                            prev.filter((stockItem) => {
                                if (stockItem.id === item.id) {
                                    stockItem.quantity = requestStoredItem.response[0].quantity
                                }

                                return stockItem
                            })
                        )

                        setActiveItem((prev) => ({ ...prev, quantity: requestStoredItem.response[0].quantity }))
                    }
                }
            }
        } else {
            toast({
                size: 'huge',
                type: 'error',
                icon: 'warning',
                title: request.response?.non_field_errors?.[0] || t('unable_to_perform_action'),
                animation: 'pulse',
                time: 5000,
            })
        }
        setProcessing(false)
    }

    const handlePositionReturn = async (item) => {
        setProcessingReturn(true)
        const isWeight = activeItem.stored_item.catalogue_item.weight_is_primary_measurement
        const request = await requests.post(API.ASSETS + 'warehouse_process_operations/', {
            quantity: isWeight ? activeItem.average_weight : 1,
            operation: 1,
            stored_item: activeItem.stored_item.id,
            warehouse_process: process.id,
            warehouse_position: item.warehouse_position.id,
            performed_by: employee.id,
            stock_number: item.stock_number,
        })

        if (request.status === 201) {
            const requestStoredItem = await requests.get(
                API.ASSETS +
                    `stored_item_positions/?stock_number=${item.stock_number}&stored_item=${item.stored_item.id}`
            )
            if (requestStoredItem.status === 200) {
                if (requestStoredItem.response.length > 0) {
                    if (requestStoredItem.response[0]?.id) {
                        if (stockedItems.find((stockItem) => stockItem.id === item.id)) {
                            setStockedItems((prev) =>
                                prev.filter((stockItem) => {
                                    if (stockItem.id === item.id) {
                                        stockItem.quantity = requestStoredItem.response[0].quantity
                                    }

                                    return stockItem
                                })
                            )
                        } else {
                            setStockedItems((prev) => [...prev, requestStoredItem.response[0]])
                        }

                        setActiveItem((prev) => ({ ...prev, quantity: requestStoredItem.response[0].quantity }))
                    }
                }
            }
        }
        setProcessingReturn(false)
    }

    const isValidAction = (selectedItem) => {
        if (selectedItem !== undefined && selectedItem.quantity === 0) return false
        return true
    }

    // eslint-disable-next-line
    const displayItem = (item) => {
        const catalogueItem = item?.stored_item?.catalogue_item
        return (catalogueItem?.code ? catalogueItem?.code + ' ' : '') + catalogueItem?.title
    }

    const handleProcessSelection = async (process) => {
        setFetchingStoredItemPositions(true)
        setProcess(process)
        setActiveItem(null)
        await fetchStockedPositions(process)
        setFetchingStoredItemPositions(false)
    }

    const calculatePackages = (stockItem) => {
        let quantity = parseFloat(stockItem.quantity || 0)
        let avgWeight = parseFloat(stockItem.average_weight || 0)
        let packages = quantity

        if (avgWeight > 0 && stockItem.stored_item.catalogue_item.weight_is_primary_measurement) {
            packages = quantity / avgWeight
        }

        return parseInt(packages || 0)
    }

    const setItemView = (items) => {
        let queryset = items
        if (allowedItems.length === 0) {
            queryset = items
        } else {
            queryset = items.filter((item) =>
                allowedItems.includes(
                    filterView === 0
                        ? item.stored_item.catalogue_item?.category?.id
                        : item.stored_item.catalogue_item.id
                )
            )
        }

        return queryset
    }

    const setCategoryView = (items) => {
        const categories = items.map((item) => item.category)
        const uniqueCategories = []

        for (let i = 0; i < categories.length; i++) {
            if (![undefined, null, ''].includes(categories[i])) {
                if (!uniqueCategories.find((item) => item.id === categories[i].id)) {
                    uniqueCategories.push(categories[i])
                }
            }
        }

        return uniqueCategories
    }

    return (
        <Container fluid style={{ padding: 0 }}>
            <Grid stackable style={{ borderBottom: '1px solid lightgray' }}>
                <Grid.Row columns="2" style={{ background: 'var(--light)', padding: '1rem' }} verticalAlign="middle">
                    <Grid.Column style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        {process !== null && (
                            <Icon
                                name="chevron-back"
                                style={{
                                    cursor: 'pointer',
                                    marginRight: '1.5rem',
                                    fontSize: '2.5rem',
                                    fontWeight: 'bold',
                                }}
                                onClick={() => {
                                    setProcess(null)
                                    setActiveItem(null)
                                }}
                            />
                        )}

                        {process !== null ? (
                            <div>
                                <strong style={{ fontSize: '1.5rem' }}>{process.title} </strong>
                                <br />
                                <span style={{ opacity: '0.8', fontSize: '1.2rem' }}>{process.note}</span>
                            </div>
                        ) : (
                            <Header as="h2">{t('available_processes')}</Header>
                        )}
                    </Grid.Column>
                    <Grid.Column
                        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'right' }}
                    >
                        <div style={{ marginRight: '2rem' }}>
                            <strong style={{ fontSize: '1.3rem' }}>{today}</strong> <br />
                            <span style={{ textTransform: 'capitalize' }}>
                                {moment(today, dateFormat).locale(setLocaleLanguage()).format('dddd')}
                            </span>
                        </div>
                        <div style={{ marginRight: '2rem' }}>
                            <strong style={{ fontSize: '1.3rem' }}>{employee?.name || '--'}</strong>
                            <div>
                                {employee?.unit === null ? (
                                    <span style={{ opacity: '0.5' }}>{t('not_specified')}</span>
                                ) : (
                                    <span>{employee?.unit}</span>
                                )}
                            </div>
                        </div>
                        <Button
                            className="rounded-edges circular"
                            style={{
                                textTransform: 'uppercase',
                                fontWeight: 'bold',
                                color: 'var(--light)',
                                background: 'var(--danger)',
                                position: 'relative',
                                top: '0.2rem',
                            }}
                            onClick={() => onReturn()}
                        >
                            {t('logout')}
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>

            <SpinnerSegment loading={loading} loadingMessage={t('loading_available_processes')}>
                {!loading && (
                    <>
                        {processes.length === 0 ? (
                            <>
                                <h1 style={{ marginTop: '4rem', textAlign: 'center' }}>
                                    {' '}
                                    {t('you_dont_have_any_active_processes')}{' '}
                                </h1>
                                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '3rem' }}>
                                    <Button primary size="huge" onClick={() => fetchProcesses()}>
                                        {t('try_to_load_again')}
                                    </Button>
                                </div>
                            </>
                        ) : (
                            <>
                                {process === null && (
                                    <Container>
                                        <Grid>
                                            <Grid.Row columns="1">
                                                {processes.map((item) => (
                                                    <Grid.Column
                                                        as={Segment}
                                                        key={item.id}
                                                        style={{
                                                            paddingLeft: '1.5rem',
                                                            paddingRight: '1.5rem',
                                                            marginBottom: '1rem',
                                                            cursor: 'pointer',
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            alignItems: 'center',
                                                            justifyContent: 'space-between',
                                                        }}
                                                        onClick={() => handleProcessSelection(item)}
                                                    >
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                alignItems: 'center',
                                                            }}
                                                        >
                                                            <div style={{ marginRight: '1rem' }}>
                                                                <Icon
                                                                    name="cube-outline"
                                                                    style={{ fontSize: '3rem' }}
                                                                />
                                                            </div>
                                                            <div>
                                                                <strong style={{ fontSize: '1.5rem' }}>
                                                                    {item.title}
                                                                </strong>{' '}
                                                                <br />
                                                                <span style={{ fontSize: '1.2rem' }}>{item.note}</span>
                                                            </div>
                                                        </div>
                                                    </Grid.Column>
                                                ))}
                                            </Grid.Row>
                                        </Grid>
                                    </Container>
                                )}
                            </>
                        )}

                        {process !== null && (
                            <SpinnerSegment loading={fetchingStoredItemPositions}>
                                {!fetchingStoredItemPositions && (
                                    <>
                                        <Grid style={{ height: '90vh', background: 'var(--white)' }}>
                                            <Grid.Row columns={2} divided>
                                                <Grid.Column width="6" style={{ padding: 0 }} stretched>
                                                    <div style={{ padding: '1rem' }}>
                                                        <Button.Group basic fluid size="huge">
                                                            <Button
                                                                active={filterView === 0}
                                                                onClick={() => {
                                                                    setFilterView(0)
                                                                    setAllowedItems([])
                                                                }}
                                                                style={{
                                                                    fontWeight: filterView === 0 ? 'bold' : undefined,
                                                                }}
                                                            >
                                                                {t('categories')}
                                                            </Button>
                                                            <Button
                                                                active={filterView === 1}
                                                                onClick={() => {
                                                                    setFilterView(1)
                                                                    setAllowedItems([])
                                                                }}
                                                                style={{
                                                                    fontWeight: filterView === 1 ? 'bold' : undefined,
                                                                }}
                                                            >
                                                                {t('items')}
                                                            </Button>
                                                        </Button.Group>
                                                        <Divider />

                                                        {filterView === 0 ? (
                                                            <Card.Group key={filterView} itemsPerRow="1" stackable>
                                                                {setCategoryView(process?.allowed_items).map(
                                                                    (category) => (
                                                                        <Card
                                                                            key={category.id}
                                                                            onClick={() => {
                                                                                if (
                                                                                    !allowedItems.includes(category.id)
                                                                                ) {
                                                                                    setAllowedItems((prev) => [
                                                                                        ...prev,
                                                                                        category.id,
                                                                                    ])
                                                                                } else {
                                                                                    setAllowedItems((prev) =>
                                                                                        prev.filter(
                                                                                            (item) =>
                                                                                                item !== category.id
                                                                                        )
                                                                                    )
                                                                                }
                                                                            }}
                                                                            style={{
                                                                                textAlign: 'left',
                                                                                color: 'var(--dark)',
                                                                                fontSize: '1.2rem',
                                                                                fontWeight: 'bold',
                                                                                padding: '0.5rem',
                                                                                cursor: 'pointer',
                                                                                marginBottom: '0.2rem',
                                                                                background: allowedItems.includes(
                                                                                    category.id
                                                                                )
                                                                                    ? 'var(--light)'
                                                                                    : 'white',
                                                                                border: allowedItems.includes(
                                                                                    category.id
                                                                                )
                                                                                    ? '1px solid var(--variant3)'
                                                                                    : undefined,
                                                                            }}
                                                                        >
                                                                            <Card.Content
                                                                                style={{
                                                                                    color: 'var(--dark)',
                                                                                    display: 'flex',
                                                                                    flexDirection: 'row',
                                                                                    alignItems: 'center',
                                                                                    fontSize: '1.5rem',
                                                                                }}
                                                                            >
                                                                                <div
                                                                                    style={{
                                                                                        width: '20px',
                                                                                        height: '20px',
                                                                                        marginRight: '1rem',
                                                                                        border: '1px solid black',
                                                                                        background: `${
                                                                                            category?.color ||
                                                                                            'var(--dark)'
                                                                                        }`,
                                                                                    }}
                                                                                ></div>
                                                                                <div>{category.title}</div>
                                                                            </Card.Content>
                                                                        </Card>
                                                                    )
                                                                )}
                                                            </Card.Group>
                                                        ) : (
                                                            <Card.Group key={filterView} itemsPerRow="1" stackable>
                                                                {process?.allowed_items?.map((allowedItem) => (
                                                                    <Card
                                                                        key={allowedItem.id}
                                                                        onClick={() => {
                                                                            if (
                                                                                !allowedItems.includes(allowedItem.id)
                                                                            ) {
                                                                                setAllowedItems((prev) => [
                                                                                    ...prev,
                                                                                    allowedItem.id,
                                                                                ])
                                                                            } else {
                                                                                setAllowedItems((prev) =>
                                                                                    prev.filter(
                                                                                        (item) =>
                                                                                            item !== allowedItem.id
                                                                                    )
                                                                                )
                                                                            }
                                                                        }}
                                                                        style={{
                                                                            textAlign: 'left',
                                                                            color: 'var(--dark)',
                                                                            fontSize: '1.2rem',
                                                                            fontWeight: 'bold',
                                                                            padding: '0.5rem',
                                                                            cursor: 'pointer',
                                                                            marginBottom: '0.2rem',
                                                                            background: allowedItems.includes(
                                                                                allowedItem.id
                                                                            )
                                                                                ? 'var(--light)'
                                                                                : 'white',
                                                                            border: allowedItems.includes(
                                                                                allowedItem.id
                                                                            )
                                                                                ? '1px solid var(--dark)'
                                                                                : undefined,
                                                                        }}
                                                                    >
                                                                        <Card.Content
                                                                            style={{
                                                                                color: 'var(--dark)',
                                                                                display: 'flex',
                                                                                flexDirection: 'row',
                                                                                alignItems: 'center',
                                                                                fontSize: '1.5rem',
                                                                            }}
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    width: '20px',
                                                                                    height: '20px',
                                                                                    marginRight: '1rem',
                                                                                    border: '1px solid black',
                                                                                    background: `${
                                                                                        allowedItem?.category?.color ||
                                                                                        'var(--dark)'
                                                                                    }`,
                                                                                }}
                                                                            ></div>
                                                                            <div>
                                                                                {allowedItem.code} {allowedItem.title}
                                                                            </div>
                                                                        </Card.Content>
                                                                    </Card>
                                                                ))}
                                                            </Card.Group>
                                                        )}
                                                    </div>
                                                </Grid.Column>
                                                <Grid.Column
                                                    width="10"
                                                    style={{ padding: 0, background: 'var(--lightgrey)' }}
                                                    stretched
                                                >
                                                    <div style={{ padding: '1rem', paddingTop: 0 }}>
                                                        <div
                                                            style={{
                                                                padding: 0,
                                                                overflow: 'auto',
                                                                height: '65vh',
                                                                marginTop: '0.5rem',
                                                            }}
                                                        >
                                                            <Table celled>
                                                                <Table.Header>
                                                                    <Table.Row>
                                                                        <Table.HeaderCell
                                                                            style={{ padding: '0.5rem 1rem' }}
                                                                            width="12"
                                                                            content={t('stored_items')}
                                                                        />
                                                                        <Table.HeaderCell
                                                                            style={{
                                                                                padding: '0.5rem 1rem',
                                                                                textAlign: 'center',
                                                                            }}
                                                                            width="4"
                                                                            content={t('stock')}
                                                                        />
                                                                    </Table.Row>
                                                                </Table.Header>
                                                                <Table.Body>
                                                                    {setItemView(stockedItems)?.length === 0 && (
                                                                        <Table.Row>
                                                                            <Table.Cell
                                                                                colSpan="2"
                                                                                style={{
                                                                                    fontSize: '1.5rem',
                                                                                    textAlign: 'center',
                                                                                    fontWeight: 'bold',
                                                                                }}
                                                                            >
                                                                                {t('no_items_to_processing')}
                                                                            </Table.Cell>
                                                                        </Table.Row>
                                                                    )}
                                                                    {setItemView(stockedItems)?.map((item) => (
                                                                        <Table.Row
                                                                            key={item?.id}
                                                                            style={{
                                                                                cursor: 'pointer',
                                                                                background:
                                                                                    activeItem?.id === item?.id
                                                                                        ? 'var(--light)'
                                                                                        : 'transparent',
                                                                            }}
                                                                            onClick={() => {
                                                                                setActiveItem(item)
                                                                            }}
                                                                        >
                                                                            <Table.Cell
                                                                                style={{
                                                                                    padding: '1rem',
                                                                                    border:
                                                                                        activeItem?.id === item?.id
                                                                                            ? '2px dashed var(--variant3)'
                                                                                            : undefined,
                                                                                    borderRight:
                                                                                        activeItem?.id === item?.id
                                                                                            ? 'none'
                                                                                            : undefined,
                                                                                }}
                                                                            >
                                                                                <div
                                                                                    style={{
                                                                                        fontSize: '1.7rem',
                                                                                        fontWeight: 'bold',
                                                                                        display: 'flex',
                                                                                        flexDirection: 'row',
                                                                                        alignItems: 'center',
                                                                                        justifyContent: 'left',
                                                                                    }}
                                                                                >
                                                                                    <div
                                                                                        style={{
                                                                                            width: '20px',
                                                                                            height: '20px',
                                                                                            marginRight: '1rem',
                                                                                            border: '1px solid black',
                                                                                            background: `${
                                                                                                item?.stored_item
                                                                                                    ?.catalogue_item
                                                                                                    ?.category?.color ||
                                                                                                'var(--dark)'
                                                                                            }`,
                                                                                        }}
                                                                                    >
                                                                                        {' '}
                                                                                    </div>
                                                                                    {item.stock_number}
                                                                                </div>
                                                                                <div
                                                                                    style={{
                                                                                        marginTop: '0.5rem',
                                                                                        fontSize: '1.3rem',
                                                                                    }}
                                                                                >
                                                                                    {t('current_stock')}:{' '}
                                                                                    {item.quantity}{' '}
                                                                                    {item.stored_item?.catalogue_item
                                                                                        ?.measure_unit
                                                                                        ? ' ' +
                                                                                          item.stored_item
                                                                                              ?.catalogue_item
                                                                                              ?.measure_unit
                                                                                              ?.abbreviation
                                                                                        : ''}
                                                                                </div>
                                                                            </Table.Cell>
                                                                            <Table.Cell
                                                                                textAlign="center"
                                                                                style={{
                                                                                    border:
                                                                                        activeItem?.id === item?.id
                                                                                            ? '2px dashed var(--variant3)'
                                                                                            : undefined,
                                                                                    borderLeft:
                                                                                        activeItem?.id === item?.id
                                                                                            ? 'none'
                                                                                            : undefined,
                                                                                }}
                                                                            >
                                                                                <div style={{ fontSize: '1.5rem' }}>
                                                                                    <strong>
                                                                                        {calculatePackages(item)}
                                                                                    </strong>
                                                                                </div>
                                                                            </Table.Cell>
                                                                        </Table.Row>
                                                                    ))}
                                                                </Table.Body>
                                                            </Table>
                                                        </div>
                                                    </div>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>

                                        {activeItem !== null && (
                                            <div
                                                style={{
                                                    position: 'fixed',
                                                    bottom: 20,
                                                    right: 20,
                                                    background: 'white',
                                                    paddingRight: '2rem',
                                                    paddingBottom: '2rem',
                                                }}
                                            >
                                                <Button.Group size="huge" floated="right">
                                                    <Button
                                                        loading={processingReturn}
                                                        disabled={processing || processingReturn}
                                                        content={t('return_package').toUpperCase()}
                                                        style={{
                                                            marginRight: '2rem',
                                                            padding: '40px 100px',
                                                            color: 'var(--white)',
                                                            background: 'var(--danger)',
                                                        }}
                                                        onClick={() => handlePositionReturn(activeItem, true)}
                                                    />
                                                    <Button
                                                        primary
                                                        loading={processing}
                                                        disabled={
                                                            !isValidAction(activeItem) || processing || processingReturn
                                                        }
                                                        content={t('unload_package').toUpperCase()}
                                                        style={{
                                                            padding: '40px 100px',
                                                        }}
                                                        onClick={() => handlePositionSelection(activeItem, true)}
                                                    />
                                                </Button.Group>
                                            </div>
                                        )}
                                    </>
                                )}
                            </SpinnerSegment>
                        )}
                    </>
                )}
            </SpinnerSegment>
        </Container>
    )
}

export default WarehouseManagement
