import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import moment from 'moment'
import '@store/languages'
// components
import Icon from '@components/Icon'
import { Grid, Divider, Button, Container } from 'semantic-ui-react'
// component specific
import MonthlyCCStats from './MonthlyCCStats'
import CategoryStats from './CategoryStats'
// import AssignedToStats from './AssignedToStats';

const CostCenterStatistic = ({ isDetail, filters, data, setVisible }) => {
    const { t } = useTranslation()
    const dateFormat = useSelector((state) => state.date_format)
    const [view, setView] = useState(1)

    return (
        <Container fluid>
            <Grid stackable>
                <Grid.Row style={{ padding: 0, marginBottom: '1rem' }} columns={2}>
                    <Grid.Column>
                        <Button.Group basic size="tiny" style={{ marginLeft: '1.5rem' }}>
                            <Button active={view === 1} onClick={() => setView(1)}>
                                {t('type')}
                            </Button>
                            <Button active={view === 2} onClick={() => setView(2)}>
                                {t('categories')}
                            </Button>
                            {/* <Button primary={view === 3} onClick={() => setView(3)}>{t('assigned_to')}</Button> */}
                        </Button.Group>
                    </Grid.Column>
                    <Grid.Column textAlign="right">
                        <Icon
                            name="funnel-outline"
                            style={{ marginRight: '1rem', cursor: 'pointer' }}
                            onClick={() => setVisible(true)}
                        />
                        {/* <Icon name="download-outline" style={{ marginRight: "1rem", cursor: "not-allowed" }}/>  */}
                        <Button size="tiny" simple="true" style={{ cursor: 'default' }}>
                            <Icon name="calendar-outline" style={{ marginRight: '0.5rem' }} />
                            <span style={{ position: 'relative', top: '-0.1rem' }}>
                                <strong>
                                    <span style={{ textTransform: 'capitalize' }}>
                                        {moment(filters.date_from).format(dateFormat)}
                                    </span>
                                    {' - '}
                                    <span style={{ textTransform: 'capitalize' }}>
                                        {moment(filters.date_to).format(dateFormat)}
                                    </span>{' '}
                                    - {filters.currency || 'EUR'}
                                </strong>
                            </span>
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            {view === 1 && <MonthlyCCStats isDetail={isDetail} filters={filters} listData={data} />}
            {view === 2 && <CategoryStats isDetail={isDetail} filters={filters} listData={data} />}
            {/* {view === 3 && <AssignedToStats isDetail={isDetail} filters={filters} listData={data}/>} */}
            <Divider />
        </Container>
    )
}

export default CostCenterStatistic
