import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import moment from "moment";
// store
import { API } from "@store/config";
import { requests } from "@helpers/requests";
// components
import Icon from '@components/Icon';
import {Container, Card} from 'semantic-ui-react';
import SpinnerSegment from '@components/SpinnerSegment';

const StatsRow = ({ data, filters, params }) => {
    const { t } = useTranslation()
    // const currentUser = useSelector(state => state.user)
    const dateFormat = useSelector(state => state.date_format)

    // eslint-disable-next-line
    const [loading, setLoading] = useState(true)
    const [report, setReport] = useState(null)

    const dateFrom = filters.start_date
    const dateTo = filters.end_date


    const calculateDays = () => {
        if (dateFrom !== "" && dateTo !== ""){
            const moment_date_from = moment(dateFrom)
            const moment_date_to = moment(dateTo)
            const days = moment.duration(moment_date_to.diff(moment_date_from, 'days'))
            return days + 1;
        }

        return t('all')
    }

    useEffect(() => {
        async function fetchStats() {
            setLoading(true)

            let findSearchParam = params.split('?')
            if (findSearchParam.length === 1) {
                // eslint-disable-next-line
                params = params.replace('&', '?')
                params = params.replace('profile', 'profile_id')
            }

            const request = await requests.get(`${API.REPORTS}timesheets/review_stats/stats/${params}`)
            if (request.status === 200) {
                setReport(request.response)
            }

            setLoading(false)
        }

        fetchStats()
        // eslint-disable-next-line
    }, [data/*, filters*/])

    return (
        <SpinnerSegment loading={loading} loadingMessage={t('loading_statistics')}>
            <Container fluid>
                <Card.Group stackable doubling itemsPerRow={5}>
                    <Card style={{ borderRadius: 0 }}>
                        <Card.Content>
                            <div style={{ display: "flex", marginBottom: "2rem" }}>
                                <div style={{ textAlign: "left", width: "30%" }}>
                                    <Icon name="calendar-outline" style={{ fontSize: "3rem", fontWeight: "bold", textAlign: "left" }}/>
                                </div>
                                <div style={{ textAlign: "right", fontWeight: "bold", width: "70%", fontSize: "1.25rem" }}>
                                    { calculateDays() }
                                </div>
                            </div>

                            <div style={{ position: "absolute", width: "90%", display: "flex", bottom: "1rem", marginTop: "1rem", textTransform: "uppercase", color: "var(--dark)", opacity:"0.8" }}>
                                <div style={{ width: "40%" }}>{ t("total_days") }</div>
                                <div style={{ width: "60%", textAlign: "right" }}>
                                    { (dateFrom !== "" && dateTo !== "") && 
                                        <small>{ moment(dateFrom).format(dateFormat) } - { moment(dateTo).format(dateFormat) }</small>
                                    }
                                </div>
                            </div>
                        </Card.Content>
                    </Card>
                    <Card style={{ borderRadius: 0 }}>
                        <Card.Content>
                            <div style={{ display: "flex", marginBottom: "2rem" }}>
                                <div style={{ textAlign: "left", width: "30%" }}>
                                    <Icon name="list-outline" style={{ fontSize: "3rem", fontWeight: "bold", textAlign: "left" }}/>
                                </div>
                                <div style={{ textAlign: "right", fontWeight: "bold", width: "70%", fontSize: "1.25rem" }}>
                                    { report?.total_records || 0 }
                                </div>
                            </div>

                            <div style={{ position: "absolute", bottom: "1rem", marginTop: "1rem", textTransform: "uppercase", color: "var(--dark)", opacity:"0.8" }}>
                                { t("total_records") }
                            </div>
                        </Card.Content>
                    </Card>
                    <Card style={{ borderRadius: 0 }}>
                        <Card.Content>
                            <div style={{ display: "flex", marginBottom: "2rem" }}>
                                <div style={{ textAlign: "left", width: "30%" }}>
                                    <Icon name="timer-outline" style={{ fontSize: "3rem", fontWeight: "bold", textAlign: "left" }}/>
                                </div>
                                <div style={{ textAlign: "right", fontWeight: "bold", width: "70%", fontSize: "1.25rem" }}>
                                    { report?.total_hours || 0 } <span style={{ textTransform: "lowercase" }}>{ t("hours_shortcut") }.</span> / { report?.total_piece_work_count || 0 }x<br/>
                                </div>
                            </div>

                            <div style={{ position: "absolute", bottom: "1rem", marginTop: "1rem", textTransform: "uppercase", color: "var(--dark)", opacity:"0.8" }}>
                                { t("total_hours__amount") }
                            </div>
                        </Card.Content>
                    </Card>
                    <Card style={{ borderRadius: 0 }}>
                        <Card.Content>
                            <div style={{ display: "flex", marginBottom: "2rem" }}>
                                <div style={{ textAlign: "left", width: "30%" }}>
                                    <Icon name="checkmark-done-outline" style={{ fontSize: "3rem", fontWeight: "bold", textAlign: "left" }}/>
                                </div>
                                <div style={{ textAlign: "right", fontWeight: "bold", width: "70%", fontSize: "1.25rem" }}>
                                    { report?.total_approved_hours || 0 } <span style={{ textTransform: "lowercase" }}>{ t("hours_shortcut") }.</span> / { report?.total_approved_piece_work_count || 0 }x
                                </div>
                            </div>

                            <div style={{ position: "absolute", bottom: "1rem", marginTop: "1rem", textTransform: "uppercase", color: "var(--dark)", opacity:"0.8" }}>
                                { t("total_approved_hours__amount") }
                            </div>
                        </Card.Content>
                    </Card>
                    <Card style={{ borderRadius: 0 }}>
                        <Card.Content>
                            <div style={{ display: "flex", marginBottom: "2rem" }}>
                                <div style={{ textAlign: "left", width: "30%" }}>
                                    <Icon name="close-outline" style={{ fontSize: "3rem", fontWeight: "bold", textAlign: "left" }}/>
                                </div>
                                <div style={{ textAlign: "right", fontWeight: "bold", width: "70%", fontSize: "1.25rem" }}>
                                    { report?.total_disapproved_hours || 0 } <span style={{ textTransform: "lowercase" }}>{ t("hours_shortcut") }.</span> / { report?.total_disapproved_piece_work_count || 0 }x
                                </div>
                            </div>

                            <div style={{ position: "absolute", bottom: "1rem", marginTop: "1rem", textTransform: "uppercase", color: "var(--dark)", opacity:"0.8" }}>
                                { t("total_refused_hours__amount") }
                            </div>
                        </Card.Content>
                    </Card>
                </Card.Group>
            </Container>
        </SpinnerSegment>
    );
};

export default StatsRow;