export const allowedModules = {
    COMMON: ['common'],
    USERS: ['common', 'users'],
    PROFILE: ['common', 'users'],
    CONTRACTS: ['common', 'employees', 'contracts'],
    TERMINATIONS: ['common', 'employees', 'contracts'],
    COMPANY: ['common', 'company'],
    EMPLOYEES: ['common', 'employees'],
    HIRING_ROOMS: ['common', 'candidates', 'employees', 'company'],
    CANDIDATES: ['common', 'candidates'],
    BENEFITS: ['common', 'benefits'],
    TEAMS: ['common', 'teams', 'employees'],
    PROJECTS: ['common', 'teams', 'employees', 'projects'],
    INVOICES: [],
    PAYMENTS: [],
    TIMESHEETS: ['common', 'timesheets', 'employees'],
    JOBS: ['common', 'jobs', 'employees'],
    POSITIONS: ['common', 'jobs'],
    ORDERS: ['common', 'orders'],
    LEADS: [],
    SETTINGS: ['common'],
    ACCOUNTS: ['common', 'accounts', 'employees', 'teams'],
    REPORTS: ['common'],
    COURSES: ['common', 'courses', 'employees'],
    CASES: ['common', 'cases', 'employees'],
    ATTENDANCE: ['common', 'attendance', 'employees', 'company'],
    CONTACTS: ['common', 'contacts'],
    APPROVALS: ['common', 'approvals', 'employees'],
    ASSETS: ['common', 'assets', 'employees'],
    WAREHOUSING: ['common', 'assets', 'orders', 'employees'],
    COST_CENTERS: ['common', 'cost_centers', 'employees'],
    ONBOARDING: ['common', 'onboarding', 'employees'],
    SUPPLIERS: ['common', 'accounts', 'employees'],
    GDPRS: ['common', 'gdprs'],
    FORECASTS: ['common', 'forecasts'],
    GDPRS_AGREEMENTS: ['common'],
    VEHICLES: ['common', 'vehicles'],
    TASKS: ['common', 'tasks'],
    LOGISTICS: ['common', 'logistics'],
    ACCOMMODATIONS: ['common', 'accommodations'],
    PAYROLLS: [],
    POSTING_STAFF: ['common', 'abroads'],
}
