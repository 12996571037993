import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
// store
import { API } from '@store/config'
import { icons } from '@store/icons'
import { routes } from '@store/routes'
import { requests } from '@helpers/requests'
import { tzDateTime } from '@helpers/dates'
// components
import Icon from '@components/Icon'
import { Card, Header, Grid } from 'semantic-ui-react'
import SpinnerSegment from '@components/SpinnerSegment'

const UserTodayStatistics = ({ user, today }) => {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)

    const initialState = {
        projects: 0,
        assigned_cases: 0,
        issues: 0,
        pending_approvals: 0,
        attendance_hours: 0,
        timesheet_hours: 0,
    }

    const [stats, setStats] = useState(initialState)

    useEffect(() => {
        async function fetchUserStats() {
            const date = moment(today).format('YYYY-MM-DD')
            setLoading(true)
            const fetchActiveProjects = await requests.get(API.REPORTS + 'dashboard/user_total_active_projects/')
            const fetchAssignedCases = await requests.get(API.REPORTS + 'dashboard/user_total_assigned_cases/')
            const fetchOpenedIssues = await requests.get(API.REPORTS + 'dashboard/user_total_opened_issues/')
            const fetchPendingApprovals = await requests.get(API.REPORTS + 'dashboard/user_total_pending_approvals/')
            const fetchAttendanceWorkHours = await requests.get(
                API.REPORTS + `dashboard/user_total_attendance_work_hours/?date_from=${date}&date_to=${date}`
            )
            const fetchTimesheetWorkHours = await requests.get(
                API.REPORTS + `dashboard/user_total_timesheet_work_hours/?date_from=${date}&date_to=${date}`
            )

            let totals = initialState

            if (fetchActiveProjects.status === 200)
                totals['projects'] = fetchActiveProjects.response.total_active_projects || 0
            if (fetchAssignedCases.status === 200)
                totals['assigned_cases'] = fetchAssignedCases.response.total_assigned_cases || 0
            if (fetchOpenedIssues.status === 200) totals['issues'] = fetchOpenedIssues.response.total_opened_issues || 0
            if (fetchPendingApprovals.status === 200)
                totals['pending_approvals'] = fetchPendingApprovals.response.total_pending_approvals || 0
            if (fetchAttendanceWorkHours.status === 200)
                totals['attendance_hours'] = fetchAttendanceWorkHours.response.total_attendance_hours || 0
            if (fetchTimesheetWorkHours.status === 200)
                totals['timesheet_hours'] = fetchTimesheetWorkHours.response.total_timesheet_hours || 0

            setStats(totals)
            setLoading(false)
        }

        fetchUserStats()
        // eslint-disable-next-line
    }, [])

    const StatView = ({ icon, text, value, to }) => {
        return (
            <div className="dashboard-stats-view">
                <Link to={to}>
                    <div
                        className="stats-view"
                        style={{ display: 'flex', marginBottom: '2.2rem', color: 'var(--dark)' }}
                    >
                        <div style={{ textAlign: 'left', width: '30%' }}>
                            <Icon name={icon} style={{ fontSize: '2rem', fontWeight: 'bold', textAlign: 'left' }} />
                        </div>
                        <div style={{ textAlign: 'right', fontWeight: 'bold', width: '70%', fontSize: '1.15rem' }}>
                            <span style={{ textTransform: 'lowercase' }}>{value}</span>
                        </div>
                    </div>

                    <div
                        style={{
                            position: 'absolute',
                            width: '90%',
                            display: 'flex',
                            bottom: '0.5rem',
                            textTransform: 'uppercase',
                            color: 'var(--dark)',
                            opacity: '0.8',
                        }}
                    >
                        <div style={{ width: '100%', fontSize: '0.9rem' }}>{text}</div>
                    </div>
                </Link>
            </div>
        )
    }

    return (
        <SpinnerSegment
            loading={loading}
            loadingMessage={t('loading_users_daily_stats')}
            marginBottom={loading ? '1rem' : 0}
        >
            <Grid columns={3} divided stackable>
                <Grid.Row style={{ padding: 0 }}>
                    <Grid.Column style={{ borderBottom: '1px solid #e1e1e1' }}>
                        <StatView
                            to={routes.PROJECT_LIST}
                            icon={icons.PROJECTS}
                            text={t('active_projects')}
                            value={stats.projects}
                        />
                    </Grid.Column>
                    <Grid.Column style={{ borderBottom: '1px solid #e1e1e1' }}>
                        <StatView
                            to={routes.PROJECT_LIST}
                            icon="list-outline"
                            text={t('opened_issues')}
                            value={stats.issues}
                        />
                    </Grid.Column>
                    <Grid.Column style={{ borderBottom: '1px solid #e1e1e1' }}>
                        <StatView
                            to={routes.CASES}
                            icon={icons.CASES}
                            text={t('assigned_cases')}
                            value={stats.assigned_cases}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ padding: 0 }}>
                    <Grid.Column>
                        <StatView
                            to={routes.APPROVALS}
                            icon={icons.APPROVALS}
                            text={t('pending_approvals')}
                            value={stats.pending_approvals}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <StatView
                            to={routes.TIMESHEETS}
                            icon={icons.TIMESHEETS}
                            text={t('timesheet_hours')}
                            value={`${stats.timesheet_hours} ${t('hours_shortcut')}.`}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <StatView
                            to={routes.ATTENDANCE}
                            icon={icons.TIMESHEETS}
                            text={t('attendance_hours')}
                            value={`${stats.attendance_hours} ${t('hours_shortcut')}.`}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </SpinnerSegment>
    )
}

const UserDailyStats = ({ user, dateFormat, today }) => {
    const { t } = useTranslation()

    return (
        <Card fluid>
            <Card.Content>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0.5rem' }}>
                    <div>
                        <Header as="h3" style={{ marginBottom: '0.1rem' }}>
                            {t('greetings_hi')},{' '}
                            <span style={{ textTransform: 'capitalize' }}>
                                {user?.profile?.id ? user.profile?.firstname || user.profile?.name : user.username}!
                            </span>
                        </Header>
                        <span>{t('here_is_your_todays_reports')}</span>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div>
                            <Icon name="time-outline" style={{ fontSize: '3rem', marginRight: '1rem' }} />
                        </div>
                        <div>
                            <Header
                                as="h3"
                                content={tzDateTime(today).format('HH:mm')}
                                style={{ marginBottom: '0.1rem' }}
                            />
                            <span>{tzDateTime(today).format(dateFormat)}</span>
                        </div>
                    </div>
                </div>

                <UserTodayStatistics user={user} today={today} />
            </Card.Content>
        </Card>
    )
}

export default UserDailyStats
