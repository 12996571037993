import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// store
import { icons } from '@store/icons';
import { API } from '@store/config';
import { requests } from '@helpers/requests';
// components
import Icon from '@components/Icon';
import CanView from '@components/perms/CanView';
import BreadcrumbNav from '@components/BreadcrumbNav';
import SpinnerSegment from '@components/SpinnerSegment';
import { Container, Button, Divider, Dropdown } from 'semantic-ui-react';
// model specific comps
import StockList from './views/StockList';
import ProcessList from './views/ProcessList';

const Warehousing = () => {
    const { t } = useTranslation()
    const [view, setView] = useState(0) // stocks (0), processes (1)

    const [loading, setLoading] = useState(true)
    const [selectedWarehouse, setSelectedWarehouse] = useState("")
    const [warehouses, setWarehouses] = useState([])

    useEffect(() => {
        async function fetchWarehouses() {
            setLoading(true)
            const request = await requests.get(API.ASSETS + "warehouses/?query={id, title}")
            if (request.status === 200) {
                setWarehouses(request.response)
            }

            setLoading(false)
        }

        fetchWarehouses()
    }, [])

    return (
        <CanView permissions={['assets.c_view_items']} redirect>
            <SpinnerSegment>
                { !loading &&
                    <>
                        <BreadcrumbNav items={[
                            { 'name': t('warehousing'), 'icon': icons.ASSETS, 'href': "" }
                        ]}/>
                        <Container fluid>
                            <Button.Group basic size="small" className='custom-filter-button-group' style={{ borderRadius: '0' }}>
                                <Dropdown 
                                    simple
                                    as={Button}
                                    text={`${t('stocks')}: ${selectedWarehouse !== "" ? selectedWarehouse.title : t('all')}`}
                                    className={ view === 0 && "button-active active-btn-background" }
                                    style={{ textAlign: 'center' }}
                                >
                                    <Dropdown.Menu style={{ borderRadius: "0" }}>
                                        <Dropdown.Item 
                                            active={selectedWarehouse === ""} 
                                            onClick={() => {
                                                setView(0)
                                                setSelectedWarehouse("")
                                            }}
                                        >
                                            { t('all') }
                                        </Dropdown.Item>
                                        <Dropdown.Divider/>
                                        { warehouses.map(item => (
                                            <Dropdown.Item 
                                                active={selectedWarehouse.id === item.id} 
                                                onClick={() => {
                                                    setView(0)
                                                    setSelectedWarehouse(item)
                                                }}
                                            >
                                                { item.title }
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                                <CanView permissions={['assets.c_view_warehouse_processes']}>
                                    <Button active={view === 1} onClick={() => setView(1)}>
                                        <Icon name={icons.ATTENDANCE} style={{ marginRight: "0.5rem" }}/> 
                                        <span style={{ position: "relative", top: "-0.1rem" }}>
                                            { t('processes') }
                                        </span>
                                    </Button>
                                </CanView>
                            </Button.Group>
                            <Divider style={{ marginBottom: 0 }}/>
                        </Container>

                        { view === 0 && <StockList selectedWarehouse={selectedWarehouse} warehouses={warehouses}/> }
                        { view === 1 && <ProcessList warehouses={warehouses}/> }
                    </>
                }
            </SpinnerSegment>
        </CanView>
    );
};

export default Warehousing;