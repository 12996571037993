import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// store
import { routes } from '@routes';
import { API } from '@store/config';
import { requests } from '@helpers/requests';
// components
import { Container, Button, Grid, Divider } from 'semantic-ui-react';
import BreadcrumbNav from '@components/BreadcrumbNav';
// module specific components
import CourseRecords from './views/CourseRecords';
import CourseEvents from './views/CourseEvents';

const Courses = () => {
    const { t } = useTranslation();

    const breadcrumb_items = [{ name: t('courses'), icon: '', href: '' }];

    const [employees, setEmployees] = useState([]);
    const employeeOptions = employees.map((item) => ({ key: item.id, value: item.id, text: item.fullname }));

    const [view, setView] = useState(1);

    const setViewType = (to, viewNumber) => {
        setView(viewNumber);
    };

    useEffect(() => {
        async function fetchEmployees() {
            const request = await requests.get(API.EMPLOYEES + '?only_basic_info=true');

            if (request.status === 200) {
                setEmployees(request.response);
            }
        }

        fetchEmployees();
    }, []);

    return (
        <>
            <BreadcrumbNav items={breadcrumb_items} />
            <Container fluid>
                <Grid stackable>
                    <Grid.Row style={{ padding: 0 }} columns={2}>
                        <Grid.Column style={{ padding: 0 }}>
                            <Button.Group basic size="small" style={{ borderRadius: 0 }} className='custom-filter-button-group'>
                                <Button
                                    active={view === 1}
                                    style={{ fontWeight: view === 1 ? 'bold' : 'normal' }}
                                    content={t('records')}
                                    onClick={() => setViewType(routes.COURSES, 1)}
                                />
                                <Button
                                    active={view === 2}
                                    style={{ fontWeight: view === 2 ? 'bold' : 'normal' }}
                                    content={t('events')}
                                    onClick={() => setViewType(routes.COURSES + "events/", 2)}
                                />
                            </Button.Group>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Divider />
            </Container>
            {view === 1 ? <CourseRecords employees={employeeOptions} /> 
            : <CourseEvents employees={employeeOptions} />
            }
        </>
    );
};

export default Courses;
