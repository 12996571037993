import React, { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router'
// store
import { API } from '@store/config'
import { icons } from '@icons'
import { routes } from '@routes'
import { getRequest, createRequest, updateRequest } from '@services/ServiceCommon'
// components
import Icon from '@components/Icon'
import Loading from '@components/general/Loading'
import BreadcrumbNav from '@components/BreadcrumbNav'
import { Container, Form, Grid, Button, Header, Segment, Message } from 'semantic-ui-react'
import ContractsFieldGroup from '@components/forms/jobs/ContractsFieldGroup'
import JobOfferRequirements from '../components/forms/views/JobOfferRequirements'
import BaseInformation from '../components/forms/views/BaseInformation'
import OfferConfiguration from '../components/forms/views/OfferConfiguration'
import CanView from '@components/perms/CanView'

const JobOfferForm = () => {
    const { t } = useTranslation()
    const params = useParams()
    // const [errors, setErrors] = useState([]);

    const [salary, setSalary] = useState({
        id: 0,
        salary_type: '',
        min: '',
        max: '',
        currency: 'EUR',
        additional_info: '',
        variable_remuneration_ratio: 0,
        variable_remuneration_min_value: 0,
        variable_remuneration_max_value: 0,
        bonuses: [],
    })

    const [contract, setContract] = useState({
        id: 0,
        contract_type: '',
        work_schedule: '',
        work_shift: '',
        seniority_level: '',
        work_level: '',
        salary: 0,
    })

    const [workplace, setWorkplace] = useState({
        id: 0,
        address: 0,
        workplace_type: '',
        street: '',
        city: '',
        postcode: '',
        country: '',
        state: '',
        addressLine: '',
    })

    const [requirements, setRequirements] = useState({
        id: 0,
        educations: [],
        drivers_licences: [],
        citizenships: [],
        eligibilities: [],
        age_lower: null,
        age_higher: null,
        gender: '',
        specialization: '',
        practice_years: 1,
    })

    const [jobOffer, setJobOffer] = useState({
        id: 0,
        job_position: null,
        workplace: null,
        recruitment: null,
        job_contract: null,
        job_requirement_list: null,

        //----
        is_login_required: false,
        title: '',
        description: '',
        boarding_when: '',
        note_for_candidate: '',
        number_of_openings: 1,
        is_cv_required: false,
        in_language: 1,
        valid_for_graduate: false,
        valid_for_student: false,
        valid_for_disabled_person: false,
        contact_person: '',
        contact_email: '',
        contact_phone: '',
    })

    const breadcrumb_items = [
        // Put: name, icon and href data inside to create breadcrumb item
        { name: t('jobs'), icon: icons.JOBS, href: routes.JOBS },
        { name: params.id === 0 ? t('create_job_offer') : jobOffer.title, icon: 'file', href: '' },
    ]

    const setJobsValues = (response, job_position, id) => {
        if (response.job_position?.id && id === undefined) {
            id = response.id
        } else if (id === undefined) {
            id = 0
        }

        setJobOffer({
            ...jobOffer,
            id: id,
            title: response.title,
            is_login_required: response.is_login_required ? response.is_login_required : false,
            description: response.description ? response.description : '',
            boarding_when: response.boarding_when ? response.boarding_when : '',
            note_for_candidate: response.note_for_candidate ? response.note_for_candidate : '',
            number_of_openings: response.number_of_openings ? response.number_of_openings : 0,
            is_cv_required: response.is_cv_required ? response.is_cv_required : false,
            in_language: response.in_language ? response.in_language.toString() : '1',
            valid_for_graduate: response.valid_for_graduate ? response.valid_for_graduate : false,
            valid_for_student: response.valid_for_student ? response.valid_for_student : false,
            valid_for_disabled_person: response.valid_for_disabled_person ? response.valid_for_disabled_person : false,
            contact_person: response.contact_person ? response.contact_person : '',
            contact_email: response.contact_email ? response.contact_email : '',
            contact_phone: response.contact_phone ? response.contact_phone : '',
            job_position: job_position ? job_position : null,
            workplace: response.workplace ? response.workplace.id : null,
            job_contract: response.job_contract ? response.job_contract.id : null,
            recruitment: response.recruitment ? response.recruitment.id : null,
        })

        const address = response?.workplace?.address
        const workplaceData = response?.workplace
        setWorkplace({
            id: workplaceData?.id,
            address: address ? address?.id : 0,
            workplace_type: workplaceData?.workplace_type ? workplaceData?.workplace_type.toString() : '',
            street: address ? address?.street : '',
            city: address ? address?.city : '',
            postcode: address ? address?.postcode : '',
            country: address ? address?.country : '',
            state: address ? address?.state : '',
            addressLine: address ? address?.address_line : '',
        })

        const requirementList = response.job_requirement_list
        setRequirements({
            id: requirementList?.id ? requirementList?.id : 0,
            educations: requirementList?.educations ? requirementList?.educations : [],
            drivers_licences: requirementList?.drivers_licences ? requirementList?.drivers_licences : [],
            citizenships: requirementList?.citizenships ? requirementList?.citizenships : [],
            eligibilities: requirementList?.eligibilities ? requirementList?.eligibilities : [],
            age_lower: requirementList?.age_lower ? requirementList?.age_lower : '',
            age_higher: requirementList?.age_higher ? requirementList?.age_higher : '',
            gender: requirementList?.gender ? requirementList?.gender : '',
            specialization: requirementList?.specialization ? requirementList?.specialization : '',
            practice_years: requirementList?.practice_years ? requirementList?.practice_years : '',
        })

        const salaryData = response?.job_contract?.salary
        const job_contract = response?.job_contract

        setSalary({
            id: salaryData?.id ? salaryData?.id : 0,
            salary_type: salaryData?.salary_type ? salaryData?.salary_type?.toString() : '',
            min: salaryData?.min ? salaryData?.min : '',
            max: salaryData?.max ? salaryData?.max : '',
            currency: salaryData?.currency ? salaryData?.currency?.toString() : '',
            additional_info: salaryData?.additional_info ? salaryData?.additional_info : '',
            variable_remuneration_ratio:
                salaryData?.variable_remuneration_ratio !== '' ? salaryData?.variable_remuneration_ratio : null,
            variable_remuneration_min_value:
                salaryData?.variable_remuneration_min_value !== '' ? salaryData?.variable_remuneration_min_value : null,
            variable_remuneration_max_value:
                salaryData?.variable_remuneration_max_value !== '' ? salaryData?.variable_remuneration_max_value : null,
        })

        setContract({
            id: job_contract?.id ? job_contract?.id : 0,
            contract_type: job_contract?.contract_type ? job_contract?.contract_type?.toString() : '',
            work_schedule: job_contract?.work_schedule ? job_contract?.work_schedule?.toString() : '',
            work_shift: job_contract?.work_shift ? job_contract?.work_shift?.toString() : '',
            seniority_level: job_contract?.seniority_level ? job_contract?.seniority_level?.toString() : '',
            work_level: job_contract?.work_level ? job_contract?.work_level?.toString() : '',
            salary: salaryData ? salaryData?.id : 0,
        })
    }

    useEffect(() => {
        async function fetchData() {
            setIsLoading(true)
            const result = await getRequest(API.JOB_OFFERS + params.id + '/')

            if (result.status === 403) {
                history.push(routes.DASHBOARD)
                return
            }

            if (result.status === 200) {
                // update jobOffer
                setJobsValues(result.response, result.response?.job_position?.id, result.response?.id)
            }
            setIsLoading(false)
        }

        if (params.id) {
            fetchData()
        }
        // eslint-disable-next-line
    }, [params.id])

    // Form states:
    // const [isProcessing, setIsProcessing] = useState(false);
    const [isLoading, setIsLoading] = useState(false)

    // eslint-disable-next-line
    const [isProcessing, setIsProcessing] = useState(false)
    const [errors, setErrors] = useState([])
    const history = useHistory()

    // visibility states:
    const [contractView, setContractView] = useState(false)
    const [requirementsView, setRequirementsView] = useState(false)
    const [configurationView, setConfigurationView] = useState(false)

    const handleSubmit = async () => {
        setErrors([])
        setIsProcessing(true)

        let salaryData = {
            salary_type: salary.salary_type || null,
            currency: salary.currency || 'EUR',
            variable_remuneration_ratio: salary.variable_remuneration_ratio,
            variable_remuneration_min_value: salary.variable_remuneration_min_value,
            variable_remuneration_max_value: salary.variable_remuneration_max_value,
            additional_info: salary.additional_info,
        }

        if (salary.min !== '') {
            salaryData['min'] = salary.min
        }
        if (salary.max !== '') {
            salaryData['max'] = salary.max
        }

        let contractData = {
            contract_type: contract.contract_type || null,
            work_schedule: contract.work_schedule || null,
            work_shift: contract.work_shift || null,
            seniority_level: contract.seniority_level || null,
            work_level: contract.work_level || '',
        }

        // create or update job offer:
        let request = null

        let data = {
            title: jobOffer.title,
            description: jobOffer.description,
            job_position: jobOffer.job_position,
            recruitment: jobOffer.recruitment,
            is_login_required: jobOffer.is_login_required,
            boarding_when: jobOffer.boarding_when,
            note_for_candidate: jobOffer.note_for_candidate,
            number_of_openings: jobOffer.number_of_openings,
            is_cv_required: jobOffer.is_cv_required,
            in_language: parseInt(jobOffer.in_language),
            valid_for_student: jobOffer.valid_for_student,
            valid_for_disabled_person: jobOffer.valid_for_disabled_person,
            valid_for_graduate: jobOffer.valid_for_graduate,
            contact_email: jobOffer.contact_email,
            contact_phone: jobOffer.contact_phone,
            contact_person: jobOffer.contact_person,

            workplace: workplace?.id ? workplace?.id : null,
            // to be created using RESTQL
            job_contract: {
                contract_type: contractData.contract_type !== '' ? contractData.contract_type : null,
                work_schedule: contractData.work_schedule,
                work_shift: contractData.work_shift,
                seniority_level: contractData.seniority_level ? contractData.seniority_level : null,
                work_level: contractData.work_level || 1,
                salary: {
                    salary_type: salaryData.salary_type !== '' ? salaryData.salary_type : null,
                    min: salaryData.min,
                    max: salaryData.max,
                    currency: salaryData.currency,
                    additional_info: salaryData.additional_info,
                    variable_remuneration_ratio:
                        salaryData.variable_remuneration_ratio !== '' ? salaryData.variable_remuneration_ratio : null,
                    variable_remuneration_min_value:
                        salaryData.variable_remuneration_min_value !== ''
                            ? salaryData.variable_remuneration_min_value
                            : null,
                    variable_remuneration_max_value:
                        salaryData.variable_remuneration_max_value !== ''
                            ? salaryData.variable_remuneration_max_value
                            : null,
                },
            },
            job_requirement_list: {
                educations: requirements.educations,
                drivers_licences: requirements.drivers_licences,
                citizenships: requirements.citizenships,
                eligibilities: requirements.eligibilities,
                age_lower: requirements.age_lower !== '' ? requirements.age_lower : null,
                age_higher: requirements.age_higher !== '' ? requirements.age_higher : null,
                gender: requirements.gender,
                specialization: requirements.specialization,
                practice_years: requirements.practice_years !== '' ? requirements.practice_years : null,
            },
        }

        if (jobOffer.id === 0) {
            // create
            request = await createRequest(API.JOB_OFFERS, data)
        } else {
            // update
            request = await updateRequest(API.JOB_OFFERS + jobOffer.id + '/', data)
        }

        if (request.status === 200 || request.status === 201) {
            // OK
            history.push(routes.JOBS)
        } else if (request.status === 400) {
            // BAD REQUEST
            // handle validations
            let errorList = []
            if (request.response?.recruitment) {
                errorList.push(request.response?.recruitment[0])
            }

            if (request.response?.job_requirement_list?.age_higher) {
                errorList.push(request.response?.job_requirement_list?.age_higher[0])
            }

            if (request.response?.job_requirement_list?.age_lower) {
                errorList.push(request.response?.job_requirement_list?.age_lower[0])
            }

            if (request.response?.job_requirement_list?.practice_years) {
                errorList.push(request.response?.job_requirement_list?.practice_years[0])
            }

            if (request.response?.job_contract?.salary?.min) {
                errorList.push(request.response?.job_contract?.salary?.min[0])
            }

            if (request.response?.job_contract?.salary?.max) {
                errorList.push(request.response?.job_contract?.salary?.max[0])
            }

            setErrors(errorList)
        }

        setIsProcessing(false)
    }

    return (
        <CanView permissions={['jobs.c_manage_job_offers']} redirect>
            <Fragment>
                <BreadcrumbNav items={breadcrumb_items} />
                {isLoading ? (
                    <Loading />
                ) : (
                    <Container fluid>
                        <Grid>
                            <Grid.Row style={{ padding: 0 }}>
                                <Grid.Column computer={16} tablet={16} mobile={16} style={{ padding: 0 }}>
                                    {isLoading ? (
                                        <Loading />
                                    ) : (
                                        <Form
                                            onSubmit={() => handleSubmit()}
                                            style={{
                                                background: 'var(--white)',
                                                border: '1px solid var(--light-grey)',
                                                padding: '2rem',
                                                paddingBottom: '3.5rem',
                                            }}
                                        >
                                            <BaseInformation
                                                jobOffer={jobOffer}
                                                setJobOffer={setJobOffer}
                                                setJobsValues={setJobsValues}
                                            />

                                            <Segment clearing style={{ paddingBottom: '1rem', paddingTop: '1rem' }}>
                                                <div
                                                    style={{
                                                        cursor: 'pointer',
                                                        display: 'block',
                                                        width: '100%',
                                                        overflow: 'hidden',
                                                    }}
                                                    onClick={() => setConfigurationView(!configurationView)}
                                                >
                                                    <Icon
                                                        style={{ float: 'right', marginTop: '0.5rem' }}
                                                        name={
                                                            configurationView
                                                                ? 'chevron-up-outline'
                                                                : 'chevron-down-outline'
                                                        }
                                                    />
                                                    <Header
                                                        style={{ marginBottom: 0 }}
                                                        floated="left"
                                                        as="h2"
                                                        content={'2. ' + t('job_information')}
                                                    />
                                                </div>
                                                <div
                                                    style={{
                                                        float: 'left',
                                                        marginTop: '1rem',
                                                        width: '100%',
                                                        display: configurationView ? 'block' : 'none',
                                                    }}
                                                >
                                                    <OfferConfiguration
                                                        jobOffer={jobOffer}
                                                        setJobOffer={setJobOffer}
                                                        workplace={workplace}
                                                        setWorkplace={setWorkplace}
                                                    />
                                                </div>
                                            </Segment>

                                            <Segment clearing style={{ paddingBottom: '1rem', paddingTop: '1rem' }}>
                                                <div
                                                    style={{
                                                        cursor: 'pointer',
                                                        display: 'block',
                                                        width: '100%',
                                                        overflow: 'hidden',
                                                    }}
                                                    onClick={() => setContractView(!contractView)}
                                                >
                                                    <Icon
                                                        style={{ float: 'right', marginTop: '0.5rem' }}
                                                        name={
                                                            contractView ? 'chevron-up-outline' : 'chevron-down-outline'
                                                        }
                                                    />
                                                    <Header
                                                        style={{ marginBottom: 0 }}
                                                        floated="left"
                                                        as="h2"
                                                        content={'3. ' + t('contract_information')}
                                                    />
                                                </div>

                                                <div
                                                    style={{
                                                        float: 'left',
                                                        marginTop: '1rem',
                                                        width: '100%',
                                                        display: contractView ? 'block' : 'none',
                                                    }}
                                                >
                                                    <ContractsFieldGroup
                                                        salary={salary}
                                                        setSalary={setSalary}
                                                        contract={contract}
                                                        setContract={setContract}
                                                    />
                                                </div>
                                            </Segment>

                                            <Segment clearing style={{ paddingBottom: '1rem', paddingTop: '1rem' }}>
                                                <div
                                                    style={{
                                                        cursor: 'pointer',
                                                        display: 'block',
                                                        width: '100%',
                                                        overflow: 'hidden',
                                                    }}
                                                    onClick={() => setRequirementsView(!requirementsView)}
                                                >
                                                    <Icon
                                                        style={{ float: 'right', marginTop: '0.5rem' }}
                                                        name={
                                                            requirementsView
                                                                ? 'chevron-up-outline'
                                                                : 'chevron-down-outline'
                                                        }
                                                    />
                                                    <Header
                                                        style={{ marginBottom: 0 }}
                                                        floated="left"
                                                        as="h2"
                                                        content={'4. ' + t('job_requirements')}
                                                    />
                                                </div>
                                                <div
                                                    style={{
                                                        float: 'left',
                                                        marginTop: '1rem',
                                                        width: '100%',
                                                        display: requirementsView ? 'block' : 'none',
                                                    }}
                                                >
                                                    <JobOfferRequirements
                                                        requirements={requirements}
                                                        setRequirements={setRequirements}
                                                        jobOffer={jobOffer}
                                                        setJobOffer={setJobOffer}
                                                    />
                                                </div>
                                            </Segment>

                                            <Message
                                                error
                                                visible={errors.length > 0}
                                                header={t('error_submission_message')}
                                                list={errors}
                                            />

                                            <Form.Field style={{ textAlign: 'right' }}>
                                                <Button
                                                    disabled={isProcessing}
                                                    loading={isProcessing}
                                                    size="huge"
                                                    primary
                                                >
                                                    {' '}
                                                    {t('save')}{' '}
                                                </Button>
                                            </Form.Field>
                                        </Form>
                                    )}
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Container>
                )}
            </Fragment>
        </CanView>
    )
}

export default JobOfferForm
