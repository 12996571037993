import React from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
// components
import Icon from '@components/Icon'
import { List, Header, Grid } from 'semantic-ui-react'
import SettingsSidebarItem from './SettingsSidebarItem'
import { toggleSidebar } from '@services/helpers/functions'
import CanView from '@components/perms/CanView'

const SettingsSidebar = () => {
    const { t } = useTranslation()
    const history = useHistory()
    const urlToReturn = useSelector((state) => state.settings_return_url)
    const user = useSelector((state) => state.user)

    const redirectTo = (to) => {
        history.push(to)
    }

    return (
        <div className="settings-sidebar scrollable">
            <List.Content>
                <Grid>
                    <Grid.Row style={{ padding: 0 }}>
                        <Grid.Column width={13} style={{ padding: 0 }}>
                            <List className="settings-hide-sidebar" style={{ marginBottom: 0 }}>
                                <div
                                    fluid
                                    basic
                                    size="large"
                                    onClick={() => toggleSidebar()}
                                    style={{ cursor: 'pointer', padding: '0.5rem', marginTop: '1rem' }}
                                >
                                    <span className="hide-text" style={{ position: 'relative' }}>
                                        {t('hide_sidebar')}
                                    </span>
                                    <Icon
                                        name="chevron-back-outline"
                                        style={{ margin: 0, marginLeft: '1rem' }}
                                        className="toggle-icon"
                                    />
                                </div>
                            </List>
                            <List className="back-to-dashboard" style={{ marginBottom: 0 }}>
                                <div
                                    fluid
                                    basic
                                    size="large"
                                    onClick={() => redirectTo(urlToReturn)}
                                    style={{ cursor: 'pointer', padding: '0.5rem', marginTop: '1rem' }}
                                >
                                    <span className="hide-text" style={{ position: 'relative' }}>
                                        {t('back_to_dashboard')}
                                    </span>
                                    <Icon
                                        name="chevron-back-outline"
                                        style={{ margin: 0, marginLeft: '1rem' }}
                                        className="toggle-icon"
                                    />
                                </div>
                            </List>
                        </Grid.Column>
                        <Grid.Column width={3} style={{ padding: 0 }}>
                            <List className="back-to-dashboard-icon" style={{ marginBottom: 0 }}>
                                <div
                                    fluid
                                    basic
                                    size="large"
                                    onClick={() => redirectTo(urlToReturn)}
                                    style={{ cursor: 'pointer', padding: '0.5rem', marginTop: '1rem' }}
                                >
                                    <Icon
                                        name="close"
                                        style={{ margin: 0, marginLeft: '1rem', fontSize: '1.5rem' }}
                                        className="toggle-icon"
                                    />
                                </div>
                            </List>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>

                <List className="siderbar-list">
                    <Header className="sidebar-category" as="h4">
                        <Header.Content>{t('settings')}</Header.Content>
                    </Header>
                    <SettingsSidebarItem text={t('appearance')} icon={'color-fill-outline'} slug="appearance" />
                    {/* <CanView permissions={['templating.c_view_documents']}>
                        <SettingsSidebarItem text={t('templates')} icon={'document-text-outline'} slug="templates" />
                    </CanView> */}
                    <CanView permissions={['users.c_view_user_permissions']}>
                        <SettingsSidebarItem text={t('permissions')} icon={'lock-closed-outline'} slug="permissions" />
                    </CanView>
                    <CanView permissions={['integrations.c_view_all_bank_account_allowed_users']}>
                        <SettingsSidebarItem
                            text={t('finance_access_control')}
                            icon={'lock-closed-outline'}
                            slug={'finances'}
                        />
                    </CanView>

                    <CanView permissions={['users.c_view_users']}>
                        <SettingsSidebarItem text={t('user_management')} icon={'people-outline'} slug="users" />
                    </CanView>
                    {user.role !== 4 && (
                        <CanView permissions={['users.c_manage_types']}>
                            <SettingsSidebarItem text={t('types_management')} icon={'create-outline'} slug={'types'} />
                        </CanView>
                    )}
                    <CanView permissions={['orders.c_manage_order_tariffs']}>
                        <SettingsSidebarItem text={t('tariffs_management')} icon={'cash-outline'} slug={'tariffs'} />
                    </CanView>
                    <CanView permissions={['attendance.c_manage_shifts']}>
                        <SettingsSidebarItem text={t('shift_management')} icon={'today-outline'} slug={'work-shifts'} />
                    </CanView>
                    <CanView permissions={['attendance.c_manage_absence_types']}>
                        <SettingsSidebarItem text={t('absence_types')} icon={'list-outline'} slug="absence-types" />
                    </CanView>
                    <CanView permissions={['orders.c_view_catalogue']}>
                        <SettingsSidebarItem
                            text={t('catalogue_items')}
                            icon={'book-outline'}
                            slug={'catalogue-items'}
                        />
                    </CanView>
                    <CanView permissions={['assets.c_view_warehouses']}>
                        <SettingsSidebarItem text={t('warehouses')} icon={'albums-outline'} slug={'warehouses'} />
                    </CanView>
                    <CanView permissions={['assets.c_view_warehouses']}>
                        <SettingsSidebarItem
                            text={t('warehouse_positions')}
                            icon={'albums-outline'}
                            slug={'warehouse-positions'}
                        />
                    </CanView>
                    <CanView permissions={['common.c_view_gdpr']}>
                        <SettingsSidebarItem text={t('gdpr')} icon={'cube-outline'} slug={'gdpr'} />
                    </CanView>
                    <CanView permissions={['integrations.c_view_third_party_auth']}>
                        <SettingsSidebarItem text={t('integrations')} icon={'settings-outline'} slug={'integrations'} />
                    </CanView>
                    {user.role !== 4 && (
                        <SettingsSidebarItem text={t('preferences')} icon={'list-outline'} slug={'preferences'} />
                    )}

                    {user.role !== 4 && (
                        <CanView permissions={['users.c_manage_types']}>
                            <SettingsSidebarItem text={t('imports')} icon={'share-outline'} slug={'imports'} />
                        </CanView>
                    )}
                </List>
            </List.Content>
        </div>
    )
}

export default SettingsSidebar
