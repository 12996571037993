import React from 'react'
// components
import LoginForm from './LoginForm'
import { Image } from 'semantic-ui-react'

function Login() {
    return (
        <div className="center-element">
            <Image src="/images/login_1.svg" style={{ position: 'absolute', width: '300px', marginLeft: '20rem' }} />
            <LoginForm />
            <Image
                src="/images/login_2.svg"
                style={{ position: 'absolute', width: '300px', right: 0, marginRight: '20rem' }}
            />
        </div>
    )
}

export default Login
