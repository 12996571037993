import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-semantic-toasts'
import { useSelector } from 'react-redux'
import moment from 'moment'
import '@store/languages'
// store
import { API } from '@store/config'
import { tzDateTime } from '@helpers/dates'
import { requests } from '@helpers/requests'
// components
import Icon from '@components/Icon'
import Action from '@components/general/Action'
import CanView from '@components/perms/CanView'
import SuperField from '@components/forms/SuperField'
import ModalCancel from '@components/buttons/ModalCancel'
import ModalSubmit from '@components/buttons/ModalSubmit'
import ConfrimModal from '@components/modals/ConfrimModal'
import SuperDuperModal from '@components/modals/SuperDuperModal'
import { Popup, Button, Segment, Table, Loader, Label, Modal, Form, Divider, Message } from 'semantic-ui-react'
// specific components
import PauseForm from './PauseForm'
import RecordDuplicityMergeView from './RecordDuplicityMergeView'
import AbsenceForm from './AbsenceForm'

const WeekManagement = ({
    key,
    week,
    weekDates,
    shifts,
    loadingShifts,
    interruptionReasons,
    showCorrectedRecords,
    employee,
    holidays,
    contract,
    absenceTypes,
    viewVoucher,
    viewSecondBreak,
    setContracts,
    excludeWeekend,
    setWeeksStats,
}) => {
    const { t } = useTranslation()
    const language = useSelector((state) => state.language)
    const date_format = useSelector((state) => state.date_format)
    const [records, setRecords] = useState([])
    const [absences, setAbsences] = useState([])
    const [loading, setLoading] = useState(false)
    const [isSaving, setIsSaving] = useState(false)

    const [interruptionModal, setInterruptionModal] = useState({
        isOpen: false,
        interruption: null,
        day: null,
        record: null,
    })

    useEffect(() => {
        const generateWeeklyAttendance = async () => {
            setLoading(true)
            let dateFrom = weekDates[0]?.day
            let dateTo = weekDates[weekDates.length - 1]?.day

            let ignorePrefQuery = '&ignore_attendance_correction_preference=true'
            if (showCorrectedRecords) ignorePrefQuery = ''

            let dataset = []
            const request = await requests.get(
                API.ATTENDANCE +
                    '?contracts=' +
                    contract.id +
                    '&date_from=' +
                    moment(dateFrom).format('YYYY-MM-DD') +
                    '&date_to=' +
                    moment(dateTo).format('YYYY-MM-DD') +
                    ignorePrefQuery
            )
            if (request.status === 200) {
                dataset = request.response
            }

            let absenceList = []
            const requestAbsences = await requests.get(
                API.ATTENDANCE_BASE +
                    'absences/' +
                    '?contract=' +
                    contract.id +
                    '&date_after=' +
                    moment(dateFrom).format('YYYY-MM-DD') +
                    '&date_before=' +
                    moment(dateTo).format('YYYY-MM-DD') +
                    ignorePrefQuery
            )
            if (requestAbsences.status === 200) {
                absenceList = requestAbsences.response
            }

            const dates = getDates(moment(dateFrom), moment(dateTo))
            const attendance = []

            for (let i = 0; i < dates.length; i++) {
                const attendance_record = dataset.find((item) => item.date === moment(dates[i]).format('YYYY-MM-DD'))

                // convert to timezone
                let start_time = attendance_record?.start ? tzDateTime(attendance_record?.start).format('HH:mm') : ''
                let end_time = attendance_record?.end ? tzDateTime(attendance_record?.end).format('HH:mm') : ''
                let start_pause = attendance_record?.start_pause
                    ? tzDateTime(attendance_record?.start_pause).format('YYYY-MM-DD HH:mm')
                    : ''
                let end_pause = attendance_record?.end_pause
                    ? tzDateTime(attendance_record?.end_pause).format('YYYY-MM-DD HH:mm')
                    : ''

                let second_start_pause = attendance_record?.second_start_pause
                    ? tzDateTime(attendance_record?.second_start_pause).format('YYYY-MM-DD HH:mm')
                    : ''
                let second_end_pause = attendance_record?.second_end_pause
                    ? tzDateTime(attendance_record?.second_end_pause).format('YYYY-MM-DD HH:mm')
                    : ''

                attendance.push({
                    week: moment(dates[i]).isoWeek(),
                    day: dates[i],
                    errors: [],
                    isProcessing: false,
                    multipleData: dataset.filter((item) => item.date === moment(dates[i]).format('YYYY-MM-DD')),
                    data:
                        attendance_record !== undefined
                            ? {
                                  ...attendance_record,
                                  start_time: start_time,
                                  end_time: end_time,
                                  start_pause: start_pause,
                                  end_pause: end_pause,
                                  second_start_pause: second_start_pause,
                                  second_end_pause: second_end_pause,
                                  shift: attendance_record?.shift?.id || '',
                                  //   absence_type_display: attendance_record?.absence_type?.title || '',
                                  //   absence_type: attendance_record.absence_type?.id || '',
                              }
                            : {},
                })
            }

            setRecords(attendance)
            setAbsences(absenceList)
            setLoading(false)
        }

        generateWeeklyAttendance()
        // eslint-disable-next-line
    }, [])

    const getDates = (from, to) => {
        let dates = []
        // add logic to calculate days between two date range
        for (let day = from; day.isSameOrBefore(to); day.add(1, 'days')) {
            dates.push(day.format('YYYY-MM-DD'))
        }

        return dates
    }

    const isWeekendDay = (day) => {
        if (excludeWeekend === false) return false

        return [0, 6].includes(moment(day).day())
    }

    const handleDecimalValue = (value) => {
        if (value === '') return value

        return value.replace(',', '.')
    }

    const handleTimeValue = (value) => {
        value = value.replace('.', ':')
        value = value.replace(',', ':')
        value = value.replace(';', ':')
        // value = value.replace(" ", ":")

        return value
    }

    const isNumberValid = (value) => {
        if (value === '') return true
        if (value === null) return true
        if (value === undefined) return true

        return !isNaN(value)
    }

    const isTimeValid = (value) => {
        if (value === '') return true
        if (value === null) return true
        if (value === undefined) return true

        return moment(value, 'HH:mm', true).isValid()
    }

    const handleRecordDelete = async (day) => {
        setIsProcessing(day, true)
        const record = records.find((item) => item.day === day)
        if (record) {
            const request = await requests.post(API.ATTENDANCE + record.data.id + '/delete/')
            if (request.status === 200) {
                setRecords((prev) =>
                    prev.filter((item) => {
                        if (item.day === day) {
                            item.data = {}
                        }

                        return item
                    })
                )

                if (record.vacation > 0 || record.vacation !== null) {
                    setContracts((prev) =>
                        prev.filter((item) => {
                            if (item.id === record.contract?.id) {
                                item.available_vacation_fond =
                                    parseFloat(item.available_vacation_fond) + parseFloat(record.vacation)
                            }
                            return item
                        })
                    )
                }
            }
        }
        setIsProcessing(day, false)
    }

    const fetchWeekStats = async () => {
        let date_from = weekDates[0]?.day
        let date_to = weekDates[weekDates.length - 1]?.day
        let correctedRecordsQuery = '&ignore_attendance_correction_preference=true'
        if (showCorrectedRecords) correctedRecordsQuery = ''
        const request_week_stats = await requests.get(
            API.ATTENDANCE +
                `employee_stats/?date_from=${date_from}&date_to=${date_to}&contracts=${contract.id}${correctedRecordsQuery}`
        )

        if (request_week_stats.status === 200) {
            setWeeksStats((prev) =>
                prev.map((item) => {
                    if (item.week === week) {
                        item = {
                            week: week,
                            ...request_week_stats.response,
                        }
                    }
                    return item
                })
            )
        }
    }

    const handleWeekSaveAction = async (week) => {
        setIsSaving(true)
        const week_records = records.filter((record) => record.week === week)

        for (let i = 0; i < week_records.length; i++) {
            if (Object.keys(week_records[i].data).length !== 0) {
                // skip day when no data are filled
                setIsProcessing(week_records[i].day, true)
                const start = week_records[i].data?.start_time
                    ? moment(week_records[i].day + ' ' + week_records[i].data?.start_time)
                    : null
                const tmp_end = week_records[i].data?.end_time
                    ? moment(week_records[i].day + ' ' + week_records[i].data?.end_time)
                    : null

                let end_day = moment(week_records[i].day).format('YYYY-MM-DD')
                if (start !== null && tmp_end !== null) {
                    // find if tmp_end is not less then given day if yes then add one day
                    if (start.isAfter(tmp_end)) {
                        end_day = moment(week_records[i].day).add(1, 'days').format('YYYY-MM-DD')
                    }
                }

                const end = week_records[i].data?.end_time
                    ? moment(end_day + ' ' + week_records[i].data?.end_time)
                    : null

                const data = {
                    start: start !== null ? tzDateTime(start, true).format('YYYY-MM-DD HH:mm:ss') : start,
                    end: end !== null ? tzDateTime(end, true).format('YYYY-MM-DD HH:mm:ss') : end,
                    duration: week_records[i].data?.duration || 0,
                    start_pause: week_records[i].data?.start_pause
                        ? tzDateTime(week_records[i].data?.start_pause, true).format('YYYY-MM-DD HH:mm:ss')
                        : null,
                    end_pause: week_records[i].data?.end_pause
                        ? tzDateTime(week_records[i].data?.end_pause, true).format('YYYY-MM-DD HH:mm:ss')
                        : null,
                    second_start_pause: week_records[i].data?.second_start_pause
                        ? tzDateTime(week_records[i].data?.second_start_pause, true).format('YYYY-MM-DD HH:mm:ss')
                        : null,
                    second_end_pause: week_records[i].data?.second_end_pause
                        ? tzDateTime(week_records[i].data?.second_end_pause, true).format('YYYY-MM-DD HH:mm:ss')
                        : null,
                    shift: week_records[i].data?.shift !== '' ? week_records[i].data?.shift : null,
                    lunch_voucher: week_records[i].data?.lunch_voucher || null,
                    // vacation: week_records[i].data?.vacation ? parseFloat(week_records[i].data?.vacation) : 0,
                    // vacation_hours: week_records[i].data?.vacation_hours
                    //     ? parseFloat(week_records[i].data?.vacation_hours)
                    //     : 0,
                    // absention: week_records[i].data?.absention || null,
                    // paragraph: week_records[i].data?.paragraph || null,
                    // sick_day: week_records[i].data?.sick_day || null,
                    // care_for_a_family_member: week_records[i].data?.care_for_a_family_member || null,
                    // family_member_paragraph: week_records[i].data?.family_member_paragraph || null,
                    date: week_records[i].day,
                    employee: contract.employee.id,
                    // absence_type: week_records[i].data.absence_type || null,
                    contract: contract.id,
                    closed: start === null && week_records[i]?.data?.absence_type ? true : false,
                }

                if (
                    ((data.start !== null && data.end !== null) || data.absence_type !== null) &&
                    week_records[i].data?.require_manual_correction === true
                ) {
                    data['require_manual_correction'] = false
                }

                let request = null
                if (week_records[i].data?.id > 0) {
                    request = await requests.patch(
                        API.ATTENDANCE + week_records[i].data?.id + '/?is_manager_edit=true',
                        data
                    )
                } else {
                    request = await requests.post(API.ATTENDANCE_MANUAL, data)
                }

                if (request.status === 200 || request.status === 201) {
                    const response = request.response

                    // convert to timezone
                    let start_time = response?.start ? tzDateTime(response?.start).format('HH:mm') : ''
                    let end_time = response?.end ? tzDateTime(response?.end).format('HH:mm') : ''

                    let start_pause = response?.start_pause
                        ? tzDateTime(response?.start_pause).format('YYYY-MM-DD HH:mm')
                        : ''
                    let end_pause = response?.end_pause
                        ? tzDateTime(response?.end_pause).format('YYYY-MM-DD HH:mm')
                        : ''

                    let second_start_pause = response?.second_start_pause
                        ? tzDateTime(response?.second_start_pause).format('YYYY-MM-DD HH:mm')
                        : ''
                    let second_end_pause = response?.second_end_pause
                        ? tzDateTime(response?.second_end_pause).format('YYYY-MM-DD HH:mm')
                        : ''

                    setRecords((prev) =>
                        prev.map((item) => {
                            if (item.day === week_records[i].day) {
                                item.data = {
                                    ...response,
                                    start_time: start_time,
                                    end_time: end_time,
                                    start_pause: start_pause,
                                    end_pause: end_pause,
                                    second_start_pause: second_start_pause,
                                    second_end_pause: second_end_pause,
                                    shift: response?.shift?.id || '',
                                    // absence_type: response?.absence_type?.id || '',
                                    // absence_type_display: response?.absence_type?.title || '',
                                }
                            }

                            return item
                        })
                    )

                    toast({
                        type: 'success',
                        icon: 'check circle',
                        title: t('success_changed'),
                        description: '',
                        animation: 'bounce',
                        time: 3000,
                    })
                }

                if (request.status === 400) {
                    setRecords((prev) =>
                        prev.filter((item) => {
                            if (item.day === week_records[i].day) {
                                item.errors = request.response
                            }

                            return item
                        })
                    )
                }

                setIsProcessing(week_records[i].day, false)
            }
        }

        // fetch latest statistics
        await fetchWeekStats()

        const requestContract = await requests.get(
            API.CONTRACTS + contract.id + '/?query={id, available_vacation_fond}'
        )

        if (requestContract.status === 200) {
            setContracts((prev) =>
                prev.filter((item) => {
                    if (item.id === contract.id) {
                        item.available_vacation_fond =
                            requestContract?.response?.available_vacation_fond || item.available_vacation_fond
                    }
                    return item
                })
            )
        }

        setIsSaving(false)
    }

    const handleChange = async (day, value, attribute) => {
        setRecords((prev) =>
            prev.filter((record) => {
                if (record.day === day) {
                    record.data[attribute] = value
                }

                return record
            })
        )
    }

    const setIsProcessing = (day, value) => {
        setRecords((prev) =>
            prev.filter((item) => {
                if (item.day === day) {
                    item.isProcessing = value
                }

                return item
            })
        )
    }

    const calculateDuration = (record) => {
        let duration = ''
        if (Object.keys(record.data).length === 0) return
        if (record.data?.start_time === undefined || record.data?.start_time === null || record.data?.start_time === '')
            return
        if (record.data?.end_time === undefined || record.data?.end_time === null || record.data?.end_time === '')
            return

        // create start, end intervals
        if (
            moment(record.data?.start_time, 'HH:mm', true).isValid() &&
            moment(record.data?.end_time, 'HH:mm', true).isValid()
        ) {
            let start = record.data?.start_time ? moment(record.day + ' ' + record.data?.start_time) : null
            const tmp_end = record.data?.end_time ? moment(record.day + ' ' + record.data?.end_time) : null

            let end_day = moment(record.day).format('YYYY-MM-DD')
            if (start !== null && tmp_end !== null) {
                // find if tmp_end is not less then given day if yes then add one day
                if (start.isAfter(tmp_end)) {
                    end_day = moment(record.day).add(1, 'days').format('YYYY-MM-DD')
                }
            }

            let end = record.data?.end_time
                ? moment(end_day + ' ' + record.data?.end_time).format('YYYY-MM-DD HH:mm')
                : null
            duration = moment(end).diff(moment(moment(start).format('YYYY-MM-DD HH:mm')), 'hours', true)
        }

        let breakTime = 0
        // create start, end intervals
        if (
            moment(moment(record.data?.start_pause).format('YYYY-MM-DD HH:mm'), 'YYYY-MM-DD HH:mm', true).isValid() &&
            moment(moment(record.data?.end_pause).format('YYYY-MM-DD HH:mm'), 'YYYY-MM-DD HH:mm', true).isValid()
        ) {
            let start_pause = moment(record.data?.start_pause).format('YYYY-MM-DD HH:mm')
            let end_pause = moment(record.data?.end_pause).format('YYYY-MM-DD HH:mm')

            breakTime = moment(end_pause).diff(moment(start_pause), 'hours', true)
        }

        if (duration !== '') {
            if (duration > 0) {
                duration -= breakTime
            }
            handleChange(record.day, duration?.toFixed(2) || '', 'duration')
        }
    }

    const autoFillShift = (shift_id, record_day) => {
        const shift = shifts.find((item) => item.id === shift_id)

        if (shift) {
            if (![null, ''].includes(shift.time_from)) {
                handleChange(record_day, shift.time_from, 'start_time')
            }
            if (![null, ''].includes(shift.time_to)) {
                handleChange(record_day, shift.time_to, 'end_time')
            }

            handleChange(record_day, shift.length, 'duration')
            handleChange(record_day, ['', null, 0, undefined].includes(shift.pause_length) ? false : true, 'auto_break')
        }
    }

    const applyToAll = (field) => {
        const findRecord = records[0]
        if (findRecord) {
            for (let i = 0; i < records.length; i++) {
                handleChange(records[i].day, findRecord?.data?.[field] || '', field)
                if (field === 'shift') {
                    autoFillShift(findRecord?.data?.[field], records[i].day)
                }
            }
        }
    }

    const handleCloseAction = async (record) => {
        const request = await requests.patch(API.ATTENDANCE + record.id + '/', { closed: !record.closed })
        if (request.status === 200) {
            if (request.response.end !== null) {
                toast({
                    type: 'error',
                    icon: 'warning',
                    title: t('record_can_not_be_changed_already_locked_hint'),
                    animation: 'pulse',
                    time: 5000,
                })
            } else {
                setRecords((prev) =>
                    prev.map((item) => {
                        if (item.data.id === record.id) {
                            item.data = {
                                ...item.data,
                                closed: request.response.closed,
                            }
                        }

                        return item
                    })
                )
            }
        }
    }

    const handleAbsenceRemove = async (id) => {
        const request = await requests.del(API.ATTENDANCE_BASE + 'absences/' + id + '/')
        if (request.status === 204) {
            await fetchWeekStats()
            setAbsences((prev) => prev.filter((item) => item.id !== id))

            toast({
                type: 'success',
                icon: 'check circle',
                title: t('work_absence_removed'),
                description: '',
                animation: 'bounce',
                time: 3000,
            })
        }
    }

    return (
        <Segment
            loading={loading}
            style={{
                padding: 0,
                background: 'transparent',
                boxShadow: 'none',
                border: 'none',
                marginBottom: '1rem',
            }}
        >
            {loading && (
                <p style={{ textAlign: 'center', color: 'var(--dark)', paddingTop: '10rem', paddingBottom: '4rem' }}>
                    {t('loading_weekly_attendance')}
                </p>
            )}

            {!loading && (
                <div style={{ padding: 0, overflow: 'auto', width: '100%' }}>
                    <Table striped selectable unstackable className="table responsive" size="small">
                        <Table.Header>
                            <Table.Row verticalAlign="middle">
                                <Table.HeaderCell content={t('date')} />
                                <Table.HeaderCell>
                                    <span style={{ position: 'relative', top: '-0.2rem', textTransform: 'capitalize' }}>
                                        {t('shift')}{' '}
                                    </span>
                                    {!showCorrectedRecords && (
                                        <Popup
                                            position="top center"
                                            trigger={
                                                <Icon
                                                    name="chevron-down-circle-outline"
                                                    style={{
                                                        fontSize: '1.1rem',
                                                        marginLeft: '0.5rem',
                                                        cursor: 'pointer',
                                                        fontWeight: 'bold',
                                                    }}
                                                    onClick={() => applyToAll('shift')}
                                                />
                                            }
                                            content={t('apply_to_all_attendance_hint')}
                                        />
                                    )}
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    <span style={{ position: 'relative', top: '-0.2rem' }}> {t('time_from')} </span>
                                    {!showCorrectedRecords && (
                                        <Popup
                                            position="top center"
                                            trigger={
                                                <Icon
                                                    name="chevron-down-circle-outline"
                                                    style={{
                                                        fontSize: '1.1rem',
                                                        marginLeft: '0.5rem',
                                                        cursor: 'pointer',
                                                        fontWeight: 'bold',
                                                    }}
                                                    onClick={() => applyToAll('start_time')}
                                                />
                                            }
                                            content={t('apply_to_all_attendance_hint')}
                                        />
                                    )}
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    <span style={{ position: 'relative', top: '-0.2rem' }}> {t('time_to')} </span>
                                    {!showCorrectedRecords && (
                                        <Popup
                                            position="top center"
                                            trigger={
                                                <Icon
                                                    name="chevron-down-circle-outline"
                                                    style={{
                                                        fontSize: '1.1rem',
                                                        marginLeft: '0.5rem',
                                                        cursor: 'pointer',
                                                        fontWeight: 'bold',
                                                    }}
                                                    onClick={() => applyToAll('end_time')}
                                                />
                                            }
                                            content={t('apply_to_all_attendance_hint')}
                                        />
                                    )}
                                </Table.HeaderCell>
                                <Table.HeaderCell textAlign="left">{t('interruptions')}</Table.HeaderCell>
                                <Table.HeaderCell content={t('pause')} textAlign="center" />
                                {viewSecondBreak && <Table.HeaderCell content={t('pause_2')} textAlign="center" />}
                                <Table.HeaderCell content={t('working_hours')} textAlign="center" />
                                {viewVoucher && (
                                    <Table.HeaderCell textAlign="center" singleLine>
                                        <span style={{ position: 'relative', top: '-0.2rem' }}>
                                            {' '}
                                            {t('lunch_voucher')}{' '}
                                        </span>
                                        {!showCorrectedRecords && (
                                            <Popup
                                                position="top center"
                                                trigger={
                                                    <Icon
                                                        name="chevron-down-circle-outline"
                                                        style={{
                                                            fontSize: '1.1rem',
                                                            marginLeft: '0.5rem',
                                                            cursor: 'pointer',
                                                            fontWeight: 'bold',
                                                        }}
                                                        onClick={() => applyToAll('lunch_voucher')}
                                                    />
                                                }
                                                content={t('apply_to_all_attendance_hint')}
                                            />
                                        )}
                                    </Table.HeaderCell>
                                )}

                                <Table.HeaderCell textAlign="left">{t('work_absence')}</Table.HeaderCell>

                                <Table.HeaderCell>
                                    <Button
                                        primary
                                        fluid
                                        size="small"
                                        loading={isSaving}
                                        disabled={isSaving || showCorrectedRecords}
                                        type="button"
                                        content={t('save')}
                                        onClick={() => handleWeekSaveAction(week)}
                                    />
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {records.map((record, index) => (
                                <>
                                    {!isWeekendDay(record.day) && (
                                        <Table.Row
                                            verticalAlign="middle"
                                            key={index}
                                            style={
                                                record.multipleData.length > 1 ||
                                                record?.data?.require_manual_correction
                                                    ? { background: '#f7b0b0' }
                                                    : undefined
                                            }
                                        >
                                            <Table.Cell
                                                width="1"
                                                singleLine
                                                style={{
                                                    fontWeight: 'bold',
                                                    color: holidays?.[record.day]
                                                        ? 'orange'
                                                        : [0, 6].includes(moment(record.day).day())
                                                        ? 'var(--danger)'
                                                        : 'var(--dark)',
                                                }}
                                            >
                                                {holidays?.[record.day] ? (
                                                    <Popup
                                                        position="right center"
                                                        trigger={
                                                            <div>
                                                                <span style={{ textTransform: 'uppercase' }}>
                                                                    {moment(record.day).locale(language).format('dd')}
                                                                </span>{' '}
                                                                · {moment(record.day).format(date_format)} <br />
                                                            </div>
                                                        }
                                                        content={
                                                            <div style={{ textAlign: 'center' }}>
                                                                {holidays?.[record.day]} <br />
                                                                <small>
                                                                    <strong>{t('holiday')}</strong>
                                                                </small>
                                                            </div>
                                                        }
                                                    />
                                                ) : (
                                                    <>
                                                        <span style={{ textTransform: 'uppercase' }}>
                                                            {moment(record.day).locale(language).format('dd')}
                                                        </span>{' '}
                                                        · {moment(record.day).format(date_format)} <br />
                                                    </>
                                                )}
                                            </Table.Cell>
                                            <Table.Cell>
                                                <SuperField
                                                    as="choice"
                                                    search
                                                    value={record?.data?.shift || ''}
                                                    loading={loadingShifts}
                                                    disabled={loadingShifts || showCorrectedRecords}
                                                    customOptions={shifts.map((item) => ({
                                                        key: item.id,
                                                        value: item.id,
                                                        text: item.title,
                                                    }))}
                                                    style={{ marginTop: '0.25rem', width: '150px' }}
                                                    onChange={(e, { value }) => {
                                                        autoFillShift(value, record.day)
                                                        handleChange(record.day, value, 'shift')
                                                    }}
                                                />
                                            </Table.Cell>
                                            <Table.Cell width="1">
                                                <Popup
                                                    position="top center"
                                                    disabled={isTimeValid(record.data?.start_time || '')}
                                                    trigger={
                                                        <SuperField
                                                            as="timepicker"
                                                            disabled={showCorrectedRecords}
                                                            style={{ width: '100px' }}
                                                            value={record.data?.start_time || ''}
                                                            onChange={(e, { value }) => {
                                                                handleChange(
                                                                    record.day,
                                                                    handleTimeValue(value),
                                                                    'start_time'
                                                                )
                                                                let changed_record = record
                                                                changed_record.data['start_time'] =
                                                                    handleTimeValue(value)
                                                                calculateDuration(changed_record)
                                                            }}
                                                            error={
                                                                !isTimeValid(record.data?.start_time || '')
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                    }
                                                    content={
                                                        !isTimeValid(record.data?.start_time || '')
                                                            ? t('wront_time_format_use_hh_mm')
                                                            : ''
                                                    }
                                                />
                                            </Table.Cell>
                                            <Table.Cell width="1">
                                                <Popup
                                                    position="top center"
                                                    disabled={isTimeValid(record.data?.end_time || '')}
                                                    trigger={
                                                        <SuperField
                                                            as="timepicker"
                                                            disabled={showCorrectedRecords}
                                                            style={{ width: '100px' }}
                                                            value={record.data?.end_time || ''}
                                                            error={
                                                                !isTimeValid(record.data?.end_time || '') ? true : false
                                                            }
                                                            onChange={(e, { value }) => {
                                                                handleChange(
                                                                    record.day,
                                                                    handleTimeValue(value),
                                                                    'end_time'
                                                                )
                                                                let changed_record = record
                                                                changed_record.data['end_time'] = handleTimeValue(value)
                                                                calculateDuration(changed_record)
                                                            }}
                                                        />
                                                    }
                                                    content={
                                                        !isTimeValid(record.data?.end_time || '')
                                                            ? t('wront_time_format_use_hh_mm')
                                                            : ''
                                                    }
                                                />
                                            </Table.Cell>
                                            <Table.Cell style={{ minWidth: '100px' }}>
                                                {record?.data?.interruptions?.length > 0 ? (
                                                    <Popup
                                                        hoverable
                                                        position="top center"
                                                        style={{ zIndex: 1500 }}
                                                        trigger={
                                                            <Label size="tiny" basic>
                                                                <span style={{ position: 'relative', top: '-0.2rem' }}>
                                                                    {t('yes')} - {record?.data?.interruptions?.length}x
                                                                </span>
                                                                <Icon
                                                                    name="eye-outline"
                                                                    style={{
                                                                        marginLeft: '0.5rem',
                                                                        fontSize: '1rem',
                                                                        color: 'var(--primary)',
                                                                        cursor: 'pointer',
                                                                    }}
                                                                />
                                                            </Label>
                                                        }
                                                        content={
                                                            <div>
                                                                {record?.data?.interruptions?.map((item, idx) => (
                                                                    <div
                                                                        style={{ marginBottom: '0.5rem' }}
                                                                        key={item.id}
                                                                    >
                                                                        <strong>
                                                                            {idx + 1}. {item.reason.title}
                                                                        </strong>{' '}
                                                                        <br />
                                                                        {item.start
                                                                            ? tzDateTime(item.start).format('HH:mm')
                                                                            : '--:--'}{' '}
                                                                        -{' '}
                                                                        {item.end
                                                                            ? tzDateTime(item.end).format('HH:mm')
                                                                            : '--:--'}
                                                                        {!showCorrectedRecords && (
                                                                            <div
                                                                                onClick={() =>
                                                                                    setInterruptionModal({
                                                                                        isOpen: true,
                                                                                        interruption: item,
                                                                                        day: record.day,
                                                                                        record: record.data,
                                                                                    })
                                                                                }
                                                                                className="ref-link"
                                                                                style={{ color: 'var(--primary)' }}
                                                                            >
                                                                                {t('update_record')}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        }
                                                    />
                                                ) : (
                                                    <Label size="tiny" basic style={{ opacity: '0.5' }}>
                                                        {t('no')}
                                                    </Label>
                                                )}
                                            </Table.Cell>
                                            <Table.Cell textAlign="center">
                                                <Action
                                                    as="modal"
                                                    type="icon"
                                                    modalSize="tiny"
                                                    iconColor="var(--dark)"
                                                    icon={record.data?.start_pause ? 'pencil-outline' : 'add-outline'}
                                                    tooltip={
                                                        record.data?.start_pause ? (
                                                            <div style={{ textAlign: 'center' }}>
                                                                <strong>{t('update_pause')}</strong> <br />
                                                                {moment(record.data?.start_pause).format(
                                                                    date_format + ' HH:mm'
                                                                )}{' '}
                                                                <br />
                                                                {moment(record.data?.end_pause).format(
                                                                    date_format + ' HH:mm'
                                                                )}
                                                            </div>
                                                        ) : (
                                                            t('add_pause')
                                                        )
                                                    }
                                                    modal={
                                                        <PauseForm
                                                            record={record}
                                                            handleChange={handleChange}
                                                            calculateDuration={calculateDuration}
                                                        />
                                                    }
                                                />
                                            </Table.Cell>
                                            {viewSecondBreak && (
                                                <Table.Cell textAlign="center">
                                                    <Action
                                                        as="modal"
                                                        type="icon"
                                                        modalSize="tiny"
                                                        iconColor="var(--dark)"
                                                        icon={
                                                            record.data?.second_start_pause
                                                                ? 'pencil-outline'
                                                                : 'add-outline'
                                                        }
                                                        tooltip={
                                                            record.data?.second_start_pause ? (
                                                                <div style={{ textAlign: 'center' }}>
                                                                    <strong>{t('update_second_pause')}</strong> <br />
                                                                    {moment(record.data?.second_start_pause).format(
                                                                        date_format + ' HH:mm'
                                                                    )}{' '}
                                                                    <br />
                                                                    {moment(record.data?.second_end_pause).format(
                                                                        date_format + ' HH:mm'
                                                                    )}
                                                                </div>
                                                            ) : (
                                                                t('add_second_pause')
                                                            )
                                                        }
                                                        modal={
                                                            <PauseForm
                                                                isSecond={true}
                                                                record={record}
                                                                handleChange={handleChange}
                                                                calculateDuration={calculateDuration}
                                                            />
                                                        }
                                                    />
                                                </Table.Cell>
                                            )}
                                            <Table.Cell textAlign="center" style={{ fontWeight: 'bold' }}>
                                                {record.data?.work_hours === undefined ? (
                                                    '--'
                                                ) : (
                                                    <>
                                                        {record.data?.work_hours || '0'}{' '}
                                                        <span style={{ textTranform: 'lowercase' }}>
                                                            {t('hours_shortcut')}.
                                                        </span>
                                                    </>
                                                )}
                                            </Table.Cell>

                                            {viewVoucher && (
                                                <Table.Cell textAlign="center" width="1">
                                                    <Popup
                                                        position="top center"
                                                        disabled={isNumberValid(record.data?.lunch_voucher || '')}
                                                        content={
                                                            !isNumberValid(record.data?.lunch_voucher)
                                                                ? t('not_a_number')
                                                                : ''
                                                        }
                                                        trigger={
                                                            <SuperField
                                                                as="input"
                                                                style={{ width: '60px' }}
                                                                className="input-centered-value"
                                                                value={
                                                                    record.data?.lunch_voucher
                                                                        ?.toString()
                                                                        ?.replace('.00', '') || ''
                                                                }
                                                                onChange={(e, { value }) =>
                                                                    handleChange(
                                                                        record.day,
                                                                        handleDecimalValue(value),
                                                                        'lunch_voucher'
                                                                    )
                                                                }
                                                                error={
                                                                    !isNumberValid(record.data?.lunch_voucher || '')
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                        }
                                                    />
                                                </Table.Cell>
                                            )}

                                            <Table.Cell width="3" style={{ width: '500px' }}>
                                                {absences.filter((item) => item.date === record.day).length > 0 && (
                                                    <>
                                                        {absences
                                                            .filter((item) => item.date === record.day)
                                                            .map((item) => (
                                                                <Label basic>
                                                                    {item.absence_type?.title} - {item.hours}{' '}
                                                                    {t('hours_shortcut').toLocaleLowerCase()}
                                                                    {item.requested && (
                                                                        <Popup
                                                                            trigger={
                                                                                <span>
                                                                                    <Icon
                                                                                        style={{
                                                                                            color: 'var(--blue)',
                                                                                            marginLeft: '0.5rem',
                                                                                            fontSize: '1rem',
                                                                                            position: 'relative',
                                                                                            top: '0.1rem',
                                                                                        }}
                                                                                        name="information-circle-outline"
                                                                                    />
                                                                                </span>
                                                                            }
                                                                            content={
                                                                                <div>
                                                                                    {t('assigned_by_employee_at')}:{' '}
                                                                                    <br />
                                                                                    <strong>
                                                                                        {tzDateTime(
                                                                                            item.created_on
                                                                                        ).format(
                                                                                            date_format + ' HH:mm'
                                                                                        )}
                                                                                    </strong>
                                                                                </div>
                                                                            }
                                                                        />
                                                                    )}
                                                                    {!showCorrectedRecords && (
                                                                        <ConfrimModal
                                                                            onConfirm={() =>
                                                                                handleAbsenceRemove(item.id)
                                                                            }
                                                                            description={t(
                                                                                'are_you_sure_to_remove_work_absence'
                                                                            )}
                                                                            button={
                                                                                <Icon
                                                                                    name="close-outline"
                                                                                    style={{
                                                                                        color: 'var(--danger)',
                                                                                        marginLeft: '0.5rem',
                                                                                        position: 'relative',
                                                                                        top: '0.1rem',
                                                                                        cursor: 'pointer',
                                                                                    }}
                                                                                />
                                                                            }
                                                                        />
                                                                    )}
                                                                </Label>
                                                            ))}
                                                    </>
                                                )}
                                                {!showCorrectedRecords && (
                                                    <SuperDuperModal
                                                        header={t('add_absence')}
                                                        trigger={
                                                            <Label
                                                                basic
                                                                style={{ cursor: 'pointer', color: 'var(--primary)' }}
                                                            >
                                                                <Icon
                                                                    name="add-outline"
                                                                    style={{
                                                                        marginRight: '0.5em',
                                                                        position: 'relative',
                                                                        top: '0.1rem',
                                                                    }}
                                                                />
                                                                {t('add')}
                                                            </Label>
                                                        }
                                                        content={
                                                            <AbsenceForm
                                                                setAbsences={setAbsences}
                                                                fetchWeekStats={fetchWeekStats}
                                                                contract={contract}
                                                                absenceTypes={absenceTypes}
                                                                day={record.day}
                                                            />
                                                        }
                                                    />
                                                )}
                                            </Table.Cell>

                                            <Table.Cell width="1" style={{ width: '150px', textAlign: 'center' }}>
                                                {!showCorrectedRecords && (
                                                    <>
                                                        <CanView permissions={['attendance.c_delete_all_records']}>
                                                            {record.data?.id > 0 && (
                                                                <>
                                                                    {record.multipleData.length > 1 ? (
                                                                        <Action
                                                                            as="modal"
                                                                            type="icon"
                                                                            size="huge"
                                                                            tooltip={t('correction_multiple_records')}
                                                                            iconColor="var(--dark)"
                                                                            header={t('correction_multiple_records')}
                                                                            icon="pencil-outline"
                                                                            modal={
                                                                                <RecordDuplicityMergeView
                                                                                    setRecords={setRecords}
                                                                                    day={record.day}
                                                                                    records={record.multipleData}
                                                                                />
                                                                            }
                                                                        />
                                                                    ) : record.isProcessing ? (
                                                                        <Loader
                                                                            size="small"
                                                                            className="dark-loader"
                                                                            active
                                                                            inline
                                                                        />
                                                                    ) : (
                                                                        <Popup
                                                                            position="left center"
                                                                            content={t('remove')}
                                                                            trigger={
                                                                                <Icon
                                                                                    className={
                                                                                        record.isProcessing
                                                                                            ? 'disabled'
                                                                                            : ''
                                                                                    }
                                                                                    name="close-outline"
                                                                                    style={{
                                                                                        position: 'relative',
                                                                                        cursor: 'pointer',
                                                                                        fontSize: '1.2rem',
                                                                                        marginRight: '2rem',
                                                                                        top: '0.2rem',
                                                                                        color: record.isProcessing
                                                                                            ? 'var(--black)'
                                                                                            : 'var(--danger)',
                                                                                    }}
                                                                                    onClick={() =>
                                                                                        handleRecordDelete(record.day)
                                                                                    }
                                                                                />
                                                                            }
                                                                        />
                                                                    )}
                                                                </>
                                                            )}
                                                        </CanView>

                                                        {!record.isProcessing && (
                                                            <CanView permissions={['attendance.c_manage_all_records']}>
                                                                {record?.data?.id &&
                                                                    record.multipleData.length === 1 && (
                                                                        <Popup
                                                                            position="right center"
                                                                            content={
                                                                                record?.data?.closed === true
                                                                                    ? t('reopen_closed_day')
                                                                                    : t('close_day')
                                                                            }
                                                                            trigger={
                                                                                <Icon
                                                                                    style={{
                                                                                        cursor: 'pointer',
                                                                                        fontSize: '1.2rem',
                                                                                        color:
                                                                                            record?.data?.closed ===
                                                                                            true
                                                                                                ? 'var(--dark)'
                                                                                                : 'green',
                                                                                    }}
                                                                                    onClick={() =>
                                                                                        handleCloseAction(record?.data)
                                                                                    }
                                                                                    name={
                                                                                        record?.data?.closed === true
                                                                                            ? 'lock-closed'
                                                                                            : 'lock-open'
                                                                                    }
                                                                                />
                                                                            }
                                                                        />
                                                                    )}
                                                            </CanView>
                                                        )}
                                                    </>
                                                )}
                                            </Table.Cell>
                                        </Table.Row>
                                    )}
                                </>
                            ))}
                        </Table.Body>
                    </Table>
                </div>
            )}

            <Modal
                closeIcon
                size="tiny"
                open={interruptionModal.isOpen}
                onClose={() =>
                    setInterruptionModal({
                        isOpen: false,
                        interruption: null,
                        day: null,
                        record: null,
                    })
                }
            >
                <Modal.Header>{t('update_interruption_record')}</Modal.Header>
                <Modal.Content>
                    <InterruptionForm
                        interruption={interruptionModal.interruption}
                        interruptionReasons={interruptionReasons}
                        day={interruptionModal.day}
                        record={interruptionModal.record}
                        setRecords={setRecords}
                        onClose={() =>
                            setInterruptionModal({
                                isOpen: false,
                                interruption: null,
                                day: null,
                                record: null,
                            })
                        }
                    />
                </Modal.Content>
            </Modal>
        </Segment>
    )
}

const InterruptionForm = ({ interruption, day, record, setRecords, onClose, interruptionReasons }) => {
    const { t } = useTranslation()
    // const date_format = useSelector((state) => state.date_format)
    const [processing, setProcessing] = useState(false)
    const [removeRequest, setRemoveRequest] = useState(false)
    const [startDate, setStartDate] = useState(
        interruption.start ? tzDateTime(interruption.start, false).format('YYYY-MM-DD') : ''
    )
    const [endDate, setEndDate] = useState(
        interruption.end ? tzDateTime(interruption.end, false).format('YYYY-MM-DD') : ''
    )
    const [startTime, setStartTime] = useState(
        interruption.start ? tzDateTime(interruption.start, false).format('HH:mm') : ''
    )
    const [endTime, setEndTime] = useState(interruption.end ? tzDateTime(interruption.end, false).format('HH:mm') : '')
    const [reason, setReason] = useState(interruption.reason.id)

    const handleSubmit = async () => {
        setProcessing(true)

        // update or remove interruption and then fetch record again
        const start = tzDateTime(startDate + ' ' + startTime, true).format('YYYY-MM-DD HH:mm')
        const end = tzDateTime(endDate + ' ' + endTime, true).format('YYYY-MM-DD HH:mm')
        if (!removeRequest) {
            const request = await requests.patch(API.ATTENDANCE_INTERRUPTIONS + interruption.id + '/', {
                reason: reason,
                start: start,
                end: end,
            })

            if (request.status === 200) {
                const requestRecord = await requests.patch(
                    API.ATTENDANCE_BASE + 'records/' + record.id + '/?is_manager_edit=true'
                )
                if (requestRecord.status === 200) {
                    setRecords((prev) =>
                        prev.filter((item) => {
                            if (item.day === day) {
                                item.data['work_hours'] = requestRecord.response.work_hours
                                item.data['duration'] = requestRecord.response.duration
                                item.data['interruptions'] = requestRecord.response.interruptions
                            }
                            return item
                        })
                    )
                    onClose()
                }
            }
        } else {
            const request = await requests.post(API.ATTENDANCE_INTERRUPTIONS + interruption.id + '/delete/')

            if (request.status === 200) {
                const requestRecord = await requests.patch(API.ATTENDANCE_BASE + 'records/' + record.id + '/')
                if (requestRecord.status === 200) {
                    setRecords((prev) =>
                        prev.filter((item) => {
                            if (item.day === day) {
                                item.data['work_hours'] = requestRecord.response.work_hours
                                item.data['duration'] = requestRecord.response.duration
                                item.data['interruptions'] = requestRecord.response.interruptions
                            }
                            return item
                        })
                    )
                    onClose()
                }
            }
        }

        setProcessing(false)
    }

    const isDatesValid = () => {
        if (startDate !== '' && startTime !== '' && endDate !== '' && endTime !== '') {
            const dateFrom = moment(startDate).format('YYYY-MM-DD')
            const dateTo = moment(endDate).format('YYYY-MM-DD')
            const datetimeFrom = moment(dateFrom + ' ' + startTime)
            const datetimeTo = moment(dateTo + ' ' + endTime)

            if (moment(datetimeTo).isAfter(moment(datetimeFrom))) return true
            return false
        } else {
            return false
        }
    }

    const isValidForm = () => {
        if (removeRequest) return true
        if (reason === '') return false
        if (!isDatesValid()) return false
        if (startDate === '') return false
        if (endDate === '') return false
        if (startTime === '') return false
        if (endTime === '') return false

        return true
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group>
                <SuperField
                    as="checkbox"
                    label={t('update_record')}
                    checked={!removeRequest}
                    onChange={() => setRemoveRequest(false)}
                />
                <SuperField
                    as="checkbox"
                    label={t('remove_record')}
                    checked={removeRequest}
                    onChange={() => setRemoveRequest(true)}
                />
            </Form.Group>
            <Divider />

            {!removeRequest ? (
                <>
                    <SuperField
                        as="choice"
                        search
                        required
                        clearable={false}
                        label={t('reason')}
                        value={reason}
                        customOptions={interruptionReasons.map((item) => ({
                            key: item.id,
                            value: item.id,
                            text: item.title,
                        }))}
                        onChange={(e, { value }) => setReason(value)}
                    />

                    <Form.Group widths="equal">
                        <SuperField
                            required
                            as="datepicker"
                            label={t('date_from')}
                            value={startDate}
                            onChange={(e, { value }) => setStartDate(value)}
                        />
                        <SuperField
                            required
                            as="timepicker"
                            label={t('time_from')}
                            value={startTime}
                            onChange={(e, { value }) => setStartTime(value)}
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <SuperField
                            required
                            as="datepicker"
                            label={t('date_to')}
                            value={endDate}
                            onChange={(e, { value }) => setEndDate(value)}
                        />
                        <SuperField
                            required
                            as="timepicker"
                            label={t('time_to')}
                            value={endTime}
                            onChange={(e, { value }) => setEndTime(value)}
                        />
                    </Form.Group>

                    <Message info visible style={{ fontWeight: 'bold' }}>
                        <Icon name="information-circle" style={{ marginRight: '0.5rem' }} />
                        <span style={{ position: 'relative', top: '-0.1rem' }}>
                            {t('make_sure_your_date_and_time_range_is_correct')}
                        </span>
                    </Message>
                </>
            ) : (
                <Message info visible>
                    <Icon name="information-circle" style={{ marginRight: '0.5rem' }} />{' '}
                    <span style={{ position: 'relative', top: '-0.1rem' }}>
                        {t('attendance_interruption_removal_hint')}.
                    </span>
                </Message>
            )}

            <Divider />
            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit loading={processing} disabled={processing || !isValidForm()} />
            </Form.Field>
        </Form>
    )
}

export default WeekManagement
