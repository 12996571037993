import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import moment from 'moment'
// store
import { API } from '@store/config'
import { routes } from '@routes'
import { requests } from '@helpers/requests'
import { useHasPermissions } from '@helpers/hooks'
// components
import Icon from '@components/Icon'
import ListView from '@components/ListView'
import { Form, Button, Segment, Header, Divider, Grid } from 'semantic-ui-react'
import SuperField from '@components/forms/SuperField'
import PlannedCourseForm from '../components/PlannedCourseForm'
import CanView from '@components/perms/CanView'
import Action from '@components/general/Action'
import { FlexRow, FlexItem } from '@components/tables/FlexTable'

const CourseEvents = ({ employees }) => {
    const { t } = useTranslation()
    const canManageCourses = useHasPermissions(['courses.c_manage_all_courses'])

    const ParticipantList = ({ participants, course, plannedCourse, closed, setData }) => {
        const [closing, setClosing] = useState(false)
        const [loadingRecords, setLoadingRecords] = useState(true)
        const [participantsData, setParticipantsData] = useState([])
        const dateFormat = useSelector((state) => state.date_format)

        async function fetchRecordsForParticipants() {
            setLoadingRecords(true)
            for (const participant of participants) {
                const request = await requests.get(
                    API.COURSES_RECORDS +
                        `?query={id, course{id}, failed, made_on, valid_from, valid_until, person{id, fullname, profile_picture}}&person=${participant.id}&course=${course}`
                )

                if (request.response.length > 0) {
                    setParticipantsData((prevState) => {
                        return [
                            ...prevState,
                            {
                                ...request.response[0],
                                person: participant,
                                isProcessing: false,
                            },
                        ]
                    })
                } else {
                    setParticipantsData((prevState) => {
                        return [
                            ...prevState,
                            {
                                id: 0,
                                person: participant,
                                course: { id: course },
                                failed: false,
                                made_on: '',
                                valid_from: '',
                                valid_until: '',
                                isProcessing: false,
                            },
                        ]
                    })
                }
            }
            setLoadingRecords(false)
        }

        useEffect(() => {
            fetchRecordsForParticipants()
            // eslint-disable-next-line
        }, [])

        function setProcessing(id, status) {
            setParticipantsData(
                participantsData.filter((item) => {
                    if (item.person.id === id) {
                        item.isProcessing = status
                    }

                    return item
                })
            )
        }

        function validateDates(validFrom, validUntil) {
            if (validFrom !== '' && validUntil !== '') {
                if (moment(validFrom).isAfter(validUntil)) {
                    return t('invalid_date_should_be_higher')
                }
            }

            return false
        }

        function validateFilledData(data) {
            if (data.failed === '' || data.made_on === '' || data.valid_from === '' || data.valid_until === '') {
                return true
            }

            return false
        }

        function handleData(id, value, type) {
            setParticipantsData(
                participantsData.filter((item) => {
                    if (item.person.id === id) item[type] = value
                    return item
                })
            )
        }

        function handleApplyToAll() {
            setParticipantsData(
                participantsData.filter((item) => {
                    item.failed = participantsData[0].failed
                    item.valid_from = participantsData[0].valid_from
                    item.valid_until = participantsData[0].valid_until
                    item.made_on = participantsData[0].made_on
                    return item
                })
            )
        }

        function handleApplyToAllField(field) {
            setParticipantsData(
                participantsData.filter((item) => {
                    item[field] = participantsData[0][field]
                    return item
                })
            )
        }

        async function evaluateParticipant(record) {
            setProcessing(record.person.id, true)
            const result = await requests.post(API.COURSES_RECORDS, {
                made_on: record.made_on,
                valid_from: record.valid_from,
                valid_until: record.valid_until,
                failed: record.failed,
                course: record.course?.id,
                person: record.person.id,
            })

            if (result.status === 201) {
                setParticipantsData(
                    participantsData.map((item) => {
                        if (item.person.id === result.response.person.id) {
                            item.id = result.response.id
                        }

                        return item
                    })
                )
            }

            setProcessing(record.person.id, false)
        }

        async function updateRecord(record) {
            setProcessing(record.person.id, true)
            const result = await requests.put(API.COURSES_RECORDS + record.id + '/', {
                made_on: record.made_on,
                valid_from: record.valid_from,
                valid_until: record.valid_until,
                failed: record.failed,
                course: record.course?.id,
                person: record.person.id,
            })

            if (result.status === 200) {
                setParticipantsData(
                    participantsData.map((item) => {
                        if (item.id === result.response.id) {
                            return {
                                ...item,
                                id: result.response.id,
                            }
                        }
                        return item
                    })
                )
            }

            setProcessing(record.person.id, false)
        }

        function checkIfAllRecordsAreEvaluated() {
            let counter = 0
            for (const item of participantsData) {
                if (item.id !== 0) counter++
            }
            if (counter === participantsData.length) return true
            return false
        }

        async function closeCourse() {
            setClosing(true)
            const request = await requests.patch(API.PLANNED_COURSES + plannedCourse.id + '/', { is_closed: true })
            if (request.status === 200) {
                setData((prev) =>
                    prev.map((item) => {
                        if (item.id === request.response.id) {
                            item.is_closed = true
                        }

                        return item
                    })
                )
            }
            setClosing(false)
        }

        if (participantsData.length === 0)
            return (
                <FlexRow>
                    <FlexItem bold textAlign="center">
                        {' '}
                        {t('no_records')}{' '}
                    </FlexItem>
                </FlexRow>
            )

        return (
            <Segment loading={loadingRecords} style={{ border: 'none', boxShadow: 'none' }}>
                <Grid>
                    <Grid.Row columns={'2'} style={{ paddingBottom: 0, paddingTop: 0 }}>
                        <Grid.Column verticalAlign={'middle'}>
                            <Header as={'h3'} content={plannedCourse.course.title} />
                        </Grid.Column>
                        <Grid.Column textAlign="right">
                            {!closed && (
                                <CanView permissions={['courses.c_manage_all_courses']}>
                                    <Button
                                        disabled={!checkIfAllRecordsAreEvaluated()}
                                        loading={closing}
                                        style={{ background: 'var(--danger)', color: 'var(--white)' }}
                                        content={t('close_event')}
                                        onClick={() => closeCourse()}
                                    />
                                </CanView>
                            )}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Divider style={{ marginBottom: 0 }} />
                <Grid>
                    <Grid.Row columns={'2'}>
                        <Grid.Column
                            textAlign="left"
                            style={{ borderRight: '1px solid silver', paddingBottom: '1rem' }}
                        >
                            <Header as={'h4'}>
                                {t('start_date')}
                                <Header.Subheader>
                                    <div>
                                        {plannedCourse.start_date
                                            ? moment(plannedCourse.start_date).format(dateFormat)
                                            : '--'}
                                    </div>
                                </Header.Subheader>
                            </Header>
                        </Grid.Column>
                        <Grid.Column textAlign="left" style={{ paddingBottom: '1rem' }}>
                            <Header as={'h4'}>
                                {t('end_date')}
                                <Header.Subheader>
                                    <div>
                                        {plannedCourse.end_date
                                            ? moment(plannedCourse.end_date).format(dateFormat)
                                            : '--'}
                                    </div>
                                </Header.Subheader>
                            </Header>
                        </Grid.Column>
                        <Grid.Column textAlign="left" style={{ borderRight: '1px solid silver' }}>
                            <Header as={'h4'}>
                                {t('responsible_person')}
                                <Header.Subheader>
                                    <div> {plannedCourse.responsible_person?.fullname || '--'} </div>
                                </Header.Subheader>
                            </Header>
                        </Grid.Column>
                        <Grid.Column textAlign="left">
                            <Header as={'h4'}>
                                {t('participants')}
                                <Header.Subheader>
                                    <div>{plannedCourse.participants?.length || '--'}</div>
                                </Header.Subheader>
                            </Header>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Divider style={{ marginTop: 0 }} />
                {!loadingRecords && (
                    <ListView
                        as="table"
                        externalData={participantsData}
                        actions={[
                            {
                                as: 'custom',
                                type: 'button',
                                buttonText: t('evaluate'),
                                iconColor: 'var(--primary)',
                                onClick: (item) => evaluateParticipant(item),
                                isHidden: (item) => item.id !== 0,
                                disabled: validateFilledData(participantsData) || participantsData.isProcessing,
                            },
                            {
                                as: 'custom',
                                type: 'button',
                                buttonText: t('update'),
                                iconColor: 'var(--primary)',
                                onClick: (item) => updateRecord(item),
                                isHidden: (item) => item.id === 0,
                                disabled: validateFilledData(participantsData) || participantsData.isProcessing,
                            },
                            {
                                as: 'custom',
                                type: 'icon',
                                tooltip: t('apply_to_all'),
                                icon: 'chevron-down-circle-outline',
                                onClick: () => handleApplyToAll(),
                                isHidden: (item) => participantsData[0] !== item,
                            },
                        ]}
                        tableHeaders={[
                            { title: t('participants') },
                            {
                                title: t('status'),
                                action: participants.length > 1 && (
                                    <CanView permissions={['courses.c_manage_all_courses']}>
                                        <Action
                                            as="custom"
                                            type="icon"
                                            icon="chevron-down-circle-outline"
                                            size="large"
                                            onClick={() => handleApplyToAllField('failed')}
                                            tooltip={t('apply_to_all')}
                                            iconColor="var(--dark)"
                                            paddingLeft="1rem"
                                            paddingRight="1rem"
                                        />
                                    </CanView>
                                ),
                            },
                            {
                                title: t('made_on'),
                                action: participants.length > 1 && (
                                    <CanView permissions={['courses.c_manage_all_courses']}>
                                        <Action
                                            as="custom"
                                            type="icon"
                                            icon="chevron-down-circle-outline"
                                            size="large"
                                            onClick={() => handleApplyToAllField('made_on')}
                                            tooltip={t('apply_to_all')}
                                            iconColor="var(--dark)"
                                            paddingLeft="1rem"
                                            paddingRight="1rem"
                                        />
                                    </CanView>
                                ),
                            },
                            {
                                title: t('valid_from'),
                                action: participants.length > 1 && (
                                    <CanView permissions={['courses.c_manage_all_courses']}>
                                        <Action
                                            as="custom"
                                            type="icon"
                                            icon="chevron-down-circle-outline"
                                            size="large"
                                            onClick={() => handleApplyToAllField('valid_from')}
                                            tooltip={t('apply_to_all')}
                                            iconColor="var(--dark)"
                                            paddingLeft="1rem"
                                            paddingRight="1rem"
                                        />
                                    </CanView>
                                ),
                            },
                            {
                                title: t('valid_until'),
                                action: participants.length > 1 && (
                                    <CanView permissions={['courses.c_manage_all_courses']}>
                                        <Action
                                            as="custom"
                                            type="icon"
                                            icon="chevron-down-circle-outline"
                                            size="large"
                                            onClick={() => handleApplyToAllField('valid_until')}
                                            tooltip={t('apply_to_all')}
                                            iconColor="var(--dark)"
                                            paddingLeft="1rem"
                                            paddingRight="1rem"
                                        />
                                    </CanView>
                                ),
                            },
                        ]}
                        renderCells={(participant) => [
                            { content: participant.person.fullname },
                            {
                                content: (
                                    <Button.Group size="tiny" basic style={{ borderRadius: '0' }}>
                                        <Button
                                            disabled={!canManageCourses}
                                            active={!participant.failed}
                                            onClick={() => handleData(participant.person.id, false, 'failed')}
                                        >
                                            <Icon name="checkmark-outline" color="green" />
                                        </Button>
                                        <Button
                                            disabled={!canManageCourses}
                                            active={participant.failed}
                                            onClick={() => handleData(participant.person.id, true, 'failed')}
                                        >
                                            <Icon name="close-outline" color="red" />
                                        </Button>
                                    </Button.Group>
                                ),
                            },
                            {
                                content: (
                                    <SuperField
                                        disabled={!canManageCourses}
                                        style={{
                                            width: '7.5rem',
                                            height: '2.5rem',
                                            fontSize: '0.8rem',
                                            marginTop: '1.5rem',
                                        }}
                                        as="datepicker"
                                        value={participant.made_on}
                                        onChange={(e, { value }) => handleData(participant.person.id, value, 'made_on')}
                                    />
                                ),
                            },
                            {
                                content: (
                                    <SuperField
                                        disabled={!canManageCourses}
                                        style={{
                                            width: '7.5rem',
                                            height: '2.5rem',
                                            fontSize: '0.8rem',
                                            marginTop: '1.5rem',
                                        }}
                                        as="datepicker"
                                        value={participant.valid_from}
                                        onChange={(e, { value }) =>
                                            handleData(participant.person.id, value, 'valid_from')
                                        }
                                    />
                                ),
                            },
                            {
                                content: (
                                    <SuperField
                                        disabled={!canManageCourses}
                                        style={{
                                            width: '7.5rem',
                                            height: '2.5rem',
                                            fontSize: '0.8rem',
                                            marginTop: '1.5rem',
                                        }}
                                        as="datepicker"
                                        error={validateDates(participant.valid_from, participant.valid_until)}
                                        value={participant.valid_until}
                                        onChange={(e, { value }) =>
                                            handleData(participant.person.id, value, 'valid_until')
                                        }
                                    />
                                ),
                            },
                        ]}
                    />
                )}
            </Segment>
        )
    }

    return (
        <>
            <ListView
                as="table"
                allowSearch
                allowInfoBelow
                endpoint={API.PLANNED_COURSES}
                // module="course_events"
                initialFilters={{
                    course: '',
                    responsible_person: '',
                    start_date: '',
                    end_date: '',
                    is_closed: 'false',
                }}
                listAdditionActions={() => [
                    {
                        as: 'filter',
                        index: 0,
                        name: t('opened'),
                        filters: {
                            is_closed: false,
                        },
                    },
                    {
                        as: 'filter',
                        index: 1,
                        name: t('closed'),
                        filters: {
                            is_closed: true,
                        },
                    },
                ]}
                renderFilterFields={(filters, setFilters) => (
                    <>
                        <SuperField
                            search
                            as="choice"
                            label={t('course')}
                            endpoint={API.COURSES + '?query={id, title}'}
                            value={filters.course}
                            text="title"
                            onChange={(e, { value }) => setFilters({ ...filters, course: value })}
                        />
                        <SuperField
                            as="choice"
                            search
                            label={t('responsible_person')}
                            value={filters.responsible_person}
                            customOptions={employees}
                            onChange={(e, { value }) => setFilters({ ...filters, responsible_person: value })}
                        />
                        <Form.Group widths="equal">
                            <SuperField
                                fluid
                                as="datepicker"
                                label={t('date_start')}
                                placeholder={t('date_start')}
                                value={filters.start_date}
                                onChange={(e, { value }) => setFilters({ ...filters, start_date: value })}
                            />
                            <SuperField
                                fluid
                                as="datepicker"
                                label={t('end_date')}
                                placeholder={t('end_date')}
                                value={filters.end_date}
                                onChange={(e, { value }) => setFilters({ ...filters, end_date: value })}
                            />
                        </Form.Group>
                    </>
                )}
                actions={[
                    {
                        as: 'modal',
                        type: 'add',
                        name: t('create_event'),
                        modal: <PlannedCourseForm employees={employees} />,
                        permissions: ['courses.c_manage_all_courses'],
                    },
                    {
                        type: 'sidebar',
                        icon: 'eye-outline',
                        name: t('show_detail'),
                        customIconColor: 'var(--primary)',
                        content: (item, setData) => (
                            <ParticipantList
                                closed={item.is_closed}
                                participants={item.participants}
                                course={item.course?.id}
                                plannedCourse={item}
                                setData={setData}
                            />
                        ),
                        permissions: [],
                    },
                    {
                        name: t('edit'),
                        type: 'edit',
                        as: 'modal',
                        icon: 'pencil-outline',
                        customIconColor: 'var(--dark)',
                        permissions: ['courses.c_manage_all_courses'],
                        modal: (item) => <PlannedCourseForm plannedCourse={item} employees={employees} />,
                    },
                    {
                        name: t('delete'),
                        type: 'delete',
                        as: 'delete',
                        text: t('are_you_sure'),
                        permissions: ['courses.c_manage_all_courses'],
                    },
                ]}
                tableHeaders={[
                    { title: t('course'), orderBy: 'title' },
                    { title: t('responsible_person') },
                    { title: t('participants') },
                    { title: t('start_date') },
                    { title: t('end_date') },
                    { title: t('status') },
                ]}
                renderCells={(plannedCourse) => [
                    { content: plannedCourse.course?.title },
                    {
                        content: (
                            <>
                                {plannedCourse.responsible_person?.fullname ? (
                                    <Link
                                        className="ref-link"
                                        to={routes.EMPLYOEES_DETAIL + plannedCourse.responsible_person?.id}
                                        target="_blank"
                                    >
                                        {plannedCourse.responsible_person?.fullname}
                                    </Link>
                                ) : (
                                    '--'
                                )}
                            </>
                        ),
                    },
                    {
                        content: (
                            <>
                                <Icon name="people-outline" style={{ marginRight: '1rem' }} />
                                <span style={{ position: 'relative', top: '-0.2rem' }}>
                                    {plannedCourse.participants.length}
                                </span>
                            </>
                        ),
                    },
                    { content: plannedCourse.start_date },
                    { content: plannedCourse.end_date },
                    {
                        content: (
                            <>
                                {plannedCourse.is_closed ? (
                                    <span style={{ color: 'var(--danger)' }}>{t('closed')}</span>
                                ) : (
                                    <span style={{ color: 'var(--success)' }}>{t('opened')}</span>
                                )}
                            </>
                        ),
                    },
                ]}
            />
        </>
    )
}

export default CourseEvents
