import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import moment from 'moment'
// store
import { routes } from '@routes'
import { API } from '@store/config'
import { requests } from '@helpers/requests'
import { thousandsSeparators } from '@helpers/functions'
import { useHasPermissions } from '@helpers/hooks'
// components
import ListView from '@components/ListView'
import CanView from '@components/perms/CanView'
import DatePresets from '@components/DatePresets'
import SuperField from '@components/forms/SuperField'
import LabelsList from '@components/lists/LabelsList'
import { Form, Segment /*Divider, Header*/ } from 'semantic-ui-react'
// module components
import CostCenterStatistic from '../components/stats/CostCenterStatistic'
import CostCenterForm from '../components/main/CostCenterForm'
import CostCenterAllowedUsersManagement from '../components/main/CostCenterAllowedUsersManagement'
import ListStatsView from '../components/stats/ListStatsView'

const CCListView = () => {
    const { t } = useTranslation()
    const currentUser = useSelector((state) => state.user)
    const canManagePerm = useHasPermissions(['cost_centers.c_manage_cost_centers'])
    const firstDay = moment().startOf('month').format('YYYY-MM-DD')
    const lastDay = moment().endOf('month').format('YYYY-MM-DD')

    const [allowedUsers, setAllowedUsers] = useState([])
    const [loading, setLoading] = useState(true)

    const [visible, setVisible] = useState(false)
    const [data, setData] = useState()

    const initialFilters = {
        code: '',
        unit: '',
        currency: '',
        date_from: firstDay,
        date_to: lastDay,
        monthStart: 1,
        monthEnd: 12,
        year: moment().format('YYYY'),
        projects: [],
        cost_center_tags: [],
        cost_centers: '',
        cost_center: '',
    }

    const [sharedFilters, setSharedFilters] = useState(initialFilters)

    useEffect(() => {
        async function fetchAllowedUsers() {
            setLoading(true)
            const request = await requests.get(API.COST_CENTERS_ALLOWED_USERS + `?user=${currentUser.id}`)
            if (request.status === 200) {
                setAllowedUsers(request.response)
            }
            setLoading(false)
        }

        fetchAllowedUsers()
        // eslint-disable-next-line
    }, [])

    const renderIncome = (item) => {
        if (sharedFilters.currency !== '')
            return parseFloat(item.converted_incomes).toFixed(2) + ' ' + sharedFilters.currency
        if ([0, undefined].includes(item.total_incomes?.length) || item.total_incomes === 0) return 0

        return item.total_incomes?.map((income) => (
            <>
                {' '}
                {thousandsSeparators(parseFloat(income.sum).toFixed(2)) + ' ' + income.currency} <br />{' '}
            </>
        ))
    }

    const renderExpense = (item) => {
        if (sharedFilters.currency !== '')
            return parseFloat(item.converted_expenses).toFixed(2) + ' ' + sharedFilters.currency
        if ([0, undefined].includes(item.total_expenses?.length) || item.total_expenses === 0) return 0

        return item.total_expenses?.map((expense) => (
            <>
                {' '}
                {thousandsSeparators(parseFloat(expense.sum).toFixed(2)) + ' ' + expense.currency} <br />{' '}
            </>
        ))
    }

    const renderDifference = (item) => {
        if (sharedFilters.currency !== '')
            return (
                parseFloat(item.converted_incomes - item.converted_expenses).toFixed(2) + ' ' + sharedFilters.currency
            )
        if ([0, undefined].includes(item.total_expenses?.length) && [0, undefined].includes(item.total_incomes?.length))
            return 0

        const income_currencies = item.total_incomes === 0 ? [] : item.total_incomes?.map((item) => item.currency)
        const expense_currencies = item.total_expenses === 0 ? [] : item.total_expenses?.map((item) => item.currency)
        const merged_arrays = income_currencies?.concat(expense_currencies)
        const currencies = merged_arrays?.filter((item, pos) => merged_arrays?.indexOf(item) === pos)

        return currencies?.map((currency) => {
            let difference = 0
            // let the magic happens here - return undefined if not found or founded object
            const income =
                item.total_incomes === 0
                    ? undefined
                    : item.total_incomes.find((item) => item.currency === currency)?.sum || 0
            const expense =
                item.total_expenses === 0
                    ? undefined
                    : item.total_expenses.find((item) => item.currency === currency)?.sum || 0
            difference = parseFloat(income + (expense > 0 ? expense * -1 : 0)).toFixed(2)

            return (
                <span style={{ color: difference > 0 ? 'var(--success)' : 'var(--danger)' }}>
                    {' '}
                    {thousandsSeparators(difference) + ' ' + currency} <br />
                </span>
            )
        })
    }

    const renderBudget = (item) => {
        if (sharedFilters.currency !== '')
            return parseFloat(item.converted_budgets).toFixed(2) + ' ' + sharedFilters.currency

        if (item.total_budgets.length === 0) return 0

        return item.total_budgets.map((expense) => (
            <>
                {' '}
                {thousandsSeparators(parseFloat(expense.sum).toFixed(2)) + ' ' + expense.currency} <br />{' '}
            </>
        ))
    }

    // eslint-disable-next-line
    const months = [
        { key: 1, value: 1, text: t('january') },
        { key: 2, value: 2, text: t('february') },
        { key: 3, value: 3, text: t('march') },
        { key: 4, value: 4, text: t('april') },
        { key: 5, value: 5, text: t('may') },
        { key: 6, value: 6, text: t('june') },
        { key: 7, value: 7, text: t('july') },
        { key: 8, value: 8, text: t('august') },
        { key: 9, value: 9, text: t('september') },
        { key: 10, value: 10, text: t('october') },
        { key: 11, value: 11, text: t('november') },
        { key: 12, value: 12, text: t('december') },
    ]

    // eslint-disable-next-line
    const isValidMonthRange = () => {
        return sharedFilters.monthStart < sharedFilters.monthEnd
    }

    return (
        <CanView permissions={['cost_centers.c_view_cost_centers']} redirect>
            <Segment
                loading={loading}
                style={{
                    padding: 0,
                    background: 'transparent',
                    boxShadow: 'none',
                    border: 'none',
                    marginBottom: '1rem',
                    marginTop: '0',
                }}
            >
                {loading && (
                    <p style={{ textAlign: 'center', color: 'var(--dark)', paddingTop: '6rem' }}>
                        {' '}
                        {t('message_performing_verification')}{' '}
                    </p>
                )}
                {!loading && (
                    <>
                        <CanView permissions={['reports.c_view_cost_center_reports']}>
                            <CostCenterStatistic filters={sharedFilters} data={data} setVisible={setVisible} />
                        </CanView>

                        <ListView
                            as="table"
                            allowSearch
                            isSoftDeleteAllowed
                            forceLoading={loading}
                            forceVisibility={visible}
                            endpoint={API.COST_CENTERS}
                            // module="cost_centers"
                            showStatistic
                            visibleStatsOnRender
                            loadingMessage={t('loading_list')}
                            onResponse={(response, filters) => {
                                setData(response)
                                setSharedFilters(filters)
                            }}
                            onVisibilityChange={(visibility) => {
                                if (visibility !== visible) setVisible(visibility)
                            }}
                            actionsCellWidth="2"
                            initialFilters={initialFilters}
                            exports={[
                                {
                                    endpoint: API.EXPORTS + 'dynamic_cost_centers/',
                                    permissions: ['cost_centers.c_view_cost_centers'],
                                    apply_filters: true,
                                    type: 'xlsx',
                                    filename: 'cost_centers',
                                },
                            ]}
                            tableHeaders={[
                                { title: t('code'), orderBy: 'code' },
                                { title: t('title'), orderBy: 'title' },
                                { title: t('unit'), orderBy: 'unit__name' },
                                { title: t('labels') },
                                { title: t('projects') },
                                { title: t('income') },
                                { title: t('expense') },
                                { title: t('difference') },
                                { title: t('budget') },
                            ]}
                            renderStatisticView={(filters, data, total, params, setTotal, setData, setFilters) => (
                                <ListStatsView filters={filters} data={data} total={total} params={params} />
                            )}
                            renderFilterFields={(filters, setFilters) => (
                                <>
                                    <Form.Group widths="equal">
                                        <SuperField
                                            search
                                            as="datepicker"
                                            label={t('date_from')}
                                            value={filters.date_from}
                                            onChange={(e, { value }) => {
                                                setFilters((prev) => ({ ...prev, date_from: value }))
                                                setSharedFilters((prev) => ({ ...prev, date_from: value }))
                                            }}
                                        />
                                        <SuperField
                                            search
                                            as="datepicker"
                                            label={t('date_to')}
                                            value={filters.date_to}
                                            onChange={(e, { value }) => {
                                                setFilters((prev) => ({ ...prev, date_to: value }))
                                                setSharedFilters((prev) => ({ ...prev, date_to: value }))
                                            }}
                                        />
                                    </Form.Group>

                                    <DatePresets
                                        open={true}
                                        from={filters.date_from}
                                        to={filters.date_to}
                                        onSelection={(firstMonthDay, lastMonthDay, year) => {
                                            setFilters((prev) => ({
                                                ...prev,
                                                date_from: firstMonthDay,
                                                date_to: lastMonthDay,
                                                year: year,
                                            }))
                                            setSharedFilters((prev) => ({
                                                ...prev,
                                                date_from: firstMonthDay,
                                                date_to: lastMonthDay,
                                                year: year,
                                            }))
                                        }}
                                    />

                                    <Form.Group widths="equal">
                                        <SuperField
                                            search
                                            as="choice"
                                            label={t('currency')}
                                            type="currency_codes"
                                            value={filters.currency}
                                            onChange={(e, { value }) => {
                                                setFilters((prev) => ({ ...prev, currency: value }))
                                                setSharedFilters((prev) => ({ ...prev, currency: value }))
                                            }}
                                        />

                                        <SuperField
                                            text="name"
                                            as="choice"
                                            label={t('unit')}
                                            value={filters.unit}
                                            endpoint={API.UNITS + '?query={id, name}&only_from_active_company=true'}
                                            onChange={(e, { value }) => {
                                                setFilters((prev) => ({ ...prev, unit: value }))
                                                setSharedFilters((prev) => ({ ...prev, unit: value }))
                                            }}
                                        />
                                    </Form.Group>

                                    <SuperField
                                        as="input"
                                        label={t('code')}
                                        value={filters.code}
                                        onChange={(e, { value }) => {
                                            setFilters((prev) => ({ ...prev, code: value }))
                                            setSharedFilters((prev) => ({ ...prev, code: value }))
                                        }}
                                    />

                                    <SuperField
                                        as="choice"
                                        multiple
                                        search
                                        text="name"
                                        label={t('projects')}
                                        value={filters.projects}
                                        endpoint={API.PROJECTS + `?query={id,name}`}
                                        onChange={(e, { value }) => {
                                            setFilters((prev) => ({ ...prev, projects: value }))
                                            setSharedFilters((prev) => ({ ...prev, projects: value }))
                                        }}
                                    />

                                    <SuperField
                                        as="choice"
                                        multiple
                                        search
                                        text="title"
                                        label={t('cost_centers')}
                                        value={(() => {
                                            let value = []
                                            if (filters.cost_centers !== '') {
                                                let splitValues = filters.cost_centers.split(',')
                                                for (let i = 0; i < splitValues.length; i++) {
                                                    if (splitValues[i] !== '') {
                                                        value.push(parseInt(splitValues[i]))
                                                    }
                                                }
                                            }

                                            return value || []
                                        })()}
                                        endpoint={API.COST_CENTERS + `?query={id,title}`}
                                        onChange={(e, { value }) => {
                                            setFilters((prev) => ({ ...prev, cost_centers: value.toString() }))
                                            setSharedFilters((prev) => ({ ...prev, cost_centers: value.toString() }))
                                        }}
                                    />

                                    <SuperField
                                        as="choice"
                                        multiple
                                        search
                                        text="name"
                                        label={t('labels')}
                                        value={filters.cost_center_tags}
                                        endpoint={API.COST_CENTERS + `labels/?query={id,name}`}
                                        onChange={(e, { value }) => {
                                            setFilters((prev) => ({ ...prev, cost_center_tags: value }))
                                            setSharedFilters((prev) => ({ ...prev, cost_center_tags: value }))
                                        }}
                                    />
                                </>
                            )}
                            renderCells={(cc) => [
                                { content: cc?.code || '' },
                                { content: cc.title },
                                { content: cc.unit?.name },
                                {
                                    content: (
                                        <LabelsList
                                            rowID={cc.id}
                                            labels={cc?.tags}
                                            endpoint={API.COST_CENTERS}
                                            // additionalFilters="&lead_tag=true"
                                            // mark="lead_tag"
                                            endpointLabels={API.COST_CENTERS + 'labels/'}
                                            label_key="tags"
                                        />
                                    ),
                                },
                                { content: cc.projects.length },
                                { content: renderIncome(cc) },
                                { content: renderExpense(cc) },
                                { content: renderDifference(cc) },
                                { content: renderBudget(cc) },
                            ]}
                            actions={[
                                {
                                    as: 'modal',
                                    type: 'add',
                                    name: t('create_cost_center'),
                                    modal: <CostCenterForm />,
                                },
                                {
                                    as: 'link',
                                    type: 'detail',
                                    icon: 'eye-outline',
                                    name: t('view_detail'),
                                    redirect: (item) => routes.COST_CENTERS + 'detail/' + item.id,
                                    isHidden: (item) =>
                                        allowedUsers.find((user) => user.cost_center.id === item.id)?.can_view !==
                                            true && canManagePerm !== true,
                                },
                                {
                                    as: 'modal',
                                    type: 'custom',
                                    icon: 'settings-outline',
                                    modalSize: 'large',
                                    name: t('allowed_users'),
                                    modal: (item) => (
                                        <CostCenterAllowedUsersManagement currentUser={currentUser} costCenter={item} />
                                    ),
                                    permissions: ['cost_centers.c_manage_cost_centers'],
                                },
                                {
                                    as: 'modal',
                                    type: 'edit',
                                    name: t('edit'),
                                    modalSize: 'small',
                                    modal: <CostCenterForm />,
                                    isHidden: (item) =>
                                        allowedUsers.find((user) => user.cost_center.id === item.id)?.can_manage !==
                                            true && canManagePerm !== true,
                                },
                                {
                                    name: t('delete'),
                                    type: 'delete',
                                    as: 'delete',
                                    text: t('do_you_really_want_to_delete_cost_center'),
                                    isHidden: (item) =>
                                        allowedUsers.find((user) => user.cost_center.id === item.id)?.can_delete !==
                                            true && canManagePerm !== true,
                                },
                            ]}
                        />
                    </>
                )}
            </Segment>
        </CanView>
    )
}

export default CCListView
