import React from 'react'
import { useTranslation } from 'react-i18next'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
// components
import ListView from '@components/ListView'
import CanView from '@components/perms/CanView'
import { FlexRow, FlexItem } from '@components/tables/FlexTable'
import { Header, Checkbox, Container } from 'semantic-ui-react'

const AbsenceList = () => {
    const { t } = useTranslation()

    const toggleAbsence = async (itemID, active, setData, setTotal, filters) => {
        const result = await requests.patch(API.ABSENCE_TYPES + itemID + '/', { is_active: !active })
        if (result.status === 200) {
            setData((prevState) =>
                prevState.filter((item) => {
                    if (item.id === itemID) {
                        item.is_active = !active
                    }
                    return item
                })
            )

            if (filters?.is_active === true || filters?.is_active === false) {
                setTotal((prev) => prev - 1)
                setData((prev) => prev.filter((item) => item.id !== itemID))
            } else {
                setData((prev) =>
                    prev.map((item) => {
                        if (item.id === itemID) {
                            item.is_active = !active
                        }
                        return item
                    })
                )
            }
        }
    }

    return (
        <CanView permissions={['attendance.c_manage_absence_types']} redirect>
            <Container fluid>
                <div style={{ marginTop: '1rem' }}></div>
                <ListView
                    as="panel"
                    allowSearch
                    endpoint={API.ABSENCE_TYPES}
                    initialFilters={{
                        is_active: true,
                        ordering: '-id',
                    }}
                    listAdditionActions={() => [
                        {
                            as: 'filter',
                            index: 0,
                            name: t('active'),
                            filters: {
                                is_active: true,
                            },
                        },
                        {
                            as: 'filter',
                            index: 1,
                            name: t('inactive'),
                            filters: {
                                is_active: false,
                            },
                        },
                        {
                            as: 'filter',
                            index: 2,
                            name: t('all'),
                            filters: {
                                is_active: '',
                            },
                        },
                    ]}
                    renderPanel={(item, setData, setTotal, filters) => (
                        <div style={{ marginBottom: '0.3rem' }}>
                            <FlexRow key={item.id} borders padding="0.5rem" paddingLeft="0" background="var(--white)">
                                <FlexItem>
                                    <Header as="h4">
                                        <Header.Content>{item.title}</Header.Content>
                                    </Header>
                                </FlexItem>
                                <FlexItem basis="20%" textAlign="right">
                                    <Checkbox
                                        toggle
                                        checked={item.is_active}
                                        onChange={() =>
                                            toggleAbsence(item.id, item.is_active, setData, setTotal, filters)
                                        }
                                    />
                                </FlexItem>
                            </FlexRow>
                        </div>
                    )}
                />
            </Container>
        </CanView>
    )
}

export default AbsenceList
