import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-semantic-toasts'
// store
import { API } from '@store/config'
import { icons } from '@store/icons'
import { routes } from '@store/routes'
import { requests, fileDownload } from '@helpers/requests'
// components
import Icon from '@components/Icon'
import Notes from '@components/Notes'
import ListView from '@components/ListView'
import Action from '@components/general/Action'
import CanView from '@components/perms/CanView'
import { Label, Button } from 'semantic-ui-react'
// import Action from '@components/general/Action';
import BreadcrumbNav from '@components/BreadcrumbNav'
import GenerateDocuments from '@components/GenerateDocuments'
import SuperDuperModal from '@components/modals/SuperDuperModal'
// specific components
import Documents from './views/Documents'
import ContractForm from './components/ContractForm'
import ContractFilters from './components/ContractFilters'
import ContractSignForm from './components/ContractSignForm'
import ContractTerminationForm from './components/ContractTerminationForm'
import ImportContractVacationStatus from './components/ImportContractVacationStatus'
import ContractStats from './components/ContractStats'

function convertSelectedToIds(items) {
    let ids = ''
    for (let i = 0; i < items.length; i++) {
        if (items.length - 1 === i) {
            ids += items[i].id
        } else {
            ids += items[i].id + ','
        }
    }

    return ids
}

const Contracts = () => {
    const { t } = useTranslation()
    const dateFormat = useSelector((state) => state.date_format)

    const [employers, setEmployers] = useState([])
    const [employees, setEmployees] = useState([])
    const [positions, setPositions] = useState([])
    const [forceModalOpen, setForceModalOpen] = useState(0)

    useEffect(() => {
        async function fetchEmployees() {
            const request = await requests.get(API.EMPLOYEES + '?only_basic_info=true&is_active=true')
            if (request.status === 200) setEmployees(request.response)
        }

        async function fetchEmployers() {
            const request = await requests.get(API.BUSINESS_DETAIL + '?query={id, name}&is_employer=true')
            if (request.status === 200) setEmployers(request.response)
        }

        async function fetchPositions() {
            const request = await requests.get(
                API.JOB_POSITIONS + '?query={id, title, job_contract{work_level}, bonuses{id, title}}'
            )
            if (request.status === 200) setPositions(request.response)
        }

        fetchEmployees()
        fetchEmployers()
        fetchPositions()
    }, [])

    const handleExportVacationStatuses = async (queryParams, selected) => {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        const filename = 'xlsx-export.xlsx'
        const endpoint = API.EXPORTS + 'dynamic_vacation_statuses/'

        toast({
            type: 'success',
            icon: 'check circle',
            title: t('generating_export') + '...',
            description: t('wait_a_moment_until_its_finished') + '.',
            animation: 'bounce',
            time: 3000,
        })

        // when there are no selected invoices then export all based on filters specified
        if (selected.length === 0) {
            await fileDownload('GET', endpoint + '?query={*}' + queryParams, fileType, filename)
        } else {
            await fileDownload('GET', endpoint + '?ids=' + convertSelectedToIds(selected), fileType, filename)
        }
    }

    return (
        <CanView
            redirect
            permissions={[
                'contracts.c_view_all_contracts',
                'contracts.c_view_user_contracts',
                'company.c_view_only_assigned_unit_employee_contracts',
                'common.c_view_only_agency_employees',
                'common.c_view_only_internal_employees',
                'common.c_view_only_external_employees',
            ]}
        >
            <BreadcrumbNav items={[{ name: t('contracts'), icon: icons.CONTRACTS, href: '' }]} mBottom="0rem" />
            <ListView
                as="table"
                allowSearch
                allowSelection
                // module="contracts"
                isSoftDeleteAllowed
                showStatistic
                // visibleStatsOnRender
                endpoint={API.CONTRACTS}
                actionsCellWidth="2"
                bulkActions={(selected) => [
                    {
                        as: 'modal',
                        name: t('generate_documents'),
                        icon: 'document-text-outline',
                        modal: <GenerateDocuments source="contracts.Contract" selected={selected} />,
                    },
                ]}
                renderStatisticView={(filters, data, total, params, setFilters, setTotal, setData, fetchData) => (
                    <ContractStats listData={data} />
                )}
                settings={[
                    {
                        permissions: [
                            'contracts.c_view_all_contracts',
                            'company.c_view_only_assigned_unit_employee_contracts',
                        ],
                        as: 'onClick',
                        name: t('export_vacation_statuses'),
                        icon: 'download-outline',
                        onClick: (queryParams, selected) => handleExportVacationStatuses(queryParams, selected),
                    },
                    {
                        permissions: [
                            'contracts.c_manage_all_contracts',
                            'company.c_manage_only_assigned_unit_employee_contracts',
                        ],
                        as: 'modal',
                        size: 'tiny',
                        centered: false,
                        name: t('import_vacation_statuses'),
                        icon: 'share-outline',
                        modal: <ImportContractVacationStatus />,
                        // onClick: (queryParams, selected) => handleExportVacationStatuses(queryParams, selected),
                    },
                ]}
                exports={[
                    {
                        endpoint: API.EXPORTS + 'dynamic_contracts/',
                        permissions: [
                            'contracts.c_view_all_contracts',
                            'contracts.c_view_user_contracts',
                            'company.c_view_only_assigned_unit_employee_contracts',
                            'common.c_view_only_agency_employees',
                            'common.c_view_only_internal_employees',
                            'common.c_view_only_external_employees',
                        ],
                        apply_filters: true,
                        type: 'xlsx',
                        filename: 'contracts',
                    },
                ]}
                listAdditionActions={(data, setData, total, setTotal, response) => [
                    {
                        as: 'filter',
                        index: 0,
                        name: t('active'),
                        filters: {
                            status: '1',
                        },
                        count: response?.active_count || 0,
                    },
                    {
                        as: 'filter',
                        index: 1,
                        name: t('notice_period'),
                        filters: {
                            status: '3',
                        },
                        count: response?.in_notice_period_count || 0,
                    },
                    {
                        as: 'filter',
                        index: 2,
                        name: t('draft'),
                        filters: {
                            status: '4',
                        },
                        count: response?.is_draft_count || 0,
                    },
                    {
                        as: 'filter',
                        index: 3,
                        name: t('archived_contracts'),
                        filters: {
                            status: '2',
                        },
                        count: response?.terminated_count || 0,
                    },
                    {
                        as: 'filter',
                        index: 4,
                        name: t('all'),
                        filters: {
                            status: '',
                        },
                        count: response?.total || 0,
                    },
                ]}
                actions={[
                    {
                        as: 'modal',
                        type: 'add',
                        name: t('create_contract'),
                        modal: <ContractForm employees={employees} employers={employers} positions={positions} />,
                        permissions: [
                            'contracts.c_manage_all_contracts',
                            'company.c_manage_only_assigned_unit_employee_contracts',
                            'common.c_manage_only_agency_employees',
                            'common.c_manage_only_internal_employees',
                            'common.c_manage_only_external_employees',
                        ],
                    },
                    {
                        as: 'modal',
                        type: 'custom',
                        modalSize: 'tiny',
                        icon: 'create-outline',
                        customIconColor: 'var(--dark)',
                        name: t('update_date_of_signature'),
                        permissions: [
                            'contracts.c_manage_all_contracts',
                            'company.c_manage_only_assigned_unit_employee_contracts',
                            'common.c_manage_only_agency_employees',
                            'common.c_manage_only_internal_employees',
                            'common.c_manage_only_external_employees',
                        ],
                        modal: <ContractSignForm />,
                    },
                    {
                        name: t('edit'),
                        type: 'edit',
                        as: 'modal',
                        icon: 'pencil-outline',
                        customIconColor: 'var(--dark)',
                        permissions: [
                            'contracts.c_manage_all_contracts',
                            'company.c_manage_only_assigned_unit_employee_contracts',
                            'common.c_manage_only_agency_employees',
                            'common.c_manage_only_internal_employees',
                            'common.c_manage_only_external_employees',
                        ],
                        modal: <ContractForm employees={employees} employers={employers} positions={positions} />,
                    },
                    {
                        name: t('termination'),
                        type: 'custom',
                        as: 'modal',
                        icon: 'close-circle-outline',
                        customIconColor: 'var(--danger)',
                        isHidden: (item) => [2, 3, 4].includes(item?.status),
                        permissions: [
                            'contracts.c_manage_all_contracts',
                            'company.c_manage_only_assigned_unit_employee_contracts',
                            'common.c_manage_only_agency_employees',
                            'common.c_manage_only_internal_employees',
                            'common.c_manage_only_external_employees',
                        ],
                        modal: <ContractTerminationForm />,
                    },
                    {
                        name: t('delete'),
                        type: 'delete',
                        as: 'delete',
                        text: t('delete_contract_message'),
                        permissions: [
                            'contracts.c_delete_all_contracts',
                            'company.c_manage_only_assigned_unit_employee_contracts',
                            'common.c_delete_only_agency_employees',
                            'common.c_delete_only_external_employees',
                            'common.c_delete_only_internal_employees',
                        ],
                    },
                ]}
                tableHeaders={[
                    { title: t('custom_id'), orderBy: 'custom_id' },
                    { title: t('employee'), orderBy: 'personal_information__person__lastname' },
                    { title: t('employer'), orderBy: 'employer__name' },
                    { title: t('contract_type'), orderBy: 'type__title' },
                    { title: t('fond'), orderBy: 'fond' },
                    { title: t('salary'), orderBy: 'salary' },
                    { title: t('vacation_status'), orderBy: 'available_vacation_fond' },
                    { title: t('starts'), orderBy: 'valid_from' },
                    { title: t('ends'), orderBy: 'valid_until' },
                    { title: t('notes') },
                    { title: t('documents') },
                    { title: t('status') },
                ]}
                initialFilters={{
                    type: '',
                    custom_filter: '',
                    employee: '',
                    employer: '',
                    unit: '',
                    position_group: '',
                    positions: [],
                    valid_until_before: '',
                    valid_until_after: '',
                    valid_from_before: '',
                    valid_from_after: '',
                    trial_period_before: '',
                    trial_period_after: '',
                    is_valid_until_none: '',
                    expected_end_date_after: '',
                    expected_end_date_before: '',
                    status: '1',
                    is_employed_by_agencies: '',
                    // include_salary: true
                }}
                renderFilterFields={(filters, setFilters) => (
                    <ContractFilters
                        filters={filters}
                        setFilters={setFilters}
                        employees={employees}
                        employers={employers}
                        positions={positions}
                    />
                )}
                renderCells={(contract, setData) => [
                    { content: contract.custom_id },
                    {
                        content: (
                            <>
                                <Link to={routes.EMPLYOEES_DETAIL + contract?.employee?.id} target="_blank">
                                    <strong>{contract?.employee?.fullname_with_titles}</strong>
                                </Link>{' '}
                                <br />
                                <small>{contract?.work_position?.title}</small>
                            </>
                        ),
                    },
                    {
                        content: (
                            <>
                                <Link to={routes.UNIT_DETAIL + contract?.employer?.id} target="_blank">
                                    <strong>{contract?.employer?.name}</strong> <br />
                                </Link>
                                <small>{contract?.employer?.identification_number}</small>
                            </>
                        ),
                    },
                    { content: contract?.type?.title },
                    {
                        content: (
                            <>
                                {contract?.fond} {t('hours_shortcut')}. <br />
                                <small>{contract?.fond_per_unit_display}</small>
                            </>
                        ),
                    },
                    {
                        content: (
                            <>
                                {contract.salary} {contract.currency} <br />
                                <small> {contract?.per_unit_display} </small>
                            </>
                        ),
                    },
                    {
                        content: (
                            <>
                                {contract?.available_vacation_fond} {t('days')}
                            </>
                        ),
                    },
                    { content: moment(contract?.valid_from).format(dateFormat) },
                    {
                        content: contract?.valid_until
                            ? moment(contract?.valid_until).format(dateFormat)
                            : t('indefinite_period'),
                    },
                    {
                        content: (
                            <Action
                                as="modal"
                                tooltip={t('contract_notes')}
                                header={t('contract_notes')}
                                open={contract.id === forceModalOpen}
                                onClose={() => setForceModalOpen(0)}
                                trigger={
                                    <span
                                        style={{ fontSize: '1.2rem', cursor: 'pointer' }}
                                        onClick={() => setForceModalOpen(contract.id)}
                                    >
                                        <Icon name="document-text-outline" />{' '}
                                        <sup style={{ padding: '0.2rem', fontWeight: 'bold', opacity: '0.6' }}>
                                            {contract.notes.length}
                                        </sup>
                                    </span>
                                }
                                modal={
                                    <Notes
                                        notes={contract.notes}
                                        endpoint={API.CONTRACTS}
                                        id={contract.id}
                                        setData={setData}
                                    />
                                }
                            />
                        ),
                    },
                    {
                        content: (
                            <SuperDuperModal
                                size="small"
                                trigger={<Button size="tiny" basic content={t('manage_documents')} />}
                                content={<Documents contract={contract} setContracts={setData} />}
                            />
                        ),
                    },
                    {
                        content: (
                            <>
                                {contract.status === 4 && <Label basic>{t('draft')}</Label>}
                                {contract.status === 3 && (
                                    <>
                                        <Label
                                            style={{
                                                background: 'var(--blue)',
                                                color: 'var(--white)',
                                                textAlign: 'center',
                                            }}
                                        >
                                            {t('active') + ' - ' + t('in_notice_period')}
                                        </Label>
                                        {/* <NoticePeriodAlert noticePeriod={moment(contract?.notice_period).format("DD.MM.YYYY")} />  */}
                                    </>
                                )}
                                {contract.status === 2 && (
                                    <Label
                                        style={{
                                            background: 'var(--danger)',
                                            color: 'var(--white)',
                                            textAlign: 'center',
                                        }}
                                    >
                                        {t('terminated')}{' '}
                                    </Label>
                                )}
                                {contract.status === 1 && (
                                    <Label style={{ background: 'var(--success)', color: 'var(--white)' }}>
                                        {t('active')}
                                    </Label>
                                )}
                            </>
                        ),
                    },
                ]}
            />
        </CanView>
    )
}

export default Contracts
