import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
//components
import Icon from '@components/Icon'
import SuperDuperModal from '@components/modals/SuperDuperModal'
import { Card, Grid, Button, Segment } from 'semantic-ui-react'
import ConfirmModal from '@components/modals/ConfrimModal'
import CustomRulesetModules from './CustomRulesetModules'
import PermissionGroupForm from './PermissionGroupForm'

const PermissionGroup = ({ permissions, group, onDelete, onUpdate }) => {
    const { t } = useTranslation()
    // eslint-disable-next-line
    const [groupPermissions, setGroupPermissions] = useState([])
    const [visible, setVisible] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        async function fetchGroupPermissions() {
            if (visible) {
                setIsLoading(true)
                const request = await requests.get(API.PERMISSION_GROUPS + group.id + '/permissions/')
                if (request.status === 200) {
                    setGroupPermissions(request.response)
                }
                setIsLoading(false)
            }
        }

        fetchGroupPermissions()
        // eslint-disable-next-line
    }, [visible])

    return (
        <>
            <Card fluid style={{ borderRadius: 0, backgroundColor: 'var(--white)', cursor: 'normal', marginBottom: 0 }}>
                <Grid>
                    <Grid.Row columns="2" verticalAlign="middle">
                        <Grid.Column textAlign="left">
                            {!visible ? (
                                <Button
                                    onClick={() => setVisible(!visible)}
                                    floated="left"
                                    size="medium"
                                    icon={<Icon name="chevron-down-outline" />}
                                    style={{
                                        paddingTop: 0,
                                        paddingLeft: 0,
                                        paddingBottom: 0,
                                        marginRight: '0.5rem',
                                        marginTop: '0.30em',
                                        background: 'none',
                                    }}
                                />
                            ) : (
                                <Button
                                    onClick={() => setVisible(!visible)}
                                    floated="left"
                                    size="medium"
                                    icon={<Icon name="chevron-up-outline" />}
                                    style={{
                                        paddingTop: 0,
                                        paddingLeft: 0,
                                        paddingBottom: 0,
                                        marginRight: '0.5rem',
                                        marginTop: '0.30em',
                                        background: 'none',
                                    }}
                                />
                            )}
                            <span style={{ marginLeft: '1rem', fontWeight: 'bold' }}> {group.name} </span>
                        </Grid.Column>
                        <Grid.Column textAlign="right">
                            <ConfirmModal
                                description={t('are_you_sure')}
                                onConfirm={() => onDelete(group.id)}
                                button={
                                    <Button
                                        floated="right"
                                        size="medium"
                                        icon={<Icon name="close-outline" />}
                                        style={{
                                            paddingTop: 0,
                                            paddingLeft: 0,
                                            marginTop: '0.50em',
                                            background: 'none',
                                            color: 'var(--danger)',
                                        }}
                                    />
                                }
                            />
                            <SuperDuperModal
                                trigger={
                                    <Button
                                        floated="right"
                                        size="medium"
                                        icon={<Icon name="pencil-outline" />}
                                        style={{
                                            paddingTop: 0,
                                            paddingLeft: 0,
                                            marginTop: '0.50em',
                                            background: 'none',
                                            color: 'var(--dark)',
                                        }}
                                    />
                                }
                                content={<PermissionGroupForm onSubmit={onUpdate} group={group} />}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Card>
            {visible && (
                <Segment
                    loading={isLoading}
                    style={{ padding: 0, margin: 0, background: 'transparent', border: 'none', boxShadow: 'none' }}
                >
                    {!groupPermissions.isLoading && (
                        <CustomRulesetModules
                            permissions={permissions.data}
                            groupPermissions={groupPermissions}
                            setGroupPermissions={setGroupPermissions}
                            group={group}
                        />
                    )}
                </Segment>
            )}
        </>
    )
}

export default PermissionGroup
