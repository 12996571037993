import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
import { isEmpty } from '@helpers/validation'
import { dates as dateHelper } from '@helpers/dates'
import { useSelectOptions, useHasPermission } from '@helpers/hooks'
// components
import Icon from '@components/Icon'
import SuperField from '@components/forms/SuperField'
import ModalCancel from '@components/buttons/ModalCancel'
import ModalSubmit from '@components/buttons/ModalSubmit'
import { Form, Grid, Button, Divider, Header, Message, Loader } from 'semantic-ui-react'
import ProjectDropdownField from '@components/forms/projects/ProjectDropdownField'
import MilestoneDropdownField from '@components/forms/projects/MilestoneDropdownField'
import NonFieldErrors from '@components/NonFieldErrors'

const ActivityForm = ({ record, setData, setTotal, onClose, orders, clients, profile }) => {
    const { t } = useTranslation()
    const canManageCategories = useHasPermission('timesheets.c_manage_timesheet_categories')
    const [activities, setActivities] = useSelectOptions(API.TIMESHEETS + 'categories/', 'title')
    const [processing, setProcessing] = useState(false)
    const [showTime, setShowTime] = useState(record?.datetime_from && record?.datetime_to ? true : false)
    const [isProductivity, setIsProductivity] = useState(record?.piece_work_count > 0 ? true : false)
    const [view, setView] = useState(null)
    const [errors, setErrors] = useState([])

    const [allowGPSTrackingTimesheets, setAllowGPSTrackingTimesheets] = useState(false)

    const [form, setForm] = useState({
        category: record?.category?.id || '',
        date: record?.date || moment().format('YYYY-MM-DD'),
        hours: record?.hours || 0,
        time_from: record?.datetime_from
            ? moment(dateHelper.convertUTCDateToLocalDate(record?.datetime_from)).format('HH:mm')
            : '',
        time_to: record?.datetime_to
            ? moment(dateHelper.convertUTCDateToLocalDate(record?.datetime_to)).format('HH:mm')
            : '',
        activity: record?.activity || '',
        project: record?.project?.id || '',
        milestone: record?.milestone?.id || '',
        business_detail: record?.business_detail?.id || '',
        order: record?.order?.id || '',
        piece_work_count: record?.piece_work_count || '',
    })

    const [location, setLocation] = useState({
        latitude: null,
        longitude: null,
    })

    function handleLocation() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(success, error)
        } else {
            console.log('Geolocation not supported')
        }
    }

    useEffect(() => {
        const fetchPreferences = async () => {
            const requestGPSTimesheets = await requests.get(
                API.PREFERENCES + 'timesheet_preferences__save_gps_coordinates_in_timesheet/'
            )

            if (requestGPSTimesheets.status === 200) {
                setAllowGPSTrackingTimesheets(requestGPSTimesheets.response.value)
                if (requestGPSTimesheets.response.value) {
                    handleLocation()
                }
            }
        }

        if (record === undefined) {
            fetchPreferences()
        }
        // eslint-disable-next-line
    }, [])

    function success(position) {
        const latitude = position.coords.latitude
        const longitude = position.coords.longitude
        setLocation({ latitude, longitude })
        // console.log(`Latitude: ${latitude}, Longitude: ${longitude}`)
    }

    function error() {
        console.log('Unable to retrieve your location')
    }

    const handleSubmit = async () => {
        setProcessing(true)
        setErrors(null)

        let data = form

        if (!isEmpty(form.time_from) && !isEmpty(form.time_to) && showTime) {
            // then calculate hours diff and create datetime objects
            let datetime_from = moment(form.date + ' ' + form.time_from)
            datetime_from = moment.utc(datetime_from).format('YYYY-MM-DD HH:mm:ss')
            let datetime_to = moment(form.date + ' ' + form.time_to)
            datetime_to = moment.utc(datetime_to).format('YYYY-MM-DD HH:mm:ss')

            // do diff and if its negative then increment day for time_to
            let duration = moment.duration(moment(datetime_to).diff(moment(datetime_from)))
            let hours = duration.asHours()

            if (hours < 0) {
                // add day to time_to
                datetime_to = moment(datetime_to)
                datetime_to = datetime_to.add('days', 1).format('YYYY-MM-DD HH:mm:ss')
                duration = moment.duration(moment(datetime_to).diff(moment(datetime_from)))
                hours = duration.asHours()
            }

            data = {
                ...data,
                hours: hours,
                datetime_from: moment(datetime_from).format('YYYY-MM-DD HH:mm:ss'),
                datetime_to: moment(datetime_to).format('YYYY-MM-DD HH:mm:ss'),
            }

            if (record === undefined) {
                data = {
                    ...data,
                    latitude: location.latitude,
                    longitude: location.longitude,
                }
            }
        } else {
            data = {
                ...data,
                hours: form.hours,
                datetime_from: null,
                datetime_to: null,
            }

            if (record === undefined) {
                data = {
                    ...data,
                    latitude: location.latitude,
                    longitude: location.longitude,
                }
            }
        }

        if (!isProductivity) {
            data = {
                ...data,
                piece_work_count: null,
            }
        }

        if (record?.id === undefined) {
            let endpoint = profile !== undefined ? API.TIMESHEETS + 'records/manual/' : API.TIMESHEETS + 'records/'
            if (profile !== undefined) {
                data = {
                    ...data,
                    profile: profile.id,
                }
            }
            const request = await requests.post(endpoint, data)
            if (request.status === 400) setErrors(request.response)
            if (request.status === 201) {
                setTotal((prev) => prev + 1)
                setData((prev) => [request.response, ...prev])
            }
        } else {
            const request = await requests.patch(`${API.TIMESHEETS}records/${record?.id}/`, data)
            if (request.status === 400) setErrors(request.response)
            if (request.status === 200) {
                setData((prev) =>
                    prev.map((item) => {
                        if (item.id === request.response.id) {
                            item = request.response
                        }

                        return item
                    })
                )
            }
        }

        setProcessing(false)
    }

    const handleAddItem = async (item) => {
        setActivities((prev) => ({ ...prev, isLoading: true }))
        const request = await requests.post(API.TIMESHEETS + 'categories/', {
            title: item,
        })

        if (request.status === 201) {
            setActivities((prevState) => {
                return {
                    isLoading: false,
                    options: [
                        {
                            key: request.response.id,
                            value: request.response.id,
                            text: request.response.title,
                        },
                        ...prevState.options,
                    ],
                }
            })

            setForm((prev) => ({
                ...prev,
                category: request.response.id,
            }))
        }
    }

    const isGPSRequired = () => {
        let isRequired = false

        if (allowGPSTrackingTimesheets && record === undefined) {
            if (location.latitude === null || location.longitude === null) return true
        }

        return isRequired
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Header as="h3" content={record === undefined ? t('add_activity') : t('update_activity')} />
            <Divider />
            <NonFieldErrors errors={errors} />

            {allowGPSTrackingTimesheets && (
                <Message info visible>
                    <strong style={{ fontSize: '1.2rem' }}>{t('gps_tracking_required')}.</strong> <br />
                    {location.latitude && location.longitude ? (
                        <>
                            {`${t('latitude')}: ${location.latitude}`} <br />
                            {`${t('longitude')}: ${location.longitude}`} <br />
                            <a
                                style={{ fontSize: '1rem', fontWeight: 'bold' }}
                                href={`https://www.google.com/maps/search/?api=1&query=${location.latitude}%2C${location.longitude}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {t('show_location')}
                            </a>
                        </>
                    ) : (
                        <>
                            {t('enable_gps_tracking_in_your_device')}
                            <div style={{ marginTop: '0.5rem' }}>
                                <Loader
                                    size="tiny"
                                    className="dark-loader"
                                    active
                                    inline
                                    style={{ marginRight: '0.5rem' }}
                                />{' '}
                                {t('checking_availability')}...
                            </div>
                        </>
                    )}
                </Message>
            )}

            <SuperField
                as="choice"
                search
                required
                autoFocus
                text="title"
                clearable={false}
                value={form.category}
                label={t('activity')}
                loading={activities.isLoading}
                disabled={activities.isLoading}
                customOptions={activities.options}
                error={errors?.category?.[0] || false}
                allowAdditions={canManageCategories}
                onChange={(e, { value }) => setForm((prev) => ({ ...prev, category: value }))}
                help={canManageCategories ? t('add_activity_type_hint') : null}
                onAddItem={canManageCategories ? (e, { value }) => handleAddItem(value) : null}
            />

            <Form.Group widths="equal">
                <Form.Field>
                    <label style={{ fontWeight: 'bold' }}> {t('timesheet_tracking_mode')} </label>
                    <Button.Group basic size="small" style={{ borderRadius: 0 }}>
                        <Button type="button" active={!isProductivity} onClick={() => setIsProductivity(false)}>
                            {' '}
                            {t('mode_time')}{' '}
                        </Button>
                        <Button type="button" active={isProductivity} onClick={() => setIsProductivity(true)}>
                            {' '}
                            {t('mode_productivity')}{' '}
                        </Button>
                    </Button.Group>
                </Form.Field>
                <Form.Field>
                    <div style={{ textAlign: 'right', fontWeight: 'bold', marginTop: '2.3rem' }}>
                        <span className="ref-link" onClick={() => setShowTime((prev) => !prev)}>
                            <Icon name="repeat-outline" style={{ marginRight: '0.5rem', fontSize: '1.2rem' }} />
                            <span style={{ position: 'relative', top: '-0.2rem' }}>
                                {showTime ? t('switch_to_duration') : t('switch_to_time')}
                            </span>
                        </span>
                    </div>
                </Form.Field>
            </Form.Group>

            <Form.Group widths="equal">
                <SuperField
                    as="datepicker"
                    required
                    label={t('date')}
                    value={form.date}
                    error={errors?.date?.[0] || false}
                    onChange={(e, { value }) => setForm((prev) => ({ ...prev, date: value }))}
                />
                {!showTime ? (
                    <SuperField
                        as="input"
                        required
                        value={form.hours}
                        label={t('hours')}
                        help={t('enter_value_in_hours_for_example_hint')}
                        pattern="^-?[0-9]\d*\.?\d*$"
                        error={errors?.hours?.[0] || false}
                        onChange={(e, { value }) =>
                            setForm((prev) => ({ ...prev, hours: value?.replace(',', '.') || '' }))
                        }
                    />
                ) : (
                    <>
                        <SuperField
                            as="timepicker"
                            required
                            value={form.time_from}
                            label={t('time_from')}
                            error={errors?.datetime_from?.[0] || false}
                            onChange={(e, { value }) => setForm((prev) => ({ ...prev, time_from: value }))}
                        />
                        <SuperField
                            as="timepicker"
                            required
                            value={form.time_to}
                            label={t('time_to')}
                            error={errors?.datetime_to?.[0] || false}
                            onChange={(e, { value }) => setForm((prev) => ({ ...prev, time_to: value }))}
                        />
                    </>
                )}
            </Form.Group>

            {isProductivity && (
                <SuperField
                    as="input"
                    required={isProductivity}
                    value={form.piece_work_count}
                    label={t('productivity_amount')}
                    help={t('enter_value_in_amount_for_example_hint')}
                    pattern="^-?[0-9]\d*\.?\d*$"
                    error={errors?.piece_work_count?.[0] || false}
                    onChange={(e, { value }) =>
                        setForm((prev) => ({ ...prev, piece_work_count: value?.replace(',', '.') || '' }))
                    }
                />
            )}

            <Divider />

            <div style={{ padding: 0 }}>
                <Header as="h3" content={t('activity_towards_whom') + '?'} style={{ marginBottom: 0 }} />
                <span>{t('activity_unclassification_hint') + '.'}</span>
            </div>
            {view === null ? (
                <Grid stackable style={{ padding: 0 }}>
                    <Grid.Row columns="3" verticalAlign="middle">
                        <Grid.Column textAlign="center" style={{ padding: 0 }} onClick={() => setView('project')}>
                            <Button basic fluid active={!isEmpty(form.project) || !isEmpty(form.milestone)}>
                                {t('project')}
                            </Button>
                        </Grid.Column>
                        <Grid.Column textAlign="center" style={{ padding: 0 }} onClick={() => setView('client')}>
                            <Button basic fluid active={!isEmpty(form.business_detail)}>
                                {t('client')}
                            </Button>
                        </Grid.Column>
                        <Grid.Column textAlign="center" style={{ padding: 0 }} onClick={() => setView('order')}>
                            <Button basic fluid active={!isEmpty(form.order)}>
                                {t('order')}
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            ) : (
                <>
                    <Form.Field
                        style={{
                            display: 'flex',
                            fontWeight: 'bold',
                            fontSize: '1.3rem',
                            marginTop: '1rem',
                            marginBottom: '1rem',
                        }}
                    >
                        <div style={{ width: '50%' }}>
                            <Icon
                                name="arrow-back-circle-outline"
                                onClick={() => setView(null)}
                                style={{ cursor: 'pointer', fontSize: '1.5rem' }}
                            />
                        </div>
                        <div style={{ width: '50%', textAlign: 'right' }}>{t(view)}</div>
                    </Form.Field>

                    {view === 'project' && (
                        <Form.Group widths={2}>
                            <Form.Field>
                                <ProjectDropdownField
                                    currentuser={true}
                                    value={form.project}
                                    error={errors?.project?.[0] || false}
                                    onChange={(e, { value }) => setForm((prev) => ({ ...prev, project: value }))}
                                />
                            </Form.Field>
                            <Form.Field>
                                <MilestoneDropdownField
                                    milestone={form.milestone}
                                    error={errors?.milestone?.[0] || false}
                                    setMilestone={(e, { value }) => setForm((prev) => ({ ...prev, milestone: value }))}
                                    project={form.project}
                                    disabled={form.project === '' ? true : false}
                                />
                            </Form.Field>
                        </Form.Group>
                    )}

                    {view === 'client' && (
                        <SuperField
                            as="choice"
                            search
                            text="name"
                            label={t('client')}
                            value={form.business_detail}
                            error={errors?.business_detail?.[0] || false}
                            onChange={(e, { value }) => setForm((prev) => ({ ...prev, business_detail: value }))}
                            // endpoint={API.BUSINESS_DETAIL + "?is_freelancer=false&is_supplier=false&query={id, name}"}
                            customOptions={clients.map((item) => ({ key: item.id, value: item.id, text: item.name }))}
                        />
                    )}

                    {view === 'order' && (
                        <SuperField
                            as="choice"
                            search
                            text="name"
                            label={t('order')}
                            value={form.order}
                            error={errors?.order?.[0] || false}
                            onChange={(e, { value }) => {
                                let order = orders.find((item) => item.id === value)
                                if (order !== undefined) {
                                    if (order.business_detail !== null) {
                                        setForm((prev) => ({
                                            ...prev,
                                            order: value,
                                            business_detail: order?.business_detail?.id || '',
                                        }))
                                    } else {
                                        setForm((prev) => ({ ...prev, order: value }))
                                    }
                                } else {
                                    setForm((prev) => ({ ...prev, order: value }))
                                }
                            }}
                            customOptions={orders.map((item) => ({ key: item.id, value: item.id, text: item.name }))}
                        />
                    )}
                </>
            )}

            <Divider />

            <SuperField
                as="textarea"
                label={t('note')}
                value={form.activity}
                error={errors?.activity?.[0] || false}
                onChange={(e, { value }) => setForm((prev) => ({ ...prev, activity: value }))}
            />

            <Divider />

            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose} disabled={processing} />
                <ModalSubmit
                    text={t('save')}
                    loading={processing}
                    disabled={
                        isGPSRequired() ||
                        processing ||
                        isEmpty(form.date) ||
                        isEmpty(form.category) ||
                        (!showTime
                            ? isEmpty(form.hours) || form.hours === 0
                            : isEmpty(form.time_from) && isEmpty(form.time_from)) ||
                        (isProductivity && (form.piece_work_count === 0 || isEmpty(form.piece_work_count)))
                    }
                />
            </Form.Field>
        </Form>
    )
}

export default ActivityForm
