import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
// store
import { routes } from '@routes'
import { API } from '@store/config'
import { requests } from '@helpers/requests'
// components
import { Label } from 'semantic-ui-react'
import ListView from '@components/ListView'
import SuperField from '@components/forms/SuperField'
// module specific components
import StockItemForm from '../components/StockItemForm'
import StockListStatistics from '../components/StockListStatistics'
import StockItemCorrectionForm from '../components/StockItemCorrectionForm'
import StoredItemPositionHistory from '../components/StoredItemPositionHistory'

const StockList = ({ warehouses, selectedWarehouse }) => {
    const { t } = useTranslation()

    const [catalogueItems, setCatalogueItems] = useState([])
    const [warehousePositions, setwarehousePositions] = useState([])

    useEffect(() => {
        const fetchCatalogueItems = async () => {
            const request = await requests.get(API.CATALOGUE_ITEMS + '?query={id, title, code}&is_service=false')

            if (request.status === 200) {
                let items = []
                for (let i = 0; i < request.response.length; i++) {
                    const item = request.response[i]
                    items.push({
                        key: item.id,
                        value: item.id,
                        text: (item.code ? item.code + ' ' : '') + item.title,
                    })
                }

                setCatalogueItems(items)
            }
        }

        const fetchWarehousePositions = async () => {
            const request = await requests.get(
                API.ASSETS + 'warehouse_positions/?query={id, title, section, row, warehouse{id, title}}'
            )

            if (request.status === 200) {
                let items = []
                for (let i = 0; i < request.response.length; i++) {
                    const item = request.response[i]
                    items.push({
                        key: item.id,
                        value: item.id,
                        text:
                            item.title +
                            (item.section && ' / ' + item.section) +
                            (item.row && ' / ' + item.row) +
                            (item.warehouse ? ' - ' + item.warehouse.title : ''),
                        warehouse: item.warehouse?.id,
                    })
                }

                setwarehousePositions(items)
            }
        }

        fetchCatalogueItems()
        fetchWarehousePositions()
    }, [])

    const getMeasureUnit = (stockItem) => {
        let measure_unit = stockItem.stored_item?.catalogue_item?.measure_unit

        if (measure_unit) {
            return measure_unit.title
        }

        return t('unspecified')
    }

    const calculatePackages = (stockItem) => {
        let quantity = parseFloat(stockItem.quantity || 0)
        let avgWeight = parseFloat(stockItem.average_weight || 0)
        let packages = quantity

        if (avgWeight > 0 && stockItem.stored_item.catalogue_item.weight_is_primary_measurement) {
            packages = quantity / avgWeight
        }

        return parseInt(packages || 0)
    }

    return (
        <ListView
            as="table"
            allowSearch
            // allowSelection
            // isSoftDeleteAllowed
            showStatistic
            visibleStatsOnRender
            actionsCellWidth="2"
            key={selectedWarehouse?.id}
            endpoint={API.ASSETS + 'stored_item_positions/'}
            tableHeaders={[
                { title: t('stock_number') },
                { title: t('item') },
                { title: t('warehouse') },
                { title: t('warehouse_position') },
                { title: t('measure_unit') },
                { title: t('stock') },
                { title: t('average_weight') },
                { title: t('packages') },
                { title: t('status') },
            ]}
            settings={[
                {
                    permissions: ['orders.c_manage_catalogue'],
                    as: 'link',
                    // target: "_blank",
                    linkTo: routes.SETTINGS + 'catalogue-items',
                    name: t('catalogue_items_management'),
                    icon: 'book-outline',
                },
                {
                    permissions: ['assets.c_manage_warehouses'],
                    as: 'link',
                    // target: "_blank",
                    linkTo: routes.SETTINGS + 'warehouses',
                    name: t('warehouse_management'),
                    icon: 'albums-outline',
                },
                {
                    permissions: ['assets.c_manage_warehouses'],
                    as: 'link',
                    // target: "_blank",
                    linkTo: routes.SETTINGS + 'warehouse-positions',
                    name: t('warehouse_positions_management'),
                    icon: 'albums-outline',
                },
            ]}
            initialFilters={{
                warehouse: selectedWarehouse?.id || '',
                catalogue_item: '',
                category: '',
                warehouse_position: '',
                process: '',
                in_stock: true,
            }}
            renderStatisticView={(filters, data, total, params, setFilters, setTotal, setData, fetchData) => (
                <StockListStatistics
                    data={data}
                    total={total}
                    params={params}
                    filters={filters}
                    setFilters={setFilters}
                    fetchData={fetchData}
                />
            )}
            renderFilterFields={(filters, setFilters) => (
                <>
                    <SuperField
                        as="choice"
                        search
                        label={t('item')}
                        value={filters.catalogue_item}
                        customOptions={catalogueItems}
                        onChange={(e, { value }) => setFilters({ ...filters, catalogue_item: value })}
                    />

                    <SuperField
                        as="choice"
                        search
                        label={t('category')}
                        value={filters.category}
                        endpoint={API.ORDERS + 'catalogue_item_category/?query={id, title}'}
                        text="title"
                        onChange={(e, { value }) => setFilters({ ...filters, category: value })}
                    />

                    <SuperField
                        as="choice"
                        search
                        text="title"
                        label={t('warehouse_position')}
                        value={filters.warehouse_position}
                        customOptions={
                            selectedWarehouse
                                ? warehousePositions.filter((item) => item.warehouse === selectedWarehouse?.id)
                                : warehousePositions
                        }
                        onChange={(e, { value }) => setFilters({ ...filters, warehouse_position: value })}
                    />

                    {selectedWarehouse?.id === undefined && (
                        <SuperField
                            as="choice"
                            search
                            label={t('warehouse')}
                            value={filters.warehouse}
                            onChange={(e, { value }) => setFilters({ ...filters, warehouse: value })}
                            customOptions={warehouses.map((item) => ({
                                key: item.id,
                                value: item.id,
                                text: item.title,
                            }))}
                        />
                    )}

                    <SuperField
                        as="choice"
                        search
                        label={t('warehouse_process')}
                        value={filters.process}
                        text="title"
                        endpoint={
                            API.ASSETS + 'warehouse_processes/?is_active=true&is_template=false&exclude_stats=true'
                        }
                        onChange={(e, { value }) => setFilters({ ...filters, process: value })}
                    />
                </>
            )}
            listAdditionActions={(data, setData, total, setTotal, response) => [
                {
                    as: 'filter',
                    index: 0,
                    name: t('in_stock'),
                    filters: {
                        in_stock: true,
                    },
                },
                {
                    as: 'filter',
                    index: 1,
                    name: t('unloaded'),
                    filters: {
                        in_stock: false,
                    },
                },
                {
                    as: 'filter',
                    index: 2,
                    name: t('all'),
                    filters: {
                        in_stock: null,
                    },
                },
            ]}
            renderCells={(stockitem, setData, setTotal, idx, filters) => [
                {
                    content: <strong> {stockitem.stock_number} </strong>,
                },

                {
                    content:
                        (stockitem?.stored_item?.catalogue_item?.code
                            ? stockitem?.stored_item?.catalogue_item?.code + ' '
                            : '') + stockitem?.stored_item?.catalogue_item?.title,
                },
                {
                    content: stockitem?.stored_item?.warehouse?.title || '',
                },
                {
                    content:
                        stockitem.warehouse_position.title +
                        (stockitem.warehouse_position.section && ' / ' + stockitem.warehouse_position.section) +
                        (stockitem.warehouse_position.row && ' / ' + stockitem.warehouse_position.row),
                },
                {
                    content: getMeasureUnit(stockitem),
                },
                {
                    content: stockitem.quantity,
                },
                {
                    content: stockitem.average_weight,
                },
                {
                    content: calculatePackages(stockitem),
                },
                {
                    content: stockitem.in_stock ? (
                        <Label size="small" color="green">
                            {t('in_stock')}
                        </Label>
                    ) : (
                        <Label size="small" color="red">
                            {t('unloaded')}
                        </Label>
                    ),
                },
            ]}
            actions={[
                {
                    as: 'modal',
                    type: 'add',
                    modalSize: 'tiny',
                    name: t('store_item'),
                    modalCentered: false,
                    modal: <StockItemForm warehouses={warehouses} selectedWarehouse={selectedWarehouse} />,
                    matchEveryPermission: true,
                    permissions: ['assets.c_manage_items'],
                },
                {
                    as: 'modal',
                    type: 'custom',
                    modalSize: 'tiny',
                    icon: 'swap-vertical-outline',
                    name: t('make_a_correction'),
                    customIconColor: 'var(--dark)',
                    modal: <StockItemCorrectionForm />,
                    matchEveryPermission: true,
                    permissions: ['assets.c_manage_items'],
                },
                {
                    as: 'modal',
                    type: 'custom',
                    name: t('history'),
                    modalSize: 'large',
                    icon: 'reader-outline',
                    permissions: ['assets.c_view_item_history'],
                    customIconColor: 'var(--blue)',
                    modal: <StoredItemPositionHistory />,
                },
                {
                    name: t('delete'),
                    type: 'delete',
                    as: 'delete',
                    text: t('stored_item_delete_message'),
                    matchEveryPermission: true,
                    permissions: ['assets.c_manage_items', 'assets.c_manage_warehouse_processes'],
                },
            ]}
        />
    )
}

export default StockList
